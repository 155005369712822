import React, { useState } from 'react';
import { 
    IconButton, 
    Popover, 
    Typography, 
    Button, 
    Box, 
    Paper,
    Switch,
    Tooltip,
    InputBase,
    Badge,
    Fade
} from "@mui/material";
import { 
    Settings as SettingsIcon, 
    RestartAlt as ResetIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Search as SearchIcon
} from "@mui/icons-material";
import { styled, alpha } from '@mui/material/styles';

// Styled Search Input
const SearchInput = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

// Styled Search Container
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    marginLeft: 0,
    width: '100%',
}));

// Styled Search Icon Wrapper
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const TableColCtrl = ({ 
    visibleColumns = {}, 
    setVisibleColumns, 
    columns = [], 
    onColumnToggle 
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setSearchTerm('');
    };

    // Count hidden columns
    const hiddenColumnsCount = Array.isArray(columns) 
        ? columns.filter(column => !visibleColumns[column.name]).length 
        : 0;

    // Filter columns based on search
    const filteredColumns = Array.isArray(columns)
        ? columns.filter(column => 
            column.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : [];

    // Reset all columns to visible
    const handleReset = () => {
        if (!Array.isArray(columns)) return;

        const resetColumns = columns.reduce((acc, column) => {
            acc[column.name] = true;
            return acc;
        }, {});
        setVisibleColumns(resetColumns);
    };

    // If no columns or setVisibleColumns is not provided, return null
    if (!Array.isArray(columns) || !setVisibleColumns) {
        return null;
    }

    return (
        <>
            <Tooltip title="Column Visibility">
                <IconButton 
                    onClick={handleOpenMenu}
                    size="small"
                >
                    <Badge 
                        badgeContent={hiddenColumnsCount} 
                        color="error"
                        invisible={hiddenColumnsCount === 0}
                    >
                        <SettingsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                // TransitionComponent={Fade}
                // transitionDuration={{
                //     enter: 500,
                //     exit: 195
                // }}
            >
                <Paper 
                    sx={{ 
                        width: 320, 
                        maxHeight: 500, 
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                    }}
                >
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center' 
                    }}>
                        <Typography variant="h6">Column Visibility</Typography>
                        <Button
                            startIcon={<ResetIcon />}
                            size="small"
                            onClick={handleReset}
                            variant="outlined"
                        >
                            Reset
                        </Button>
                    </Box>

                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <SearchInput
                            placeholder="Search columns…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Search>

                    <Box sx={{ 
                        maxHeight: 300, 
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1
                    }}>
                        {filteredColumns.length > 0 ? (
                            filteredColumns.map((column) => (
                                <Box
                                    key={column.name}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        p: 1,
                                        borderRadius: 1,
                                        '&:hover': {
                                            bgcolor: 'action.hover'
                                        }
                                    }}
                                >
                                    <Box sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        gap: 1 
                                    }}>
                                        {visibleColumns[column.name] ? (
                                            <VisibilityIcon color="primary" fontSize="small" />
                                        ) : (
                                            <VisibilityOffIcon color="disabled" fontSize="small" />
                                        )}
                                        <Typography variant="body2">
                                            {column.name}
                                        </Typography>
                                    </Box>
                                    <Switch
                                        checked={visibleColumns[column.name] || false}
                                        onChange={(e) => {
                                            setVisibleColumns((prev) => ({
                                                ...prev,
                                                [column.name]: e.target.checked,
                                            }));
                                            onColumnToggle && onColumnToggle(column.name, e.target.checked);
                                        }}
                                        size="small"
                                    />
                                </Box>
                            ))
                        ) : (
                            <Typography 
                                variant="body2" 
                                color="text.secondary" 
                                align="center"
                            >
                                No columns found
                            </Typography>
                        )}
                    </Box>
                </Paper>
            </Popover>
        </>
    );
};

export default TableColCtrl;