import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { Box, Button, Typography, Tabs, Tab, styled } from '@mui/material';
import './EditorStyles.css'

// Styled components using the 'styled' API
const HeaderBox = styled(Box)(({ theme }) => ({
  padding: '24px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
}));

const TitleBox = styled(Box)(({ theme }) => ({
  color: '#1a1a1a',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '26px',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: '#6c757d',
  fontSize: '14px',
  marginTop: '4px',
}));

const RightButtonsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: '40px',
  padding: '0px 16px',
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 'medium',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '-0.44px',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#ffffff',
  paddingLeft: '24px',
  paddingTop: '16px',
}));

const StyledTab = styled(Tab)(({ theme, selected }) => ({
  textTransform: 'none',
  color: selected ? '#007bff' : '#6c757d',
  fontWeight: selected ? 'bold' : 'normal',
}));

const EditorBox = styled(Box)(({ theme }) => ({
  padding: '24px',
  backgroundColor: '#ffffff',
  flexGrow: 1,
  overflowY: 'auto',
}));

const HtmlBox = styled(Box)(({ theme }) => ({
  padding: '16px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  backgroundColor: '#f9f9f9',
  height: '100%',
  overflowY: 'auto',
}));

const App = ({ placeholder }) => {
  const editor = useRef(null);
  const [content, setContent] = useState(''); // State to store the editor's content
  const [tabIndex, setTabIndex] = useState(0); // State to track the active tab

  // Function to fetch query parameters
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  // On component mount, check for htmlFile query parameter
  useEffect(() => {
    const htmlFile = getQueryParam('htmlFile');
    if (htmlFile) {
      // Fetch the actual HTML file
      fetchHtmlFile(htmlFile);
    }
  }, []);

  // Fetch the actual HTML file from the public directory
  const fetchHtmlFile = async (URL) => {
    try {
      const response = await fetch(`${URL}`); // Fetch the file from the public directory
      if (!response.ok) {
        throw new Error(`Could not load ${URL}`);
      }
      const fileContent = await response.text();
      setContent(fileContent); // Set the editor content with the HTML file content
    } catch (error) {
      console.error('Error fetching HTML file:', error);
      setContent('<p>Error loading file. Please check the file name.</p>');
    }
  };

  // JoditEditor configuration
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || 'Start typing...',
      toolbarSticky: true, // Keep the toolbar fixed when scrolling
      toolbarStickyOffset: -25, // Offset from the top (adjust as needed)
    }),
    [placeholder]
  );

  // Effect to log content changes (for debugging)
  useEffect(() => {
    console.log('Content updated:', content);
  }, [content]);

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue); // Switch between "Editor" and "View"
  };

  // Handle HTML file upload
  const handleUploadHtml = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const uploadedHtml = event.target.result;
      setContent(uploadedHtml); // Set the content state with the uploaded HTML
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  // Handle HTML download
  const handleDownloadHtml = () => {
    const blob = new Blob([content], { type: 'text/html' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'editor-content.html';
    link.click();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#f9f9f9',
      }}
    >
      {/* Header Area */}
      <HeaderBox>
        {/* Title and Subtitle */}
        <TitleBox>
          <Title variant="h4">HTML Editor</Title>
          <Subtitle variant="body2">Edit your HTML files</Subtitle>
        </TitleBox>

        {/* Buttons on Right */}
        <RightButtonsBox>
          <StyledButton
            component="label"
            variant="text"
            sx={{
              backgroundColor: '#eeeff1',
              borderRadius: '8px',
              '&:hover': { backgroundColor: '#d6d7d9' },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'medium',
                color: 'black',
                whiteSpace: 'nowrap',
                textTransform: 'none',
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '-0.44px',
              }}
            >
              Upload file
            </Typography>
            <input
              type="file"
              accept=".html"
              hidden
              onChange={handleUploadHtml}
            />
          </StyledButton>

          <StyledButton
            onClick={handleDownloadHtml}
            variant="text"
            color="primary"
            sx={{
              backgroundColor: '#007bff',
              color: '#ffffff',
              '&:hover': { backgroundColor: '#0056b3' },
            }}
          >
            Download file
          </StyledButton>
        </RightButtonsBox>
      </HeaderBox>

      {/* Tabs Area */}
      <StyledTabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="editor tabs"
        TabIndicatorProps={{ style: { backgroundColor: '#007bff' } }}
      >
        <StyledTab label="Editor" selected={tabIndex === 0} />
        <StyledTab label="View" selected={tabIndex === 1} />
      </StyledTabs>

      <EditorBox>
        {/* Show Editor Tab if selected */}
        {tabIndex === 0 && (
          <JoditEditor
            ref={editor}
            value={content} // Set the value to the current content
            config={config}
            tabIndex={1}
            onBlur={(newContent) => setContent(newContent)} // Update content onBlur
          />
        )}

        {/* Show HTML Preview Tab if selected */}
        {tabIndex === 1 && (
          <HtmlBox dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </EditorBox>
    </Box>
  );
};

export default App;
