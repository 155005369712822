import React, { useEffect, useState } from "react";
import BasePage from '../../commons/components/BasePage/BasePage';
import { Box, CircularProgress, Typography,Skeleton  } from "@mui/material";
import { useSelector } from "react-redux";

const AnalyticsDashboard = () => {
  const sideNav = useSelector((state) => state.nav.sideNavLinks || []);
  const navObj = useSelector((state) => state.nav.selectedNav || {});
  const [subNavItem, setSubNavItem] = useState({});
  const loadingGlobal = useSelector((state) => state.nav.loading || false)
  const [loading, setLoading] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(false); 

  useEffect(() => {
    if (loadingGlobal) {
      setLoading(true);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [loadingGlobal]);

  useEffect(() => {
    if (sideNav.length > 0) {
      const NavItem = sideNav.find(
        (item) => item.id === navObj.parentId
      );
      if (NavItem && NavItem.sub_navs && NavItem.sub_navs.length > 0) {
        const subsubNavItem = NavItem.sub_navs.find(
          (item) => item.name === navObj.name
        );
        if (subsubNavItem) {
          setSubNavItem(subsubNavItem);
        }
        else
        setSubNavItem({});
      }
      else
      setSubNavItem({});
    } else {
      setSubNavItem({});
    }
  }, [sideNav,navObj]);

  useEffect(() => {
    if (subNavItem.url) {
      setIframeLoading(true); 
    }
  }, [subNavItem.url]);

  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  const renderContent = () => {
    if (loading) {
      return     <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <CircularProgress />
    </Box>
    }

    if (!subNavItem?.url) {
      return (
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
            padding: 2
          }}
        >
          <Typography variant="h6" color="textSecondary">
            No analytics configured for this hotel
          </Typography>
        </Box>
      );
    }

    return (
      <Box
      sx={{
        width: "100%",
        height: "98%",
        position: "relative",
      }}
    >
      {iframeLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
            backgroundColor: "#f5f5f5",
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            animation="wave"
          />
        </Box>
      )}

      <iframe
        src={subNavItem.url}
        title={subNavItem.name}
        onLoad={handleIframeLoad}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          display: iframeLoading ? "none" : "block",
        }}
      />
    </Box>
    )
  }
  

  return (
    <BasePage title={subNavItem.name}
    show_side_filter={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          height: "100%",
          flexGrow: 1,
        }}
      >
{renderContent()}
      </Box>
    </BasePage>
  );
};

export default AnalyticsDashboard;