import { setGlobalMessage } from "../../../../actions/dashboardActions";
import { fetchUserDetailSuccess } from "../../../../actions/loginActions";
import { fetchBrandData } from "../../../../actions/personalisationActions";
import { check_auth_error } from "../../../../components/Utility/Utility";
import axiosClient from "../../../../httpClient";
import { addFilterKey, addSortParams, modifyData } from "../../../utility";
import { fetchFilterTopics, fetchFilters, fetchHotels } from "../../Filter/actions/actions";
import { fetchSideNavLinks } from "../../Navbar/actions/actions";
import * as actionTypes from "./settingActionTypes";

export const fetchSourcesRequest = () => {
  return {
    type: actionTypes.FETCH_SOURCES_REQUEST,
  };
};

export const fetchSourcesSuccess = (sources) => {
  return {
    type: actionTypes.FETCH_SOURCES_SUCCESS,
    payload: sources,
  };
};

export const fetchSourcesError = (error) => {
  return {
    type: actionTypes.FETCH_SOURCES_ERROR,
    payload: error,
  };
};

export const setSelectedSource = (source) => {
  return {
    type: actionTypes.SET_SELECTED_SOURCE,
    payload: source,
  };
};
export const fetchCampaignListSuccess = (data) => {
  return {
    type: actionTypes.Set_Campaign_List,
    payload: data,
  };
};

export const updateSource = (updated_source) => {
  return {
    type: actionTypes.UPDATE_SOURCE,
    payload: updateSource,
  };
};

export const fetchSources = () => {
  return function (dispatch, getState) {
    dispatch(fetchSourcesRequest());
    const {
      filter: { selectedHotel },
    } = getState();
    let url = `/services/sources/connections/`
    // if (selectedHotel.hotel_code)
      url = url.concat(`?hotel=${selectedHotel?.hotel_code}`)
    axiosClient
      .get(url)
      .then((resp) => {
        dispatch(fetchSourcesSuccess(resp.data));
      })
      .catch(({ response = {} }) => {
        dispatch(fetchSourcesError(response.detail));
        console.log("sources err response", response);
      });
  };
};

// for redirection
export const fetchRedirectionUriSuccess = (uri) => {
  return {
    type: actionTypes.FETCH_REDIRECTION_URI_SUCCESS,
    payload: uri,
  };
};

export const fetchRedirectionUriError = (error) => {
  return {
    type: actionTypes.FETCH_REDIRECTION_URI_ERROR,
    payload: error,
  };
};

export const connectionRequest = (source_id) => {
  return {
    type: actionTypes.CONNECTING_SOURCE_REQUEST,
    payload: source_id,
  };
};

export const connectingSuccess = (data) => {
  return {
    type: actionTypes.CONNECTING_SOURCE_SUCCESS,
    payload: data,
  };
};

export const setConnectTOIntial = () => {
  return {
    type: actionTypes.SET_CONNECTING_TO_INITIAL,
    payload: "",
  };
};

export const RESET_STORE = () => {
  return {
    type: actionTypes.RESET_STORE,
    payload: [],
  };
};

export const connectingError = (error) => {
  return {
    type: actionTypes.CONNECTING_SOURCE_ERROR,
    payload: error,
  };
};

export const connectToSource = (data, history) => {
  return function (dispatch, getState) {
    if (!navigator.onLine) {
      dispatch(
        fetchRedirectionUriError("Please check your network connection!")
      );
      return;
    }
    const {
      filter: { selectedHotel },
    } = getState();
    dispatch(connectionRequest(data.source));
    axiosClient
      .post(
        `/services/sources/connections/?hotel=${selectedHotel.hotel_code}`,
        data
      )
      .then((resp) => {
        if (resp.data.login_url)
          dispatch(fetchRedirectionUriSuccess(resp.data.login_url));
        else dispatch(fetchRedirectionUriError(""));
        // if(history?.location?.state?.next){
        //   history.push(history.location.state.next,{scrollY:history.location.state.scrollY})
        // }
      })
      .catch(({ response = {} }) => {
        dispatch(
          fetchRedirectionUriError(
            response.data.error || "Something went wrong, please try again!"
          )
        );
        console.log("redirection err response", response);
      });
  };
};

export const saveAuthCode = (source_id, auth_code_url) => {
  return function (dispatch, getState) {
    if (!navigator.onLine) {
      dispatch(connectingError("Please check your network connection!"));
      return;
    }
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .post(
        `/services/sources/connections/?hotel=${selectedHotel.hotel_code}`,
        {
          source: source_id,
          auth_code: auth_code_url,
        }
      )
      .then((resp) => {
        let data = {};
        if (resp.data.facebook_pages)
          data = {
            facebook_pages: resp.data.facebook_pages,
          };
        else if (resp.data.google_locations)
          data = {
            google_locations: resp.data.google_locations,
          };
        dispatch(connectingSuccess(data));
        // var currURL = window.location.href;
        // var afterDomain = currURL.substring(currURL.lastIndexOf("/") + 1);
        // var beforeQueryString = afterDomain.split("?")[0];
        window.history.pushState("", "Settings", "/settings");
        dispatch(setGlobalMessage({
          mess: "source is successfully connnected",
          type: 'success'
        }))
      })
      .catch(({ response = {} }) => {
        dispatch(connectingError("Something went wrong, please try again!"));
        console.log("token save err response", response);
        dispatch(setGlobalMessage({
          mess: response?.error || "something went wrong",
          type: 'error'
        }))
      });
  };
};

export const setPageOrLocSuccess = () => {
  return {
    type: actionTypes.SET_PAGE_OR_LOC_SUCCESS,
  };
};

export const setPageorLocError = (error) => {
  return {
    type: actionTypes.SET_PAGE_OR_LOC_ERROR,
    payload: error,
  };
};

export const setPageOrLoc = (data, setLoading) => {
  return function (dispatch, getState) {
    const {
      filter: { selectedHotel },
      settings: {
        sources: { selectedSource },
      },
    } = getState();
    setLoading(true);
    axiosClient
      .patch(
        `/services/sources/connections/?hotel=${selectedHotel.hotel_code}`,
        {
          source: selectedSource.id,
          properties: data,
        }
      )
      .then((resp) => {
        setLoading(false);
        dispatch(setPageOrLocSuccess());
        dispatch(fetchSources());
      })
      .catch(({ response = {} }) => {
        setLoading(false);
        dispatch(setPageorLocError("Something went wrong, please try again!"));
        console.log("fb page save err response", response);
      });
  };
};

export const disconnectTheSource = (source_connection_id, source_id) => {
  return function (dispatch, getState) {
    if (!navigator.onLine) {
      dispatch(connectingError("Please check your network connection!"));
      return;
    }
    dispatch(connectionRequest(source_id));
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .delete(`/services/sources/connections/${source_connection_id}/?hotel=${selectedHotel.hotel_code}`)
      .then((resp) => {
        dispatch(connectingSuccess());
        dispatch(fetchSources());
        dispatch(setGlobalMessage({
          mess: "source is successfully disconnected",
          type: 'success'
        }))
      })
      .catch(({ response = {} }) => {
        dispatch(connectingError("Something went wrong, please try again!"));
        console.log("token save err response", response);
        dispatch(setGlobalMessage({
          mess: "something went wrong",
          type: 'error'
        }))
      });
  };
};

export const fetchStaffListFromSetting = (page = 0,parameters={}) => {
  return (dispatch, getState) => {
    const {q = null, closeModal,setLoading,isLoadingState} = parameters
    let {
      filter: { selectedHotel,selectedFilters },
    } = getState();
    const {  setSearchLoading = null } = parameters
    selectedFilters = selectedFilters[parameters.filterkey || "/settings-staff"] || []
    let Url = `/accounts/registration/staff/?hotel=${
      selectedHotel.hotel_code
    }&page=${page}`

    if(selectedFilters.length>0)
      Url = addFilterKey(selectedFilters,Url,"&")
    if(q)
      Url = `${Url}&q=${q}`

    if(parameters.orderBy && Object.keys(parameters.orderBy).length>0){
      Url = addSortParams(parameters.orderBy,Url,"&")
    }
    axiosClient
      .get(Url)
      .then((res) => {
        const data = res.data;
          dispatch(fetchStaffListSuccess({ data, page }));
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        dispatch(fetchStaffListSuccess({ data:[], page }));
        dispatch(setLoadingFalse(actionTypes.SET_STAFF_LOADING_FALSE))
      }).finally(()=>{
        if(closeModal)
          closeModal()
        if(isLoadingState && setLoading)
          setLoading(false)
        if(setSearchLoading)
          setSearchLoading(false)
      })
  };
};

export const deleteStaff = (body,page=0) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{ sources }
    } = getState();
    // /?hotel=${selectedHotel.hotel_code}
    axiosClient
      .delete(
        `/accounts/registration/staff/?id=${body.id}`
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Staff successfully deleted..",
          type: 'success'
        }))
        let data = sources?.staff_list?.data || []
        if(body.id){
          data = data.filter((e)=>e.id != body.id)
          dispatch(fetchStaffListSuccess({ data, page }));
        }
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};

export const fetchStaffListSuccess = (data) => {
  return {
    type: actionTypes.FETCH_STAFF_LIST_SUCCES,
    payload: data,
  };
};

export const fetchEmailTransactionSuccess = (data) => {
  return {
    type: actionTypes.FETCH_EMAIL_TRANSACTION_LIST_SUCCES,
    payload: data,
  };
};

export const fetchSmsTransactionSuccess = (data) => {
  return {
    type: actionTypes.FETCH_SMS_TRANSACTION_LIST_SUCCES,
    payload: data,
  };
};

export const fetchScrapUrlSuccess = (data) => {
  return {
    type: actionTypes.FETCH_Scrap_Url_LIST_SUCCES,
    payload: data,
  };
};

export const fetchMessageTempleteSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MESSAGE_TEMPLETE_LIST_SUCCES,
    payload: data,
  };
};

// action creator type return
export const fetchEmailTemplateSuccess = (data) => {
  return {
    type: actionTypes.FETCH_EMAIL_TEMPLATE_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchPdfSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PDF_LIST_SUCCES,
    payload: data,
  };
};

export const fetchReqForData = (data) => {
  return {
    type: actionTypes.FETCH_REQ_FOR_SETTINGS_TALBLES,
    payload: data,
  };
};

export const setLoadingFalse = (type) => {
  return {
    type: type,
    payload: false,
  };
};

export const fetchReqForDataSuccessCommon = (data,acc_type) => {
  return {
    type: acc_type,
    payload: data,
  };
};

export const fetchTextSuccess = (data) => {
  return {
    type: actionTypes.FETCH_TEXT_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchCustomFilterSuccess = (data) => {
  return {
    type: actionTypes.FETCH_CUSTOM_FILTER_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchCustomFilterFailure = (data) => {
  return {
    type: actionTypes.FETCH_CUSTOM_FILTER_DATA_FAILURE,
    payload: data,
  };
};


export const fetchDepartmentSuccess = (data) => {
  return {
    type: actionTypes.FETCH_DEPARATMENT_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchDepartmentFailure = (data) => {
  return {
    type: actionTypes.FETCH_DEPARATMENT_DATA_FAILURE,
    payload: data,
  };
};

export const fetchGuestReqTypeSuccess = (data) => {
  return {
    type: actionTypes.FETCH_GUESTREQTYPE_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchGuestReqTypeFailure = (data) => {
  return {
    type: actionTypes.FETCH_GUESTREQTYPE_DATA_FAILURE,
    payload: data,
  };
};

export const changeLoadingStatePlaceAndEvent = (data,key="PLACE") => {
  return {
    type: actionTypes.SET_PLACE_EVENT_LOADING,
    payload: data,
    key:key
  };
};

export const AddNewStaff = (body,isEdit=false, closeModel, setLoading,updateUser=false) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    body.username = body.email
    body.hotels = body.profile.hotels
    delete body.profile.hotels

    if(body.password1 === "")
      delete body.password1
    if(body.password2 === "")
      delete body.password2

    const url = `/accounts/${isEdit ? `user/` : 'registration/'}?hotel=${selectedHotel.hotel_code}`;
    const method = isEdit ? 'patch' : 'post';
    axiosClient[method](
        url,
        body
      )
      .then((res) => {

        closeModel();
        dispatch(setGlobalMessage({
          mess: "Details are successfully saved..",
          type: 'success'
        }))
       if(updateUser){
        dispatch(fetchUserDetailSuccess(res.data))
       }
       else{
        dispatch(fetchStaffListFromSetting(0))
       }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        setLoading(false)
      });

  };
};

export const SwithAccountAction = (body) => {
  return (dispatch) => {
    axiosClient
      .post(
        `/accounts/super-admin/accounts/`,
        body
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Account successfully switched",
          type: 'success'
        }))
        dispatch(fetchBrandData())
        // dispatch(fetchSideNavLinks())
        // dispatch(fetchFilters())
        dispatch(fetchHotels())
        // dispatch(fetchFilterTopics())
        // dispatch(RESET_STORE())
      })
      .catch((error) => {
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};

export const fetchMessageSchedulList = (page = 0,setLoading=null) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .get(`/messages/campaigns/?hotel=${selectedHotel.hotel_code}`)
      .then((res) => {
        const data = res.data;
        dispatch(fetchCampaignListSuccess(data))
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
      });
  };
};

export const AddNewCampaign = (body, closeModel, setLoading) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .post(
        `/messages/campaigns/?hotel=${selectedHotel.hotel_code}`,
        body
      )
      .then((res) => {

        closeModel();
        dispatch(setGlobalMessage({
          mess: "Details are successfully saved..",
          type: 'success'
        }))
        dispatch(fetchMessageSchedulList())
        setLoading(false)
      })
      .catch((error) => {
        //check_auth_error(error);
        let errorMsg = error.message;
        let ERROR = error?.response?.data?.non_field_errors || []
        if (ERROR.length > 0) {
          for (let e of ERROR)
            dispatch(setGlobalMessage({
              mess: e,
              type: 'error'
            }))
        } else
          dispatch(setGlobalMessage({
            mess: error.message,
            type: 'error'
          }))
        setLoading(false)
      });

  };
};

export const EditCampaign = (body, closeModel = null, setLoading = null) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .patch(
        `/messages/campaigns/${body.id}/?hotel=${selectedHotel.hotel_code}`,
        body
      )
      .then((res) => {

        if (closeModel)
          closeModel();
        dispatch(setGlobalMessage({
          mess: "Details are successfully saved..",
          type: 'success'
        }))
        dispatch(fetchMessageSchedulList())
        if (setLoading)
          setLoading(false)
      })
      .catch((error) => {
        //check_auth_error(error);
        let errorMsg = error.message;
        let ERROR = error?.response?.data?.non_field_errors || []
        if (ERROR.length > 0) {
          for (let e of ERROR)
            dispatch(setGlobalMessage({
              mess: e,
              type: 'error'
            }))
        } else
          dispatch(setGlobalMessage({
            mess: error.message,
            type: 'error'
          }))
        if (setLoading)
          setLoading(false)
      });

  };
};

export const deleteCampaign = (body, closeModel = null, setLoading = null) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .delete(
        `/messages/campaigns/${body.id}/?hotel=${selectedHotel.hotel_code}`,
        body
      )
      .then((res) => {
        if (closeModel)
          closeModel();
        dispatch(setGlobalMessage({
          mess: "Details are successfully deleted..",
          type: 'success'
        }))
        dispatch(fetchMessageSchedulList())
        if (setLoading)
          setLoading(false)
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        if (setLoading)
          setLoading(false)
      });

  };
};

export const fetchEmailTransaction = (page = 0) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .get(`/messages/campaigns/transactions/email/?hotel=${selectedHotel.hotel_code}`)
      .then((res) => {
        const data = res.data;
        console.log(data);
        dispatch(fetchEmailTransactionSuccess(data))
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        let errorMessage = error.message
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        dispatch(setLoadingFalse(actionTypes.SET_EMAIL_LOADING_FALSE))
      });
  };
};

export const fetchSmsTransaction = (page = 0) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .get(`/messages/campaigns/transactions/sms/?hotel=${selectedHotel.hotel_code}`)
      .then((res) => {
        const data = res.data;
        console.log(data)
        dispatch(fetchSmsTransactionSuccess(data))
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        dispatch(setLoadingFalse(actionTypes.SET_SMS_LOADING_FALSE))
      });
  };
};

export const fetchScrapUrl = (page = 0,parameters={}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    const {q, setSearchLoading} = parameters
    axiosClient
      .get(`/scrapping/urls/?hotel=${selectedHotel.hotel_code}${q?`&q=${q}`:""}`)
      .then((res) => {
        const data = res.data;
        console.log(data)
        dispatch(fetchScrapUrlSuccess(data))
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
      }).finally((f)=>{
        if(setSearchLoading)
          setSearchLoading(false)
      })
  };
};

export const AddNewScrapUrl = (body, closeModel, setLoading) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .post(
        `/scrapping/urls/?hotel=${selectedHotel.hotel_code}`,
        body
      )
      .then((res) => {

        closeModel();
        dispatch(setGlobalMessage({
          mess: "Details are successfully saved..",
          type: 'success'
        }))
        dispatch(fetchScrapUrl())
        setLoading(false)
      })
      .catch((error) => {
        //check_auth_error(error);
        let errorMsg = error.message;
        let ERROR = error?.response?.data?.non_field_errors || []
        if (ERROR.length > 0) {
          for (let e of ERROR)
            dispatch(setGlobalMessage({
              mess: e,
              type: 'error'
            }))
        } else
          dispatch(setGlobalMessage({
            mess: error.message,
            type: 'error'
          }))
        setLoading(false)
      });

  };
};


export const deleteScrapUrl = (body) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .delete(
        `/scrapping/urls/${body.id}/?hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Url are successfully deleted..",
          type: 'success'
        }))
        dispatch(fetchScrapUrl())
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};

export const RefreshScrapUrl = (body) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .post(
        `/scrapping/ingestions/url/?hotel=${selectedHotel.hotel_code}`,
        body
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Url successfully refreshed..",
          type: 'success'
        }))
        dispatch(fetchScrapUrl())
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};

export const AddUpdateMessageTemplate = (body,isEdit=false, closeModel, setLoading) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{ MessageTemplate }
    } = getState();
    const url = `/messages/msg-templates/${isEdit ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;
    const method = isEdit ? 'patch' : 'post';

    axiosClient[method](url, body)
      .then((res) => {

       if( closeModel)
        closeModel();
        dispatch(setGlobalMessage({
          mess: "Details are successfully saved..",
          type: 'success'
        }))
        if(setLoading)
        setLoading(false)
        let data = MessageTemplate.data || []
        dispatch(fetchMessageTemple())
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        if(setLoading)
          setLoading(false)
        if(closeModel)
        closeModel();
      });

  };
};

export const fetchMessageTemple = (page = 0,params = {}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      
    } = getState();

    let str = ""
    for (let i in params){
        str+=`&${i}=${params[i]}`
    }

    axiosClient
      .get(`/messages/msg-templates/?hotel=${selectedHotel.hotel_code}${str}`)
      .then((res) => {
        const data = res.data;
        if(params.guest_user_id && !Array.isArray(data))
          data = [data]
        dispatch(fetchMessageTempleteSuccess(data))
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
      });
  };
};

// action creator 
export const fetchEmailTemp = (page = 0, params = {}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    let str = "";
    for (let i in params) {
      str += `&${i}=${params[i]}`;
    }

    axiosClient.get(`/messages/email-templates/?hotel=${selectedHotel.hotel_code}${str}`)
    .then((res) => {
    const data = res.data;
    if (params.guest_user_id && !Array.isArray(data))
       data = [data];
      dispatch(fetchEmailTemplateSuccess(data));
    })
    .catch((error) => {
      console.log(error);
    });
  };
};

export const addUpdateEmailTemplate = (body, closeModal, setLoading, isEdit = false) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();


    const formData = new FormData();

    if(body.name) formData.append("name", body.name);
    // formData.append("template", body.template); // The HTML file
    if(body.template && body.filename) formData.append("template", body.template, body.filename);
    else if(body.template) formData.append("template", body.template);

    const url = isEdit 
      ? `/messages/email-templates/${body.id}/?hotel=${selectedHotel.hotel_code}`  // Update existing template
      : `/messages/email-templates/?hotel=${selectedHotel.hotel_code}`;            // Add new template
    const method = isEdit ? "patch" : "post";

    setLoading(true);

    axiosClient[method](url, formData)
      .then((res) => {
        closeModal();
        dispatch({
          type: isEdit
            ? actionTypes.EDIT_EMAIL_TEMPLATE_SUCCESS
            : actionTypes.ADD_EMAIL_TEMPLATE_SUCCESS,
          payload: res.data,
        });
        console.log("getstate is",getState());


        dispatch(
          setGlobalMessage({
            mess: isEdit
              ? "Template updated successfully."
              : "Template uploaded successfully.",
            type: "success",
          })
        );
        setLoading(false);
        dispatch(fetchEmailTemp())
      })
      .catch((error) => {
        console.log(error);

        dispatch(
          setGlobalMessage({
            mess: error.message || `Failed to ${isEdit ? "update" : "upload"} the template.`,
            type: "error",
          })
        );
        setLoading(false);
      });
  };
};


export const saveEditedTemplate = (body, closeModal, setLoading, isEdit = false) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings: { EmailTemplate },
    } = getState();

    const formData = new FormData();
    
    if(body.name) formData.append("name", body.name);
    if(body.template) formData.append("template", body.template, body.filename);

  

    // Define URL and HTTP method based on isEdit flag
    const url = isEdit 
      ? `/messages/email-templates/${body.id}/?hotel=${selectedHotel.hotel_code}`  // Update existing template
      : `/messages/email-templates/?hotel=${selectedHotel.hotel_code}`;            // Add new template
    const method = isEdit ? "patch" : "post";

    setLoading(true);
    axiosClient[method](url, formData)
    .then((res) => {
      closeModal();
      dispatch({
        type: isEdit
          ? actionTypes.EDIT_EMAIL_TEMPLATE_SUCCESS
          : actionTypes.ADD_EMAIL_TEMPLATE_SUCCESS,
        payload: res.data,
      });
      // console.log("getstate is",getState());
      


      dispatch(
        setGlobalMessage({
          mess: isEdit
            ? "Template updated successfully."
            : "Template uploaded successfully.",
          type: "success",
        })
      );
      setLoading(false);
      dispatch(fetchEmailTemp())
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        setGlobalMessage({
          mess: `error.message || Failed to ${isEdit ? "update" : "upload"} the template.`,
          type: "error",
        })
      );
      setLoading(false);
    });
};
};


export const deleteEmailTemplate = (body) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{ EmailTemplate }
    } = getState();
    axiosClient
      .delete(
        `/messages/email-templates/${body.id}/?hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Data successfully deleted..",
          type: 'success'
        }))
        let data = EmailTemplate.data || []
        if(body.id){
          data = data.filter((e)=>e.id != body.id)
          dispatch(fetchEmailTemplateSuccess(data))
        }
        // dispatch(fetchScrapUrl())
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};

export const deleteMessageTemplete = (body) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{ MessageTemplate }
    } = getState();
    axiosClient
      .delete(
        `/messages/msg-templates/${body.id}/?hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Data successfully deleted..",
          type: 'success'
        }))
        let data = MessageTemplate.data || []
        if(body.id){
          data = data.filter((e)=>e.id != body.id)
          dispatch(fetchMessageTempleteSuccess(data))
        }
        // dispatch(fetchScrapUrl())
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};

export const fetchPdf = (page = 0,parameters={}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    const {q, setSearchLoading} = parameters
    axiosClient
      .get(`/scrapping/data-files/?hotel=${selectedHotel.hotel_code}${q?`&q=${q}`:""}`)
      .then((res) => {
        const data = res.data;
        console.log(data)
        dispatch(fetchPdfSuccess(data))
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
      }).finally((f)=>{
        if(setSearchLoading)
          setSearchLoading(false)
      })
  };
};

export const AddNewPdf = (body, setLoading) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .post(
        `/scrapping/data-files/?hotel=${selectedHotel.hotel_code}`,
        body
      )
      .then((res) => {

        dispatch(setGlobalMessage({
          mess: "PDF are successfully uploaded..",
          type: 'success'
        }))
        dispatch(fetchPdf())
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        //check_auth_error(error);
        let errorMsg = error.message;
        let ERROR = error?.response?.data?.non_field_errors || []
        if (ERROR.length > 0) {
          for (let e of ERROR)
            dispatch(setGlobalMessage({
              mess: e,
              type: 'error'
            }))
        } else
          dispatch(setGlobalMessage({
            mess: error.message,
            type: 'error'
          }))
      });


  };
};

export const deletePdf = (body) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .delete(
        `scrapping/data-files/${body.id}/?hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "PDF are successfully deleted..",
          type: 'success'
        }))
        dispatch(fetchPdf())
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};


export const testCampaign = (body, closeModel, setLoading) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .post(
        `/messages/campaigns/test/?hotel=${selectedHotel.hotel_code}`,
        body
      )
      .then((res) => {

        dispatch(setGlobalMessage({
          mess: "Request successfully completed",
          type: 'success'
        }))
       if(setLoading)
        setLoading(false)
       closeModel()
      })
      .catch((error) => {
 
        if(setLoading)
          setLoading(false)
        //check_auth_error(error);
        let errorMsg = "Something went wrong";
        const ERROR = error?.response?.data;
        
        if (ERROR && typeof ERROR !== "string") {
            if (Array.isArray(ERROR)) {
                errorMsg = ERROR.join(", ");
            } else if (typeof ERROR === "object") {
                const errors = [];
                Object.keys(ERROR).forEach(key => {
                    if (Array.isArray(ERROR[key])) {
                        errors.push(`${key}: ${ERROR[key].join(", ")}`);
                    } else {
                        errors.push(`${key}: ${ERROR[key]}`);
                    }
                });
                errorMsg = errors.join(" ");
            }
        }
        
        dispatch(setGlobalMessage({
            mess: errorMsg,
            type: 'error'
        }));
        
      });


  };
};

export const fetchPlaceAndEventData = (page=0,setLoading=false,isSetLoadingIsState=false,query=null,closeModel,parameters={}) => {
  return (dispatch, getState) => {
    let {
      filter: { selectedHotel,selectedCurrDate,selectedFilters },
      settings:{PlaceAndEvent},
      auth: { token }
    } = getState(); 
    const {  setSearchLoading = null } = parameters
   selectedFilters = selectedFilters[parameters["filterkey"]] || []
    if(!token)
      return

    const params = new URLSearchParams({
      hotel: selectedHotel.hotel_code,
      ...(page===null?{}:{page: page + 1}),
      ...(parameters.entityType?{type:parameters.entityType}:{})
    });

    if (query) {
      params.append("q", query);
    }
    
    let url = `/data-aggregator/places-events/?${params.toString()}`;

    if(selectedFilters.length>0)
      url = addFilterKey(selectedFilters,url,"&")

    if(parameters.orderBy && Object.keys(parameters.orderBy).length>0){
      url = addSortParams(parameters.orderBy,url,"&")
    }

    console.log(url,query)
    axiosClient
      .get(
        url
      )
      .then((response) => {
        const data = {
          data: [
            ...(page != 0 ? PlaceAndEvent[parameters?.entityType|| "PLACE"]?.data : []),
            ...response.data.results,
          ],
          page,
          count: response.data.count,
          previous:response.data.previous,
          next:response.data.next
        };
        dispatch(fetchReqForDataSuccessCommon({
          [parameters.entityType]:data
        },actionTypes.SET_TRIP_PLANNER_DATA));        if(setLoading){
          if(isSetLoadingIsState)
          setLoading(false)
          else
          dispatch(setLoading(false,parameters.entityType))
        }
      })
      .catch((error) => {
        if(setLoading){
          if(isSetLoadingIsState)
          setLoading(false)
          else{
            dispatch(setLoading(false,parameters.entityType))
          }
       
        }
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess:errorMsg || "Something went wrong",
          type:'error'
         }))
      }).finally(()=>{
        if(closeModel)
          closeModel()
        if(setSearchLoading)
          setSearchLoading(false)
      });
  };
};

export const fetchEventVenueData = (page=0,setLoading=false,isSetLoadingIsState=false,query=null,closeModel,parameters={}) => {
  return (dispatch, getState) => {
    let {
      filter: { selectedHotel,selectedCurrDate,selectedFilters },
      settings:{PlaceAndEvent},
      auth: { token }
    } = getState(); 
    const {  setSearchLoading = null } = parameters
   selectedFilters = selectedFilters[parameters["filterkey"]] || []
    if(!token)
      return

    

    // const params = new URLSearchParams({
    //   hotel: selectedHotel.hotel_code,
    //   page: page + 1,
    //   type:"EVENT_VENUE"
    // });
    
    const params = new URLSearchParams({
      hotel: selectedHotel.hotel_code,
      ...(page===null?{}:{page: page + 1}),
      ...(parameters.entityType?{type:parameters.entityType}:{})
    });
    
    if (query) {
      params.append("q", query);
    }
    
    let url = `/data-aggregator/places-events/?${params.toString()}`;

    if(selectedFilters.length>0)
      url = addFilterKey(selectedFilters,url,"&")

    if(parameters.orderBy && Object.keys(parameters.orderBy).length>0){
      url = addSortParams(parameters.orderBy,url,"&")
    }

    console.log(selectedFilters);
    console.log(url,query)
    
    // if(parameters.orderBy && Object.keys(parameters.orderBy).length>0){
    //   url = addSortParams(parameters.orderBy,url,"&")
    // }
    axiosClient
      .get(
        url
      )
      .then((response) => {
        const data = {
          data: [
            ...(page != 0 ? PlaceAndEvent["EVENT_VENUE"]?.data : []),
            ...response.data.results,
          ],
          page,
          count: response.data.count,
          previous:response.data.previous,
          next:response.data.next
        };
        dispatch(fetchReqForDataSuccessCommon({
          EVENT_VENUE:data
        },actionTypes.SET_TRIP_PLANNER_DATA));
        if(setLoading){
          if(isSetLoadingIsState)
          setLoading(false)
          else
          dispatch(setLoading(false))
        }
      })
      .catch((error) => {
       
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess:errorMsg || "Something went wrong",
          type:'error'
         }))
      })
  };
};

export const AddUpdateEvent_Place = (body,isEdit=false, closeModel, setLoading,imageUpdoadModal,parameters={}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{PlaceAndEvent}
    } = getState();
    const url = `/data-aggregator/places-events/${body?.id ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;
    const method = body?.id ? 'patch' : 'post';

    return axiosClient[method](url, body)
      .then((res) => {

      if(closeModel)
        closeModel();
        if(setLoading)
        setLoading(false)
        // dispatch(fetchPlaceAndEventData())
        let data = []
        if(isEdit){
          data =  PlaceAndEvent[res?.data?.type]?.data?.map((e)=>{
            if(e.id === res?.data.id)
              return res.data
            else
            return e
          })
        }
        else{
          data = [res.data,...PlaceAndEvent[res?.data?.type]?.data]
        }

       if(parameters?.event_venue)
        dispatch(fetchEventVenueData(null, null , null , null,null,{
          filterkey:"/settings-trip planner"}))
        else {
          let _d = {
            [res.data.type]: {
              ...PlaceAndEvent[res.data.type],
              data,
              ...(!isEdit?{count:PlaceAndEvent[res.data.type]?.count + 1}:{})
            }
          }
          dispatch(fetchReqForDataSuccessCommon(_d,actionTypes.SET_TRIP_PLANNER_DATA));
          dispatch(setGlobalMessage({
            mess: "Details are successfully saved..",
            type: 'success'
          }))
        }
        if(imageUpdoadModal)
          imageUpdoadModal(res.data)

        return res.data;
      })
      .catch((errors) => {
        let errorMsg;
  if (typeof errors === 'object' && errors !== null) {
    errorMsg = Object.keys(errors).map((key) => {
      const value = errors[key];

      if (Array.isArray(value)) {
        return `${key}: ${value.join(', ')}`;
      } else if (typeof value === 'string') {
        return `${key}: ${value}`;
      } else {
        return `${key}: ${JSON.stringify(value)}`;
      }
    }).join('\n');
  } else {
    errorMsg = "Something went wrong" || errorMsg;
  }

        dispatch(setGlobalMessage({
          mess: errorMsg,
          type: 'error'
        }));
        if(setLoading)
          setLoading(false)
        // closeModel();
      });

  };
};


export const AddUpdateEvent_Place_Images = (body,isEdit=false, closeModel, setLoading,page,parameters = {}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{PlaceAndEvent}
    } = getState();
    const url = `/data-aggregator/places-events-images/${isEdit ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;
    const method = isEdit ? 'patch' : 'post';
    const addInfo = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    axiosClient[method](url, body,addInfo)
      .then((res) => {
        if(closeModel)
        closeModel();
        dispatch(setGlobalMessage({
          mess: "Images are successfully saved..",
          type: 'success'
        }))
        if(setLoading)
        setLoading(false)
        // dispatch(fetchPlaceAndEventData(page,changeLoadingStatePlaceAndEvent,false))
        let data =  PlaceAndEvent[parameters.entityType]?.data?.map((e)=>{
          if(e.id === res?.data[0]?.place_or_event)
           {
            if(e.images)
              e.images.push(...res.data)
            else 
            e.images = res.data
           }
          return e
        })

        console.log({...PlaceAndEvent,data:data})
        let _d = {
          [res.data.type]: {
            ...PlaceAndEvent[res.data.type],
            data
          }
        }
        dispatch(fetchReqForDataSuccessCommon(_d,actionTypes.SET_TRIP_PLANNER_DATA));
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        let errorMsg = "An error occurred.";
        if (error.response && error.response.data) {
          const errors = error.response.data;
          errorMsg = Object.keys(errors).map((key) => `${key}: ${errors[key].join(', ')}`).join('\n');
        } else {
          errorMsg = error.message;
        }

        dispatch(setGlobalMessage({
          mess: errorMsg,
          type: 'error'
        }));
        if(setLoading)
          setLoading(false)
        // closeModel();
      });

  };
};

export const delete_Place_Events = (id,parameters={}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{PlaceAndEvent}
    } = getState();
    axiosClient
      .delete(
        `data-aggregator/places-events/${id}/?hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        let data = []
        data =  PlaceAndEvent[parameters.entityType]?.data?.filter((e)=>{
          if(e.id !== id)
          return e
        
        })
        // console.log(data);
        
        let _d = {
          [parameters.entityType]: {
            ...PlaceAndEvent[parameters.entityType],
            data,
            count:PlaceAndEvent[parameters.entityType]?.count - 1
          }
        }
        // console.log(_d)
        dispatch(fetchReqForDataSuccessCommon(_d,actionTypes.SET_TRIP_PLANNER_DATA));
        dispatch(setGlobalMessage({
          mess: "Data successfully deleted..",
          type: 'success'
        }))
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};

export const delete_Place_Events_image = (id,page,parameters={}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{PlaceAndEvent}
    } = getState();
    axiosClient
      .delete(
        `data-aggregator/places-events-images/${id}/?hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Data successfully deleted..",
          type: 'success'
        }))
          let data = []
          data =  PlaceAndEvent[parameters.entityType]?.data?.map((e)=>{
            if(e.id === parameters.id)
            {
                if(e.images){
                  e.images = e.images.filter(i=>i.id!== id)
                }     
            }
           return e
          })
          let _d = {
            [parameters.entityType]: {
              ...PlaceAndEvent[parameters.entityType],
              data,
            }
          }
          dispatch(fetchReqForDataSuccessCommon(_d,actionTypes.SET_TRIP_PLANNER_DATA));
        // dispatch(fetchPlaceAndEventData(page,changeLoadingStatePlaceAndEvent,false))
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        let errorMsg = "An error occurred.";
        if (error.response && error.response.data) {
          const errors = error.response.data;
          console.log(errors)
          errorMsg = Object.keys(errors).map((key) => `${key}: ${errors[key]}`);
        } else {
          errorMsg = error.message;
        }

        dispatch(setGlobalMessage({
          mess: errorMsg,
          type: 'error'
        }));
      });

  }
};


export const fetchText = (page,parameters={}) => {
  return function (dispatch, getState) {
    const {
      filter: { selectedHotel },
    } = getState();
    const {q, setSearchLoading} = parameters
    let url = `/scrapping/text-data/`
    if (selectedHotel.hotel_code)
      url = url.concat(`?hotel=${selectedHotel.hotel_code}${q?`&q=${q}`:""}`)
    axiosClient
      .get(url)
      .then((resp) => {
        dispatch(fetchTextSuccess(resp.data));
      })
      .catch(({ response = {} }) => {
        dispatch(fetchTextSuccess([]));
        dispatch(setGlobalMessage({
          mess: "Something went wrong",
          type: 'error'
        }))
        console.log("sources err response", response);
      }).finally((f)=>{
        if(setSearchLoading)
          setSearchLoading(false)
      })
  };
};


export const AddUpdateText = (body,isEdit=false, closeModel, setLoading) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    const url = `/scrapping/text-data/${isEdit ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;
    const method = isEdit ? 'patch' : 'post';

    axiosClient[method](url, body)
      .then((res) => {

       if( closeModel)
        closeModel();
        dispatch(setGlobalMessage({
          mess: "Details are successfully saved..",
          type: 'success'
        }))
        if(setLoading)
        setLoading(false)
        dispatch(fetchText())
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        if(setLoading)
          setLoading(false)
        if(closeModel)
        closeModel();
      });

  };
};


export const deleteText = (body) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{ Text }
    } = getState();
    axiosClient
      .delete(
        `/scrapping/text-data/${body.id}/?hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Data successfully deleted..",
          type: 'success'
        }))
        let data = Text.data || []
        if(body.id){
          data = data.filter((e)=>e.id != body.id)
          dispatch(fetchTextSuccess(data))
        }
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};


export const AddUpdateCustomFilter = (body,isEdit=false, closeModel, setLoading) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    const url = `/accounts/guests/filters/${isEdit ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;
    const method = isEdit ? 'patch' : 'post';
console.log(method,isEdit,url,body)
    axiosClient[method](url, body)
      .then((res) => {

       if( closeModel)
        closeModel();
        dispatch(setGlobalMessage({
          mess: "Details are successfully saved..",
          type: 'success'
        }))
        if(setLoading)
        setLoading(false)
        dispatch(fetchCustomFilter())
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        if(setLoading)
          setLoading(false)
        if(closeModel)
        closeModel();
      });

  };
};

export const fetchCustomFilter = (page = 0,params = {}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      
    } = getState();

    let str = ""
    for (let i in params){
        str+=`&${i}=${params[i]}`
    }

    axiosClient
      .get(`/accounts/guests/filters/?hotel=${selectedHotel.hotel_code}${str}`)
      .then((res) => {
        const data = res.data;

        dispatch(fetchCustomFilterSuccess(data))
        
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchCustomFilterFailure(error.message))
        //check_auth_error(error);
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });
  };
};

export const deleteCustomFiltere = (body) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings:{ CustomFilter }
    } = getState();
    axiosClient
      .delete(
        `/accounts/guests/filters/${body.id}/?hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Data successfully deleted..",
          type: 'success'
        }))
        let data = CustomFilter.data || []
        if(body.id){
          data = data.filter((e)=>e.id != body.id)
          dispatch(fetchCustomFilterSuccess(data))
        }
      })
      .catch((error) => {
        console.log(error, error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};


export const uploadBulkStaff = (body,setLoading,setResult) => {
  return (dispatch,getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .post(
        `/accounts/user/bulk-registration/?hotel=${selectedHotel.hotel_code}`,
        body
      )
      .then((res) => {
        // dispatch(setGlobalMessage({
        //   mess: "Staff Successfully Created",
        //   type: 'success'
        // }))
        dispatch(fetchStaffListFromSetting())
        console.log(res.data);
        
        if(setResult) setResult(res.data)
      })
      .catch((error) => {
        const { response } = error;
        if (response) {
          if (response.status === 400) {
            if (setResult) setResult(response.data);
          } else {
            // dispatch(setGlobalMessage({
            //   mess: response.data?.detail || "Something went wrong",
            //   type: 'error'
            // }));
            setResult({
              detail:response.data?.detail || "Something went wrong"
            })
          }
        } else {
          console.log("Error without response:", error);
          // dispatch(setGlobalMessage({
          //   mess: error.message || "Something went wrong",
          //   type: 'error'
          // }));
          setResult({
            detail: "Something went wrong"
          })
        }
      })
      .finally(()=>{
        if(setLoading)
          setLoading(false)

      })

  };
};

export const downloadSampleFile = (setLoading) => (dispatch) => {

  axiosClient
    .get('/accounts/staff/accounts-template/')
    .then((response) => {
      const { download_url } = response.data; // Get the download URL from response
      if (download_url) {
        // Create a link element to download the file
        const a = document.createElement('a');
        a.href = download_url
        // a.href = download_url.replace("http","https");
        a.download = download_url.split('/').pop(); // Set the file name to the last part of the URL (or specify manually)
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up the DOM
        
        // Dispatch a success message
        dispatch(
          setGlobalMessage({
            mess: 'File is successfully downloaded',
            type: 'success',
          })
        );
      } else {
        throw new Error('Download URL not found in response');
      }
    })
    .catch((error) => {
      console.error('Error downloading the file:', error);
      // Dispatch an error message
      dispatch(
        setGlobalMessage({
          mess: 'Error in downloading the file',
          type: 'error',
        })
      );
    })
    .finally(() => {
      setLoading && setLoading(false); // Reset loading state
    });
};


export const fetchDepartment = (page = 0,params = {}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      
    } = getState();

    let str = ""
    for (let i in params){
        str+=`&${i}=${params[i]}`
    }

    axiosClient
      .get(`/services/hotels/departments/?hotel=${selectedHotel.hotel_code}${str}`)
      .then((res) => {
        const data = res.data;

        dispatch(fetchDepartmentSuccess(data))
        
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchDepartmentFailure(error.message))
        //check_auth_error(error);
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });
  };
};


export const AddUpdateAndDeleteDepartment = (
  body,
  isEdit = false,
  closeModel,
  setLoading,
  reqType = "post"
) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    const isDelete = reqType === "delete";
    const url = `/services/hotels/departments/${isEdit || isDelete ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;

    console.log(reqType, isEdit, url, body);

    axiosClient[reqType](url, body)
      .then((res) => {
        dispatch(
          setGlobalMessage({
            mess: isDelete ? "Department is successfully deleted." : "Details are successfully saved.",
            type: "success",
          })
        );

        dispatch(fetchDepartment());
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        dispatch(
          setGlobalMessage({
            mess: error.message || "An error occurred.",
            type: "error",
          })
        );
      })
      .finally(() => {
        if(closeModel)
        closeModel();
      if(setLoading)
        setLoading(false);
      });
  };
};

export const fetchGuestReqType = (page = 0,params = {}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      
    } = getState();

    let str = ""
    for (let i in params){
        str+=`&${i}=${params[i]}`
    }

    axiosClient
      .get(`/innspire-ai/users/guest-requests-types/?hotel=${selectedHotel.hotel_code}${str}`)
      .then((res) => {
        const data = res.data;

        dispatch(fetchGuestReqTypeSuccess(data))
        
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchGuestReqTypeFailure(error.message))
        //check_auth_error(error);
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });
  };
};


export const AddUpdateAndDeleteGuestReqType = (
  body,
  isEdit = false,
  closeModel,
  setLoading,
  reqType = "post"
) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      settings: { GuestReqType }
    } = getState();

    const isDelete = reqType === "delete";
    const url = `/innspire-ai/users/guest-requests-types/${isEdit || isDelete ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;

    console.log(reqType, isEdit, url, body);

    axiosClient[reqType](url, body)
      .then((res) => {
        dispatch(
          setGlobalMessage({
            mess: isDelete ? "Guest Req. type is successfully deleted." : "Details are successfully saved.",
            type: "success",
          })
        );
        const data  = modifyData(GuestReqType.data,reqType,res?.data,body?.id)
        // console.log("data",data,GuestReqType,res.data,reqType,body);
        
        dispatch(fetchGuestReqTypeSuccess(data))
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        dispatch(
          setGlobalMessage({
            mess: error.message || "An error occurred.",
            type: "error",
          })
        );
      })
      .finally(() => {
        if(closeModel)
        closeModel();
      if(setLoading)
        setLoading(false);
      });
  };
};


export const updateHotelConfig = (data) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
   return axiosClient
      .patch(`/accounts/hotels/hotel-config/?hotel=${selectedHotel.hotel_code}`,
       data
      )
      .then((res) => {
        dispatch(
          setGlobalMessage({
            mess: "Details are successfully saved.",
            type: "success",
         })
        )
       return res.data;
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
      });
  };
};

