import React from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import CommonButton from "../../UI/CommonButton";

// Styled components for HeaderBox
const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: "24px 32px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#ffffff",
  // Removed boxShadow for a flat background
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  color: "#1a1a1a",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "26px",
}));

const SubtitleText = styled(Typography)(({ theme }) => ({
  color: "#6c757d",
  fontSize: "14px",
  marginTop: "4px",
}));

const RightButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "16px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: "40px",
  padding: "0px 16px",
  borderRadius: "8px",
  textTransform: "none",
  fontWeight: "medium",
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "-0.44px",
}));


// HeaderBox Component
const HeaderBox = ({
  handleSaveFile,
  handleUploadHtml,
  handleDownloadHtml,
  showUploadButton,
  showSaveButton,
  handleCancel,
  content,
}) => {
  return (
    <HeaderContainer>
      {/* Title and Subtitle */}
      <TitleContainer>
        {/* <TitleText variant="h4">HTML Editor</TitleText> */}
        <SubtitleText variant="h4">Edit HTML file</SubtitleText>
      </TitleContainer>

      {/* Buttons */}
      <RightButtonsContainer>
        {showUploadButton && (
          <StyledButton
            component="label"
            variant="text"
            sx={{
              backgroundColor: "#eeeff1",
              borderRadius: "8px",
              "&:hover": { backgroundColor: "#d6d7d9" },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "medium",
                color: "black",
                whiteSpace: "nowrap",
                textTransform: "none",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.44px",
              }}
            >
              Upload file
            </Typography>
            <input
              type="file"
              accept=".html"
              hidden
              onChange={handleUploadHtml}
            />
          </StyledButton>
        )}

        {/* rendering the save button  */}
        {showSaveButton && (
          <StyledButton
            onClick={handleSaveFile}
            variant="text"
            color="primary"
            sx={{
              backgroundColor: "green", 
              color: "#ffffff", 
              "&:hover": { backgroundColor: "darkgreen" }, 
            }}
          >
            Save Edited File
          </StyledButton>
        )}

        <StyledButton
          onClick={handleDownloadHtml}
          variant="text"
          color="primary"
          sx={{
            backgroundColor: "#007bff",
            color: "#ffffff",
            "&:hover": { backgroundColor: "#0056b3" },
          }}
        >
          Download file
        </StyledButton>

        {(content && content != "<p><br></p>") && (<StyledButton
          onClick={handleCancel}
          variant="text"
          color="primary"
          sx={{
            backgroundColor: "#f44336",
            color: "#ffffff",
            "&:hover": { backgroundColor: "#d32f2f" },
          }}
        >
          Cancel
        </StyledButton>)}


      </RightButtonsContainer>
    </HeaderContainer>
  );
};

export default HeaderBox;
