import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ImageUpload from "./ImageUpload";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { commonFetchData, fetchPlace_EventForm } from "../../commons/utility";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUpdateEvent_Place,
  AddUpdateEvent_Place_Images,
} from "../../commons/components/Settings/actions/settingsActions";
// import LoadingOverlay from "../../commons/components/Loader/LoadingOverlay";
import { setGlobalMessage } from "../../actions/dashboardActions";

const getHotelPhone = (d) => {
  if (d) {
    let sp = d.split("(+)");
    return +sp[sp.length - 1] || "";
  } else return "";
};

const Information = ({
  setPublishState,
  setId,
  id = null,
  triggerSaveRef = null,
  previousFormValues,
}) => {
  const [optionData, setOptionData] = useState({});
  const [bodyData, setbodyData] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );

  useEffect(() => {
    console.log(bodyData);
    
    previousFormValues.current.final = bodyData;
  }, [bodyData]);

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          setLoading(true);
          const _d = await commonFetchData(
            `/data-aggregator/places-events/${id}/?hotel=${selectedHotel.hotel_code}`,
            { req: "get", onErrorData: {} }
          );
          if (Object.keys(_d).length > 0) {
            let initialData = {
              ...bodyData,
              category: _d.category.value,
              name: _d.name,
              description: _d.description,
              source_page_url: _d.source_page_url,
              menu_url: _d.menu_url,
              tickets_url: _d.tickets_url,
              address: _d.address,
              pincode: _d.pincode,
              phone_number: _d.phone_number,
              at_hotel: _d.at_hotel,
              is_hotel_recommended: _d.is_hotel_recommended,
              hotel_recommendation_score: _d.hotel_recommendation_score,
            };  
            setbodyData(initialData);
            setImages(_d.images || []);
            previousFormValues.current = {
              initial: initialData,
              final: initialData,
            };
            setPublishState(_d.enabled);
            setLoading(false);
          } else {
            setLoading(false);
            dispatch(
              setGlobalMessage({
                mess: "No data found",
                type: "error",
              })
            );
            setId(null)
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      const Data = await fetchPlace_EventForm(selectedHotel?.hotel_code);
      if (Object.keys(Data).length > 0) {
        setOptionData(Data);
      }
    })();
  }, []);

  const isValid = () => {
    let valid = true;
    let requiredFields = ["name", "category"];

    if (!bodyData.at_hotel) {
      requiredFields.push("address");
    }

    setFormError({});

    requiredFields.forEach((key) => {
      if (!bodyData[key] || bodyData[key].trim().length === 0) {
        valid = false;
        setFormError((prev) => ({ ...prev, [key]: "This field is required" }));
      }
    });

    // Validate phone number
    if (bodyData.phone_number && !/^\d{10,}$/.test(bodyData.phone_number)) {
      valid = false;
      setFormError((prev) => ({
        ...prev,
        phone_number: "Phone number must be at least 10 digits",
      }));
    }

    return valid;
  };

  const handleChange = (e) => {
    e?.stopPropagation();
    let { name, value, checked,type } = e.target;
    console.log(name, value, checked);

    if (name === "pincode" || name === "hotel_recommendation_score") {
      value = Math.abs(parseInt(value)) || "";
    }

    if (name == "at_hotel" && checked) {
      setbodyData((p) => {
        let d = {
          address: selectedHotel?.address || "",
          phone_number: getHotelPhone(
            selectedHotel?.hotel_config?.phone_number
          ),
          pincode: selectedHotel?.pincode || "",
          [name]: checked,
        };
        return {
          ...p,
          ...d,
        };
      });

      if (formError["address"])
        setFormError((prev) => ({ ...prev, ["address"]: "" }));

      return;
    } else if (name == "at_hotel" && !checked) {
      setbodyData((p) => {
        let d = {
          address: "",
          phone_number: "",
          pincode: "",
          [name]: checked,
        };
        return {
          ...p,
          ...d,
        };
      });

      return;
    }

    if (formError[name] && `${value}`.trim())
      setFormError((prev) => ({ ...prev, [name]: "" }));
    setbodyData((prev) => ({
      ...prev,
      [name]: e.target.type === "checkbox" ?checked : value,
    }));
  };

  const inputHandler = (data, id) => {
    console.log(data);
    if (!data || data.length === 0) return;
    const formData = new FormData();
    formData.append("place_or_event", id);

    for (let i = 0; i < data.length; i++) {
      if (!data[i].file) continue;
      console.log(data[i].file);
      formData.append("images", data[i].file);
    }
    dispatch(
      AddUpdateEvent_Place_Images(formData, false, null, null, 0, {
        entityType: bodyData.type,
      })
    );
  };

  const handleSave = () => {
    console.log(bodyData);

    if (!isValid()) return;
    setLoading(true);
    let data = bodyData;
    let cat = optionData.categories.find((e) => e.value === bodyData.category);
    data.type = cat.type;
    if (id) {
      dispatch(AddUpdateEvent_Place({ ...data, id }, true, null, setLoading));
    } else {
      delete data.id;
      dispatch(AddUpdateEvent_Place(data, false, null, setLoading, null)).then(
        (data) => {
          console.log("Response Data:", data);
          inputHandler(images, data.id);
          setId(data.id);
          params.set("id", data.id);
          console.log(params.toString());

          history.replace({ search: params.toString() });
        }
      );
    }
  };

  useEffect(() => {
    if (triggerSaveRef) triggerSaveRef.current = () => handleSave();
  }, [handleSave]);
  return (
    <Grid container spacing={4}>
      {/* <LoadingOverlay isLoading={loading} message="" /> */}
      <Grid
        item
        xs={12}
        md={6}
        spacing={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "48px",
        }}
      >
        <Stack spacing={3}>
          <Box>
            <Typography variant="h6" fontWeight="600">
              Select category
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Box>

          <Box>
            <Typography variant="body2" color="textPrimary">
              Category
            </Typography>
            <TextField
              name="category"
              value={bodyData.category || ""}
              onChange={handleChange}
              size="small"
              fullWidth
              select
              disabled={loading}
              error={formError["category"]}
              helperText={formError["category"]}
            >
              <MenuItem value="" disabled>
                Select Category
              </MenuItem>
              {optionData?.categories &&
                optionData?.categories.length > 0 &&
                optionData?.categories.map((i, idx) => (
                  <MenuItem key={i.value} value={i.value}>
                    {i.label}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
        </Stack>
        <Stack spacing={3}>
          <Box>
            <Typography variant="h6" fontWeight="600">
              Restaurant info
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Box>

          <Box>
            <Typography variant="body2" color="textPrimary">
              Name
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="name"
              value={bodyData.name}
              onChange={handleChange}
              error={formError["name"]}
              helperText={formError["name"]}
              disabled={loading}
            />
            {/* <Typography variant="caption" color="textSecondary">
              Maximum of 120 characters
            </Typography> */}
          </Box>

          <Box>
            <Typography variant="body2" color="textPrimary">
              Description
            </Typography>
            <TextField
              fullWidth
              size="small"
              multiline
              rows={4}
              value={bodyData.description}
              onChange={handleChange}
              name="description"
              error={formError["description"]}
              helperText={formError["description"]}
              disabled={loading}
            />
            {/* <Typography variant="caption" color="textSecondary">
              Maximum of 560 characters
            </Typography> */}
          </Box>

          <Box>
            <Typography variant="body2" color="textPrimary">
              Website URL
            </Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="https://www.url.com"
              type="url"
              name="source_page_url"
              value={bodyData.source_page_url}
              onChange={handleChange}
              error={formError["source_page_url"]}
              helperText={formError["source_page_url"]}
              disabled={loading}
            />
            {/* <Typography variant="caption" color="textSecondary">
              Maximum of 560 characters
              Provide a valid website URL
            </Typography> */}
          </Box>

          <Box>
            <Typography variant="body2" color="textPrimary">
              Menu URL
            </Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="https://www.url.com/menu"
              type="url"
              name="menu_url"
              value={bodyData.menu_url}
              onChange={handleChange}
              error={formError["menu_url"]}
              helperText={formError["menu_url"]}
              disabled={loading}
            />
            {/* <Typography variant="caption" color="textSecondary">
              Maximum of 560 characters
              Provide a valid website URL
            </Typography> */}
          </Box>

          <Box>
            <Typography variant="body2" color="textPrimary">
              Reservation URL
            </Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="https://www.url.com/ticket/"
              type="url"
              name="tickets_url"
              value={bodyData.tickets_url}
              onChange={handleChange}
              error={formError["tickets_url"]}
              helperText={formError["tickets_url"]}
              disabled={loading}
            />
            {/* <Typography variant="caption" color="textSecondary">
              Maximum of 560 characters
              Provide a valid website URL
            </Typography> */}
          </Box>
        </Stack>

        <Stack spacing={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "22px",
            }}
          >
            <div>
              <Typography variant="h6" fontWeight="600">
                Contact information
              </Typography>
              <Divider sx={{ my: 1 }} />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  name="at_hotel"
                  onChange={handleChange}
                  value={bodyData.at_hotel}
                  color="success"
                  checked={bodyData.at_hotel || false}
                  error={formError["at_hotel"]}
                  helperText={formError["at_hotel"]}
                  fullWidth
                  disabled={loading}
                />
              }
              label="At hotel"
            />
            <Box>
              <Typography variant="body2" color="textPrimary">
                Address
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="address"
                value={bodyData.address}
                onChange={handleChange}
                multiline
                maxRows={4}
                error={formError["address"]}
                helperText={formError["address"]}
                disabled={loading}
              />
              {/* <Typography variant="caption" color="textSecondary">
                Maximum of 240 characters
              </Typography> */}
            </Box>

            <Box>
              <Typography variant="body2" color="textPrimary">
                Postal code
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="pincode"
                value={bodyData.pincode}
                onChange={handleChange}
                type="number"
                error={formError["pincode"]}
                helperText={formError["pincode"]}
                disabled={loading}
              />
              {/* <Typography variant="caption" color="textSecondary">
                Maximum of 240 characters
              </Typography> */}
            </Box>

            <Box>
              <Typography variant="body2" color="textPrimary">
                Phone
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="phone_number"
                value={bodyData.phone_number}
                onChange={handleChange}
                type="number"
                error={formError["phone_number"]}
                helperText={formError["phone_number"]}
                disabled={loading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">(+)</InputAdornment>
                  ),
                }}
              />
              {/* <Typography variant="caption" color="textSecondary">
                Maximum of 240 characters
              </Typography> */}
            </Box>
          </Box>
        </Stack>
        <Stack spacing={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "22px",
            }}
          >
            <div>
              <Typography variant="h6" fontWeight="600">
                Recommended
              </Typography>
              <Divider sx={{ my: 1 }} />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_hotel_recommended"
                  onChange={handleChange}
                  value={bodyData.is_hotel_recommended}
                  color="success"
                  checked={bodyData.is_hotel_recommended || false}
                  error={formError["is_hotel_recommended"]}
                  helperText={formError["is_hotel_recommended"]}
                  fullWidth
                  disabled={loading}
                />
              }
              label="Recommended by the hotel"
            />
            <Box>
              <Typography variant="body2" color="textPrimary">
                Score
              </Typography>
              <TextField
                fullWidth
                size="small"
                // label="Hotel Recommmendation Score"
                name="hotel_recommendation_score"
                value={bodyData.hotel_recommendation_score}
                onChange={handleChange}
                type="number"
                error={formError["hotel_recommendation_score"]}
                helperText={formError["hotel_recommendation_score"]}
                disabled={loading}
              />
            </Box>
          </Box>
        </Stack>

        <Stack spacing={3}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              sx={{
                backgroundColor: "#EEF0F1",
                color: "#000000",
                fontSize: "14px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#ABB0B8",
                },
              }}
              disabled={loading}
            >
                {loading ? (
        <CircularProgress size={24} color="success"/> 
      ) : (
        "Save" 
      )}
            </Button>
            <Button
               disabled={loading}
              sx={{
                border: "1px solid #E4E6E7",
                color: "#000000",
                fontSize: "14px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "rgb(228, 230, 231)",
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <ImageUpload
          images={images}
          setImages={setImages}
          id={id}
          type={bodyData.type}
          inputHandler={inputHandler}
        />
      </Grid>
    </Grid>
  );
};

export default Information;
