// import { fetchUserDetail } from "../../../../actions/loginActions";
import { setFetchGuest } from "../../../../apps/Chat/actions/chatActions";
import {
  check_auth_error,
  convert_dates_to_query_params,
} from "../../../../components/Utility/Utility";
import axiosClient from "../../../../httpClient";
import { saveStaffToken } from "../../../utility";
import * as actionTypes from "./actionTypes";

export const setCurrDate = (date_obj) => {
  console.log(date_obj);
  return {
    type: actionTypes.SET_CURR_DATE,
    payload: date_obj,
  };
};

export const setCompDate = (date_obj) => {
  return {
    type: actionTypes.SET_COMP_DATE,
    payload: date_obj,
  };
};

export const resetSelectedFilterExceptMe = (key) => {
  return {
    type: actionTypes.RESET_SELECTED_FILTER_EXCEPT_ME,
    payload: key,
  };
};

export const setStatOption = (opt) => {
  return {
    type: actionTypes.SET_SELECTED_STAT_OPTION,
    payload: opt,
  };
};

export const setStatStartOption = (date) => {
  return {
    type: actionTypes.SET_SELECTED_STAT_START_DATE,
    payload: date,
  };
};

export const setStatEndOption = (date) => {
  return {
    type: actionTypes.SET_SELECTED_STAT_END_DATE,
    payload: date,
  };
};

export const setCompOption = (opt) => {
  return {
    type: actionTypes.SET_SELECTED_COMP_OPTION,
    payload: opt,
  };
};

export const setCompStartOption = (date) => {
  return {
    type: actionTypes.SET_SELECTED_COMP_START_DATE,
    payload: date,
  };
};

export const setCompEndOption = (date) => {
  return {
    type: actionTypes.SET_SELECTED_COMP_END_DATE,
    payload: date,
  };
};

export const fetchFilterRequest = () => {
  return {
    type: actionTypes.FETCH_FILTER_REQUEST,
  };
};

export const fetchFilterSuccess = (filters) => {
  return {
    type: actionTypes.FETCH_FILTER_SUCCESS,
    payload: filters,
  };
};

export const fetchFilterError = (error) => {
  return {
    type: actionTypes.FETCH_FILTER_ERROR,
    payload: error,
  };
};

export const setSelectedFiltersForAll = (data) => {
  return {
    type: actionTypes.SET_SELECTED_FILTERS_FOR_ALL,
    payload: data,
  };
};

// export const resetFilters = (filters,key)=>{
//   return function (dispatch, getState) {
//   var _sel_filters = [];
//   for (var f of filters) {
//     let d = null
//     if(f?.default_query_value && f?.options && f.options.length>0){
//        d = f?.options.find(e=>e.query_value == f.default_query_value )
//     }
//     if (d){
//       var _f = { ...d, query_key: f.query_key };
//       _sel_filters.push(_f);
//     }
//      else if(f?.options[0]){
//       var _f = { ...f.options[0], query_key: f.query_key };
//       _sel_filters.push(_f);
//     }
//   }
//   console.log(`setting default filters: ${JSON.stringify(_sel_filters)}`);
//   dispatch(setSelectedFilters(_sel_filters,key));
// }
// }

export const resetFilters = (filters, key,parameters={ selectedHotelChange: false}) => {
  return (dispatch, getState) => {
    const { selectedHotelChange } = parameters
    const selectedFilters = filters.map(f => {
      const { default_query_value, options = [], query_key } = f;

      const selectedOption = options.find(e => e.query_value === default_query_value) || options[0];

      return selectedOption ? { ...selectedOption, query_key } : null;
    }).filter(Boolean);

    console.log(`setting default filters: ${JSON.stringify(selectedFilters)}`,"ffffffffffff",filters);
    dispatch(setSelectedFilters(selectedFilters, key,selectedHotelChange));
  };
};


export function fetchFilters(Url,page="review",parameters={}) {
  return function (dispatch, getState) {
    let { resetFlag = false, key="", isHotelChange=false}  = parameters   
    const selectedFilters = getState().filter?.selectedFilters[key] || [];
    console.log(parameters, selectedFilters) 
    dispatch(fetchFilterRequest());
   if(page=="review"){
    const selectedHotel = getState().filter.selectedHotel || {};
    const selectedCurrDate = getState().filter.selectedCurrDate || {};
    const selectedCompDate = getState().filter.selectedCompDate || {};
    const date_params = `date=${convert_dates_to_query_params(
      selectedCurrDate.option,
      selectedCurrDate.startDate,
      selectedCurrDate.endDate
    )}&comp-date=${convert_dates_to_query_params(
      selectedCompDate.option,
      selectedCompDate.startDate,
      selectedCompDate.endDate
    )}`;
    axiosClient
      .get(`/analytics/filters/?hotel=${selectedHotel.hotel_code}${date_params?`&${date_params}`:""}`)
      .then((res) => {
        if (res.status != 204) {
          var _data = [...res.data];
          // _data.push({
          //   name: "Sentiment",
          //   query_key: "sentiment",
          //   options: [
          //     { display_value: "All", query_value: "All" },
          //     { display_value: "Positive", query_value: "Positive" },
          //     { display_value: "Negative", query_value: "Negative" },
          //     { display_value: "Mixed", query_value: "Mixed" },
          //     { display_value: "Neutral", query_value: "Neutral" },
          //   ],
          // });
          dispatch(fetchFilterSuccess(_data));
          if(resetFlag)
           dispatch( resetFilters(_data,key))
          else if(isHotelChange)
            dispatch( resetFilters(_data,key,{ selectedHotelChange: true}))
          else
          {
            if ( selectedFilters.length == 0)
              dispatch(resetFilters(_data,key))
          }
            // dispatch(resetSelectedFilterExceptMe(key))
        } else {
          dispatch(fetchFilterSuccess([]));
        }
      })
      .catch((error) => {
        //check_auth_error(error);
        dispatch(fetchFilterError(error.response?.msg));
      });
   }
else if (Url) {
  axiosClient
  .get(Url)
  .then((res) => {
    if (res.status != 204) {
      console.log(res.data,page);
      
      dispatch(fetchFilterSuccess(res.data));
      if(page=="chat" && res.data.length==0)
        dispatch(setFetchGuest(true))
      if(resetFlag)
        dispatch( resetFilters(res.data,key))
       else if(isHotelChange)
         dispatch( resetFilters(res.data,key,{ selectedHotelChange: true}))
       else
       {
         if ( selectedFilters.length == 0)
           dispatch(resetFilters(res.data,key))
       }
        // dispatch( resetFilters(res.data,key,{ selectedHotelChange: true}))
    } else {
      dispatch(fetchFilterSuccess([])); }
  })
  .catch((error) => {
    //check_auth_error(error);
    dispatch(fetchFilterError(error?.response?.msg));
  });
}
  };
}

export const setSelectedFilters = (selections,key,selectedHotelChange=false) => {
  return {
    type: actionTypes.SET_SELECTED_FILTERS,
    payload: selections,
    key,
    selectedHotelChange
  };
};

export const unselectSelectedFilters = (selection,key) => {
  return {
    type: actionTypes.UNSET_SELECTED_FILTER,
    payload: selection,
    key
  };
};

export const fetchFilterTopicsRequest = () => {
  return {
    type: actionTypes.FETCH_FILTER_TOPICS_REQUEST,
  };
};

export const fetchFilterTopicsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FILTER_TOPICS_SUCCESS,
    payload: data,
  };
};

export const fetchFilterTopicsError = (error) => {
  return {
    type: actionTypes.FETCH_FILTER_TOPICS_ERROR,
    payload: error,
  };
};

export function fetchFilterTopics(includeSentiment = false) {
  return function (dispatch, getState) {
    dispatch(fetchFilterTopicsRequest);
    const selectedHotel = getState().filter.selectedHotel || {};
    const sd = getState().filter.selectedCurrDate;
    const date_params = convert_dates_to_query_params(
      sd.option,
      sd.startDate,
      sd.endDate
    );
    axiosClient
      .get(
        `/analytics/filters/topics/?hotel=${
          selectedHotel.hotel_code
        }&${date_params}${includeSentiment ? "&sentiment=true" : ""}`
      )
      .then((res) => {
        if (res.status != 204) {
          dispatch(fetchFilterTopicsSuccess(res.data));
        } else {
          dispatch(fetchFilterTopicsError("Filters not found"));
        }
      })
      .catch((error) => {
        //check_auth_error(error);
        dispatch(fetchFilterTopicsError(error.response.msg));
      });
  };
}

export const setSelectedFilterTopics = (selections) => {
  return {
    type: actionTypes.SET_SELECTED_FILTER_TOPICS,
    payload: selections,
  };
};

export const setSelectedReviewId = (reviewId) => {
  return {
    type: actionTypes.SET_SELECTED_REVIEWID,
    payload: reviewId
  }
}

export function fetchHotelRequest() {
  return {
    type: actionTypes.FETCH_HOTEL_REQUEST,
  };
}

export function fetchHotelSuccess(hotels) {
  return {
    type: actionTypes.FETCH_HOTEL_SUCCESS,
    payload: hotels,
  };
}

export function fetchHotelError(error) {
  return {
    type: actionTypes.FETCH_HOTEL_ERROR,
    payload: error,
  };
}

export function fetchHotels() {
  return function (dispatch, getState) {
    const {
      gen: {fcm_token}
    } = getState()
    dispatch(fetchHotelRequest());
    axiosClient
      .get(`/services/hotels/?subscription=true&competitor=false`)
      .then((res) => {
        if (res.status != 204) {
          dispatch(fetchHotelSuccess(res.data));

          if(!fcm_token && window && window?.ReactNativeWebView){
            try {
              let payload = {
                type: 'send_fmc_token',
                data:""
              }
              console.log("Sending req for fcm token....................................")
              window.ReactNativeWebView.postMessage(JSON.stringify(payload));
              console.log("Sent req for fcm token.......................................")
            } catch (error) {
              console.log("Send event to native failed due to -",error)
            }
          }
          else {
            console.log("react Native web element is not present")
          }



        //  if(Object.keys(user).length===0)
        //  dispatch(fetchUserDetail())
        } else {
          dispatch(fetchHotelSuccess([]));
        }
      })
      .catch((error) => {
        //check_auth_error(error);
        dispatch(
          fetchHotelError(error.response?.msg || "Something went wrong!")
        );
      });
  };
}

export function setSelectedHotel(hotel) {
  return {
    type: actionTypes.SET_SELECTED_HOTEL,
    payload: hotel,
  };
}

export function setSelectedHotelData(data) {
  return {
    type: actionTypes.SET_SELECTED_HOTEL_DATA,
    payload: data,
  };
}

export const fetchFilterSuccessForComponent = (data) => {
  return {
    type: actionTypes.FETCH_FILTER_SUCCESS_FOR_COM,
    payload: data,
  };
};

export const fetchFilterErrorForComponent = (error) => {
  return {
    type: actionTypes.FETCH_FILTER_ERROR_FOR_COM,
    payload: error,
  };
};

export const setSelectedFiltersForComponent = (selections) => {
  return {
    type: actionTypes.SET_SELECTED_FILTERS_FOR_COM,
    payload: selections,
  };
};

export const unselectSelectedFiltersForComponent = (selection) => {
  console.log(selection)
  return {
    type: actionTypes.UNSET_SELECTED_FILTER_FOR_COM,
    payload: selection,
  };
};

export function fetchFilterForComponents(Url,includeSentiment = false) {
  return function (dispatch, getState) {
    // dispatch(fetchFilterRequest);
    const selectedHotel = getState().filter.selectedHotel || {};
    axiosClient
      .get(Url)
      .then((res) => {
        if (res.status != 204) {
          dispatch(fetchFilterSuccessForComponent(res.data));
        } else {
          dispatch(fetchFilterErrorForComponent("Filters not found"));
        }
      })
      .catch((error) => {
        //check_auth_error(error);
        dispatch(fetchFilterErrorForComponent(error?.response?.msg));
      });
  };
}
