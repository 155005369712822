import React, { useEffect, useState, useMemo } from "react";
import { Box, Divider, Grid, Paper, Skeleton, Typography } from "@mui/material"; // Import Skeleton
import { TP_API_KEY } from "../../components/Constants/Constants";
import { useSelector } from "react-redux";
import { Settings, LinkOff } from '@mui/icons-material';
// import { validateUrl } from "../../commons/utility";

const StatusMessage = ({ icon, title, message, showRetry = false, onRetry = null }) => {
  const Icon = icon || Settings;
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
        p: 4,
        textAlign: 'center'
      }}
    >
      <Box sx={{ position: 'relative', mb: 4 }}>
        <Icon
          sx={{
            fontSize: '64px',
            color: icon === LinkOff ? 'error.main' : 'primary.main',
            position: 'relative',
          }}
        />
      </Box>

      {/* Text Content */}
      <Typography variant="h6" sx={{ mb: 1, color: 'text.primary' }}>
        {title || "Configuration Required"}
      </Typography>
      
      <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary', maxWidth: '300px' }}>
        {message || "Please configure the Trip Planner app URL to continue"}
      </Typography>

      {/* Retry Button (conditional) */}
      {showRetry && onRetry && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2
          }}
        >
          <button
            onClick={onRetry}
            style={{
              padding: '8px 16px',
              backgroundColor: '#1976d2',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontWeight: 500
            }}
          >
            Retry
          </button>
        </Box>
      )}
    </Box>
  );
};

const LoadingPreview = () => (
  <Box sx={{ p: 2, width: "100%" }}>
    {/* Header skeleton */}
    <Skeleton variant="rectangular" width="70%" height={30} sx={{ mb: 2 }} />
    
    {/* Content skeletons */}
    <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" width="90%" height={20} sx={{ mb: 1 }} />
    <Skeleton variant="rectangular" width="80%" height={20} sx={{ mb: 1 }} />
    <Skeleton variant="rectangular" width="85%" height={20} sx={{ mb: 1 }} />
    
    {/* Action area skeleton */}
    <Box sx={{ mt: 3, display: "flex", gap: 1 }}>
      <Skeleton variant="rectangular" width={100} height={36} />
      <Skeleton variant="rectangular" width={100} height={36} />
    </Box>
  </Box>
);



const Preview = ({ id, isSaveTriggered }) => {
  const [url, setUrl] = useState("");
  const [loading,setLoading] = useState(true)
  const [error, setError] = useState(null);
  const TP_CONFIG = useSelector(
    (state) => state.filter?.selected_hotel_data?.hotel_config?.trip_planner_config || {}
  );

  const iframeUrl = useMemo(() => {
    // return `${process.env.REACT_APP_TRIP_PLANNER_BASE_URL}?path=description&ID=${id}&server=${process.env.REACT_APP_Mode}&key=${TP_API_KEY}`;
    if(TP_CONFIG?.tp_app_url) {
      return `${TP_CONFIG?.tp_app_url}&ID=${id}`
    }
    return null
  }, [id]);

  const handleRetry = () => {
    setError(null);
    setLoading(true);
    setUrl('');
    setTimeout(() => {
      setUrl(iframeUrl);
    }, 500);
  };

useEffect(() => {
    if (isSaveTriggered?.current) {
      const timer = setTimeout(() => {
        setUrl(iframeUrl);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setUrl(iframeUrl);
    }
}, [isSaveTriggered?.current, iframeUrl]);

useEffect(() => {
  const handleIframeError = () => {
    setLoading(false);
    setError("Failed to load content. This might be due to CORS restrictions or an invalid URL.");
  };

  const iframe = document.getElementById("tp_ifr");
  if (iframe) {
    iframe.addEventListener('error', handleIframeError);
    
    const timeoutId = setTimeout(() => {
      if (loading && url) {
        setLoading(false);
        setError("Content failed to load. Please check your connection and try again.");
      }
    }, 15000);

    return () => {
      iframe.removeEventListener('error', handleIframeError);
      clearTimeout(timeoutId);
    };
  }
}, [url, loading]);

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "32px"
  };

  const paperStyles = {
    py: 5,
    backgroundColor: "#f6f6f6",
    borderRadius: 2,
    height: "100%",
    width: "100%"
  };

  const boxStyles = {
    backgroundColor: "white",
    borderRadius: 4,
    overflow: "hidden",
    boxShadow: 3,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    margin: "auto",
  };

  const iframeStyles = {
    border: 0,
    width: "100%",
    height: "190vh"
  };

  const renderContent = () => {
    if (!TP_CONFIG?.tp_app_url) {
      return (
        <StatusMessage 
          icon={Settings}
          title="Configuration Required"
          message="Please configure the Trip Planner app URL to continue"
        />
      );
    }
    
    if (error) {
      return (
        <StatusMessage
          icon={LinkOff}
          title="Connection Error"
          message={error}
          showRetry={true}
          onRetry={handleRetry}
        />
      );
    }
    
    return (
      <>
        { loading && <LoadingPreview />}
         <iframe
          id="tp_ifr"
          src={url}
          style={iframeStyles}
          allowFullScreen
          onLoad={(e) => {
            e?.stopPropagation();
            setLoading(false);
          }}
        />
      </>
    );
  };

  return (
    <Grid container sx={containerStyles}>
      <Divider sx={{ my: 1 }} />
      <Paper sx={paperStyles}>
        <Box sx={boxStyles}>
          {renderContent()}
        </Box>
      </Paper>
    </Grid>
  );
};

export default Preview;
