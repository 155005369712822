import React from 'react'
import Editor from "./Editor"

function index () {
  return (
    <div>
      <Editor /> {/* Use the Editor component */}
    </div>
  )
}

export default index;
