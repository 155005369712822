import React, { useEffect, useMemo, useRef,useCallback, useState } from 'react'
import {
  Box,
  Typography,
} from "@mui/material";
import Heading from './Heading';
import CommonTableUI from '../../commons/components/Settings/components/CommonTableUI';
import { useDispatch, useSelector } from 'react-redux';
import { changeLoadingStatePlaceAndEvent, delete_Place_Events, fetchPlaceAndEventData } from '../../commons/components/Settings/actions/settingsActions';
import CommonSingleRow from '../../commons/components/Settings/components/CommonSingleRow';
import { formatDate, SplitDateAndTime } from '../../commons/utility';
import { colorWithType } from '../../components/Utility/Constants';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { CustomRating } from './CustomRating';
import SearchInput from './commonComp/SearchInput';
import DialogBox from '../../commons/components/PopUp/DialogBox';


const filterRoute = `/trip-planner`
const columns = [
  { name: "Date",orderBy:"start_time"},
  { name: "Time",orderBy:"start_time"},
  { name: "Name",orderBy:"name"},
  { name: "Venue",orderBy:"venue_name"},
  { name: "Category",orderBy:"display_category"},
  // { name: "Distance",orderBy:"distance"},
  { name: "Rating",orderBy:"rating"},
  { name: "Status",orderBy:"status"},
  {name:"Action"}
];

const dimension = {
  start_time: { whiteSpace: 'nowrap' },
  // time: { maxWidth: "120px", minWidth: "120px" },
  // name: { maxWidth: "300px", minWidth: "300px" },
  // venue: { maxWidth: "120px", minWidth: "120px" },
  // category: { maxWidth: "150px", minWidth: "150px" },
  // rating: { maxWidth: "120px", minWidth: "120px" },
  // status: { maxWidth: "80px", minWidth: "80px" }
};



const EventTab = () => {
  const APITrigger = useRef(null);
  const TableContainerRef = useRef(null);
  const ComponentDataRef = useRef({});
   const [deleteDialog, setDeleteDialog] = useState(false);
   const [operationData, setOperationData] = useState({});

  const navObj = useSelector((state) => state.nav.selectedNav || {});
  const selectedFilters = useSelector(
    (state) => state.filter.selectedFilters[`${filterRoute}`] || []
  );

  const selectedHotel = useSelector(
          (state) => state.filter.selectedHotel || {}
        );
      const eventDataSelector = state => state.settings?.PlaceAndEvent?.EVENT?.data || []
      const eventCount = useSelector(state => state.settings?.PlaceAndEvent?.EVENT?.count || 0)
      const eventpage =  useSelector(state => state.settings?.PlaceAndEvent?.EVENT?.page || 0)
      const eventLoadingSelector = state => state.settings?.PlaceAndEvent?.EVENT?.loading
      const eventErrorSelector = state => state.settings?.PlaceAndEvent?.EVENT?.error



  const dispatch = useDispatch()
  const navigate = useHistory()
  const location = useLocation();
  
      const query = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return params.get("q") || "";
      }, [location.search]);

      useEffect(() => {
        if (APITrigger.current) clearTimeout(APITrigger.current);
        if (Object.keys(selectedHotel).length > 0 
        &&   selectedFilters.length > 0
      ) {
          APITrigger.current = setTimeout(() => {

          dispatch(changeLoadingStatePlaceAndEvent(true,"EVENT"))
          dispatch(fetchPlaceAndEventData(0,changeLoadingStatePlaceAndEvent,false,query,null,{
            filterkey:`${filterRoute}`,
            entityType:"EVENT"
          }));
          }, 0);
        }

      }, [selectedFilters]);


            const fetchEvents = (newPage,q=null,parameter={})=>{
              const searchQuery = q === null ? query : q;
              dispatch(changeLoadingStatePlaceAndEvent(true,"EVENT"))
              dispatch(fetchPlaceAndEventData(newPage,changeLoadingStatePlaceAndEvent,false,  searchQuery,null,{
                filterkey:`${filterRoute}`,
                entityType:"EVENT",
                ...parameter
              }));
            }

            const handleEdit = (data)=>{
              navigate.push(`/trip-planner/manage?id=${data.id}&entity=EVENT`) 
            }

                  const hadleDelete = ()=>{
                    dispatch(delete_Place_Events(operationData.id,{
                      entityType:operationData.type,
                    }))
                    setDeleteDialog(false)
                  }
          

            const menuItems = [
              { label: 'Edit', onClick: handleEdit },
              { label: 'Delete', onClick: (d)=>{
                setDeleteDialog(true)
                setOperationData(d)
              } },
              
            ]

            const RowComponent = ({row={},singleRowRef,skipRowIndices=[]}) => {
              const dateTime = SplitDateAndTime(row.start_time,"dateTime") || {}
              let payload = {
                start_time: dateTime.date,
                time:dateTime.time,
                name:row.name,
                venue:row.venue_name,
                category:row.display_category?.label,
                // distance:row.distance || "--",
                rating:  CustomRating(row.rating) ,
                status: row.status ? <Box sx={{
                  display:'inline-block',
                  borderRadius:"16px",
                  background:colorWithType[row.status]?.bg || colorWithType["new"]?.bg,
                  color:colorWithType[row.status]?.tx || colorWithType["new"]?.tx,
                  padding:"4px 8px"
                 }}>
              <Typography
              sx={{
                fontSize:"12px",
                lineHeight:"18px",
                letterSpacing:"0.4px"
              }}
              >
                {row.status}
              </Typography>
                 </Box>:"",
            }

            const css = { whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize'}
            
            return(
              <>
          <CommonSingleRow
               {...(singleRowRef ? {ref:singleRowRef} : {})}
            key={`${row?.id}-${row?.created_at}`}
            payload={ payload}
            showMenu={true}
            menuItems={menuItems}
            id={row.id}
            fullData = {row}
            commonCss={css}
            dimension={dimension}
            skipRowIndices={skipRowIndices}
            // imageList ={row?.images || []}
          />
                </>
              );
            };

    return <Box 
    sx={{
      display:"flex",
      flexDirection:"column",
      justifyContent:'start',
      alignItems:"start",
      gap:"12px",
      width:'100%'
    }}
    >
 <Heading title="Events" count={9} />
<CommonTableUI
columns={columns}
fetchData={fetchEvents}
dataSelector={eventDataSelector}
loadingSelector={eventLoadingSelector}
errorSelector={eventErrorSelector}
isEditPermission={true}
RowComponent = {RowComponent}
minRow={20}
count={eventCount}
page={eventpage}
initialFetch={false}
title={"Events"}
heading={false}
maxHeight={"600px"}
serverSidePagination={true}
useInfiniteScroll={true}
TableContainerRef={TableContainerRef}
ComponentDataRef={ComponentDataRef}
ordering={true}
refreshButton={true}
searchBar={true}
columnCtrl={true}
/>

<DialogBox
        open={deleteDialog}
        setOpen={setDeleteDialog}
        handleAgree={hadleDelete}
        agreeButtonText={"Delete"}
        content={"Please confirm before you delete"}
      />

 </Box>
}

export default EventTab