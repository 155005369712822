import React, { useEffect, useState } from "react";
import { InputAdornment, IconButton, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const SearchInput = ({ placeholder = "Search", onSearch,additonCssForParent={} }) => {
  const  location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState(params.get("q"));
  const [isFocused, setIsFocused] = useState(false);


  useEffect(()=>{
    const val = new URLSearchParams(location.search).get("q")
    if(searchValue!= val)
    setSearchValue(val);  
  },[location.search])

  // Handle input change
  const handleChange = (event) => {
    event?.stopPropagation()
    const value = event.target.value;
    setSearchValue(value);
  };


  const handleKeyDown = (event) => {
    event?.stopPropagation()
    if (event.key === "Enter") {
      if(onSearch)
        onSearch(searchValue);
        const params = new URLSearchParams(location.search);
        params.set("q", searchValue);
        history.push({ search: params.toString() });
    }
  };

  const handleClear = () => {
    setSearchValue("");
    if (onSearch) {
      onSearch("");
    }
    const params = new URLSearchParams(location.search);
    params.set("q", "");
    history.push({ search: params.toString() });
  };

  return (
    <Stack width={"450px"} direction="row" spacing={2} alignItems="center" sx={{ mt: 3,...additonCssForParent }}>
      <TextField
      sx={{
        mt: 3,
        transition: 'width 0.25s',
        width: isFocused || searchValue ? '100%' : '70%',
      }}

        size="small"
        fullWidth
        variant="outlined"
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ 
                // color: 'hsl(214, 8.1%, 61.2%)',
                // visibility: searchValue ? 'hidden' : 'visible',
                // transition: 'visibility 0.15s',
              }} />
            </InputAdornment>
          ),
          endAdornment: searchValue && (
            <InputAdornment position="end">
              <IconButton 
                onClick={handleClear}
                sx={{ 
                  // color: 'hsl(214, 8.1%, 61.2%)',
                  '&:hover': {
                    // color: 'hsl(210, 9.1%, 87%)',
                  },
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
              ),
            }}
      />

    </Stack>
  );
};

export default SearchInput;
