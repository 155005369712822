
import React, { Suspense, lazy } from "react";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import DynamicTitle from "./commons/components/DynamicTitle";
import FullScreenLoader from "./commons/components/UI/FullScreenLoader";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import TripPlannerViewDetails from "./commons/components/Settings/components/TripPlannerViewDetails";
import Survey from './apps/Survey/Survey';
import CreateOrEditSurvey from './apps/Survey/pages/ManageSurvey/CreateNewSurvey';
import UserSurvey from './apps/Survey/pages/FillSurvey/UserSurvey';
import SurveyNew from "./apps/Survey/SurveyNew";
import HtmlEditor from "./commons/components/HtmlEditor";
import HtmlEditorV2 from "./commons/components/HtmlEditorV2";
import TripPlanner from "./apps/TripPlanner/TripPlanner";
import AddNewItem from "./apps/TripPlanner/AddNewItem";
import CuratedList from "./apps/TripPlanner/CuratedList/CuratedList";
import AddEditCuratedList from "./apps/TripPlanner/CuratedList/AddEditCuratedList";
import Monitors from "./apps/TripPlanner/Monitors/Monitors";
import AnalyticsDashboard from "./apps/Analytics/AnalyticsDashboard";


const Landing_page = lazy(() => import("./components/Landing_page/Landing_page"));
const Login = lazy(() => import("./components/Login/Login"));
const Signup = lazy(() => import("./components/Signup/Signup"));
const Forgot_Password = lazy(() => import("./components/Forgot_Password/Forgot_Password"));
const Forgot_Password_Change = lazy(() => import("./components/Forgot_Password/Forgot_Password_Change"));
const PricingPlans = lazy(() => import("./components/PricingPlans/Pricing_New"));
const Hotel_Select = lazy(() => import("./components/Hotel_Select/Hotel_Select"));
const Payment = lazy(() => import("./components/Payment/Payment"));
// const Reviews = lazy(() => import("./apps/Reviews/Reviews"));
// const Overview = lazy(() => import("./apps/Overview/Overview"));
const Subscriptions = lazy(() => import("./components/Subscriptions/Subsciptions"));
const Case_Management = lazy(() => import("./components/Case_Management/Case_Management"));
const Error_page = lazy(() => import("./components/Error_Page/ErrorPage"));
const AccountManagement = lazy(() => import("./components/Account_Management/AccountManagement"));
// const Chat = lazy(() => import("./apps/Chat/Chat"));
const Sentimental = lazy(() => import("./components/Sentimental/Sentimental"));
// const Competitors = lazy(() => import("./components/Competitors/Competitors"));
const CheckoutSuccess = lazy(() => import("./components/Checkout/CheckoutSuccess"));
const CheckoutFailed = lazy(() => import("./components/Checkout/CheckoutFailed"));
const DataLoadStatus = lazy(() => import("./components/Loading/DataLoadStatus/DataLoadStatus"));
const AdministratorPortal = lazy(() => import("./components/AdministratorPortal/AdministratorPortal"));
const Notifications = lazy(() => import("./components/Notifications/Notifications"));
const MyProfile = lazy(() => import("./components/MyProfile/MyProfile"));
// const Faq = lazy(() => import("./components/FAQ/Faqs"));
const ChangePassword = lazy(() => import("./components/ChangePassword/ChangePassword"));
const MyReports = lazy(() => import("./components/MyReports/myReports"));
// import EssentialComponents from "./EssentialComponents";
const EssentialComponents = lazy(()=>import("./EssentialComponents"))


// const GuestRequests = lazy(() => import("./apps/GuestRequest/GuestRequest"));
// const TeamCommunication = lazy(() => import("./apps/TeamCommunication/TeamCommunication"));
// const Home = lazy(() => import("./apps/Home/Home"));
// const Settings = lazy(() => import("./commons/components/Settings/Settings"));
// const Review_Management = lazy(() => import("./components/Review_Management/Review_Management"));
// const Dashboard = lazy(()=>import("./components/Dashboard/Dashboard"))


const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    h1: {
      fontSize: 32,
      fontWeight: 500,
    },
    sidenav: {
      fontSize: 16,
      fontWeight: 500,
    },
    chatcateg: {
      fontSize: 12,
      fontWeight: 600,
      color: "#707070",
    },
    chatListUser: {
      fontSize: 14,
      fontWeight: 600,
    },
    color: "#181A1B",
    // body1: { 
    //   fontSize: '1rem',
    //   fontWeight: 400,
    //   lineHeight: 1.5,
    // },
  }
  ,
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: "8px 12px",
          borderRadius: "8px",
          "&.Mui-selected": {
            background: "#F1F2F3",
            borderRadius: "8px",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#F1F2F3",
            borderRadius: "8px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          background: "#f6f7f8cc",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-containedPrimary": {
            background: "#f6f7f8cc",
            color: "#191A1B",
          },
        },
      },
    },
  },
});

function App() {

  


  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <Suspense fallback={<FullScreenLoader/>}>
          <Switch>
          <Route path="/(home|reviews|team-communication|chat-support|guest-requests|dashboard|overview|review-management|login|analytics|settings|user-preferences)" component={EssentialComponents} />
            {/* <Route exact path="/" ><DynamicTitle title={"InnSpire.One"}><Landing_page /></DynamicTitle> </Route> */}
            {/* <Route
              exact
              path="/trip-planner/:id"
              render={(props) => (
                <DynamicTitle title={"View Details"}>
                <TripPlannerViewDetails/>
                </DynamicTitle>
              )}
            /> */}
            <Route
              exact
              path="/"
              render={(props) => (
                <DynamicTitle title={"Login"}>
                  <Login {...props} />
                </DynamicTitle>
              )}
            />
            {/* <Route exact path="/signup">
              <DynamicTitle title={"Signup"}>
                <Signup />
              </DynamicTitle>
            </Route> */}
            <Route exact path="/forgot-password">
              <DynamicTitle>
              <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_Mode=="prod"?process.env.REACT_APP_DEV_SITE_Key:process.env.REACT_APP_DEV_SITE_Key_dev}
    // language="[optional_language]"
    // useRecaptchaNet="[optional_boolean_value]"
    // useEnterprise="[optional_boolean_value]"
    scriptProps={{
      async: false, 
      defer: false, 
      appendTo: 'head', 
      nonce: undefined 
    }}
    // container={{ 
    //   element: "[required_id_or_htmlelement]",
    //   parameters: {
    //     badge: '[inline|bottomright|bottomleft]', 
    //     theme: 'dark', 
    //   }
    // }}
  >
                <Forgot_Password />
                </GoogleReCaptchaProvider>
              </DynamicTitle>
            </Route>
            <Route exact path="/password-reset/confirm">
              <DynamicTitle >
                <Forgot_Password_Change />
              </DynamicTitle>
            </Route>


            <Route exact path="/pricing-plans">
              <DynamicTitle title={"PricingPlans"}>
                <PricingPlans />
              </DynamicTitle>
            </Route>
            <Route exact path="/hotel-select">
              <DynamicTitle >
                <Hotel_Select />
              </DynamicTitle>
            </Route>
            <Route exact path="/payment">
              <DynamicTitle title={"Payment"}>
                <Payment />
              </DynamicTitle>
            </Route>



    
    


            <Route exact path="/subscriptions">
              <DynamicTitle title={"Subscriptions"}>
                <Subscriptions />
              </DynamicTitle>
            </Route>
            <Route exact path="/case-management">
              <DynamicTitle title={"Case Management"}>
                <Case_Management />
              </DynamicTitle>
            </Route>
            <Route exact path="/account-management">
              <DynamicTitle title={"AccountManagement"}>
                <AccountManagement />
              </DynamicTitle>
            </Route>
            <Route exact path="/sentimental">
              <DynamicTitle title={"Sentimental"}>
                <Sentimental />
              </DynamicTitle>
            </Route>
     


            {/* <Route exact path="/competitors" component={Competitors} /> */}

            <Route exact path="/checkout-success">
              <DynamicTitle title={"CheckoutSuccess"}>
                <CheckoutSuccess />
              </DynamicTitle>
            </Route>
            <Route exact path="/checkout-failed">
              <DynamicTitle title={"CheckoutFailed"}>
                <CheckoutFailed />
              </DynamicTitle>
            </Route>
            <Route exact path="/data-load-status">
              <DynamicTitle >
                <DataLoadStatus />
              </DynamicTitle>
            </Route>
            <Route exact path="/data-ingestion">
              <DynamicTitle title={"AdministratorPortal"}>
                <AdministratorPortal />
              </DynamicTitle>
            </Route>
            <Route exact path="/notifications">
              <DynamicTitle title={"Notifications"}>
                <Notifications />
              </DynamicTitle>
            </Route>
            <Route exact path="/my-profile">
              <DynamicTitle title={"MyProfile"}>
                <MyProfile />
              </DynamicTitle>
            </Route>
            {/* <Route exact path="/faq" component={Faq} /> */}
            {/* <Route exact path="/report/:id/:fileName" component={Report} /> */}
            <Route exact path="/survey">
              <DynamicTitle title={"Survey"}>
                <SurveyNew />
              </DynamicTitle>
            </Route>
         {/* <Route exact path="/survey" component={SurveyNew} /> */}
         {/* <Route exact path="/survey" component={Survey} /> */}
          <Route exact path="/survey/edit/:surveyId" component={CreateOrEditSurvey} />
          <Route exact path="/user-survey" component={UserSurvey} />
            <Route exact path="/change-password">
              <DynamicTitle title={"ChangePassword"}>
                <ChangePassword />
              </DynamicTitle>
            </Route>
            <Route exact path="/my-reports">
              <DynamicTitle title={"MyReports"}>
                <MyReports />
              </DynamicTitle>
            </Route>

{/* addint the html-editor route  */}
            <Route exact path="/html-editor">
              <DynamicTitle title={"Html Editor"}>
                <HtmlEditor />
              </DynamicTitle>
            </Route>

            {/* html editor route v2 */}
            <Route exact path="/html-editor-v2">
              <DynamicTitle title={"Html Editor - v2"}>
                <HtmlEditorV2 />
              </DynamicTitle>
            </Route>

            
            <Route exact path="/trip-planner/listings">
              <DynamicTitle title={"Trip Planner"}>
                <TripPlanner />
              </DynamicTitle>
            </Route>

            <Route exact path="/trip-planner/manage">
              <DynamicTitle title={"Trip Planner - Add New Item"}>
                <AddNewItem />
              </DynamicTitle>
            </Route>

            <Route exact path="/trip-planner/curated-lists">
              <DynamicTitle title={"Trip Planner - Curated List"}>
                <CuratedList />
              </DynamicTitle>
            </Route>

            <Route exact path="/trip-planner/curated-list/manage">
              <DynamicTitle title={"Trip Planner - Curated List Manage"}>
                <AddEditCuratedList />
              </DynamicTitle>
            </Route>

            <Route exact path="/trip-planner/monitors">
              <DynamicTitle title={"Trip Planner - Minitors"}>
                <Monitors />
              </DynamicTitle>
            </Route>

            <Route exact path="/analytics-dashboard/:pageName">
              <DynamicTitle title={"Mobile Checkin"}>
                <AnalyticsDashboard />
              </DynamicTitle>
            </Route>
      
      
            <Route>
              <DynamicTitle title={"Error Page"}>
                <Error_page />
              </DynamicTitle>
            </Route>

            
     

          </Switch>
          </Suspense>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
