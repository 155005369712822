import { useState } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Badge,
  Box,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NotificationList from "./NotificationList";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom";
import CrossButton from "../../../components/UI/buttons/crossButton";
import belIcon from '../../assets/bell-01.svg'
import { useDispatch, useSelector } from "react-redux";
import { updateNotification } from "../Navbar/actions/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const NotificationPopup = () => {
  const notificationStat = useSelector((state)=>state?.nav.notificationCountData || {})
  const [anchorEl, setAnchorEl] = useState(null);
  const transformOrigin = useMediaQuery((theme) => theme.breakpoints.down("sm"))
    ? "right"
    : "left";
  const [redirectTo, setRedirectTo] = useState(null);
  const [notification, setNotification] = useState(null);
  const dispatch = useDispatch()
  const history = useHistory()
  // const location  = useLocation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickNotification = (e, data) => {
    e.stopPropagation();
    if(data.id && !data.read)
      dispatch(updateNotification(data.id))
    if (data.redirect_url) {
      const originalUrl = new URL(data.redirect_url);
      const pathAndSearch = originalUrl.pathname + originalUrl.search;
      const newUrl = `${pathAndSearch}&type=notification`;
      if(window.location.pathname.includes('/trip-planner/'))
        window.open(newUrl,"_self")
      else
        history.push(newUrl)
    } else setNotification(data);
    handleClose();
  };

  const closeNotificationTextModal = () => {
    setNotification(null);
  };

  return (
    <Box>
      {redirectTo && <Redirect push to={redirectTo} />}
      <Button
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          color: "gray",
          borderRadius: "50%",
          minWidth: "40px",
          padding:0,
          marginTop:"8px"
        }}
      >
        <Badge sx={{
          fontSize:"8px"
        }} badgeContent={(notificationStat?.unread_count && notificationStat?.unread_count!==0)?notificationStat?.unread_count:null} color="error">
        <img width={18} height={18} src={belIcon} />
       </Badge>
   
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{ paper: { sx: { boxShadow: "none" } } }}
      >
        <NotificationList handleClickNotification={handleClickNotification} />
      </Popover>
      <Modal
        open={notification != null}
        onClose={closeNotificationTextModal}
        aria-labelledby="modal-notification-message"
        aria-describedby="modal-notification-message"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: "500px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            borderRadius: "8px",
            border: "1px solid #E4E6E7",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#E4E6E7",
              padding: "5px 10px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", color: "#6D7378", fontWeight: 600 }}
            >
              Notification
            </Typography>
            <CrossButton clickHandler={closeNotificationTextModal} />
          </Box>
          <Typography
            sx={{
              fontSize: 16,
              color: "#6D7378",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {notification?.message}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default NotificationPopup;
