import { Box, Checkbox, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AddUpdateAndDeleteCuratedList, fetchAllPlaceAndEventData, SubmitAutoPopulateNow } from '../action/TripPlannerAction';
import { commonFetchData } from '../../../commons/utility';
import { changeLoadingStatePlaceAndEvent } from '../../../commons/components/Settings/actions/settingsActions';
import CommonButton from '../../../commons/components/UI/CommonButton';
import { setGlobalMessage } from '../../../actions/dashboardActions';
// import LoadingOverlay from '../../../commons/components/Loader/LoadingOverlay';

const detectChange = (obj1, obj2) => {
console.log(obj1,"obj------",obj2);


  if (obj1.auto_populate !== obj2.auto_populate) return true;

  if (
    Array.isArray(obj1.auto_populate_categories) &&
    Array.isArray(obj2.auto_populate_categories)
  ) {
    if (obj1.auto_populate_categories.length !== obj2.auto_populate_categories.length) {
      return true;
    }

    const sorted1 = [...obj1.auto_populate_categories].sort();
    const sorted2 = [...obj2.auto_populate_categories].sort();

    if (!sorted1.every((val, index) => val === sorted2[index])) {
      return true;
    }
  } else if (obj1.auto_populate_categories !== obj2.auto_populate_categories) {
    return true;
  }

  return false;
};


const filterRoute = `/trip-planner`;
const CuratedListForm = ({triggerSaveRef,idsRef,data,selectALlRef,loading,setLoading,setComponentData,setInitialState}) => {

const [bodyData, setbodyData] = useState(data);
const [formError, setFormError] = useState({
  name:"",
  description:"",
});
const [formData, setFormData] = useState({});
const [auto_populate_loading, setauto_populate_loading] = useState(false);

const dispatch = useDispatch()
const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );
const location = useLocation()
const history = useHistory();
const params = new URLSearchParams(location.search)
const id = params.get("id")


useEffect(() => {
  (async () => {
    const response = await commonFetchData(
      `/planner/places-events/groups/form/?hotel=${selectedHotel.hotel_code}`,
      { req: "get", onErrorData: {} }
    );
    setFormData(response) 
  })();
}, []);

useEffect(()=>{ 
if(data)
  setbodyData(data)
},[data])

  const isValid = () => {
    let valid = true;
    let requiredFields = ["name", "description"];
  
    requiredFields.forEach((key) => {
      if (!bodyData[key] || bodyData[key].trim().length === 0) {
        valid = false;
        setFormError((prev) => ({ ...prev, [key]: "This field is required" }));
      }
    });
  
    return valid;
  };


  const handleChange = (e)=>{
    e?.stopPropagation()
    let { name, value,checked,type } = e.target;
    console.log(name,value,checked,type);

      if (formError[name] && `${value}`.trim())
        setFormError((prev) => ({ ...prev, [name]: "" }));

      if(name == "auto_populate"){ 
        setComponentData((p)=>({
        ...p,"bodyData":{...bodyData, [name]:type === "checkbox" ? checked : value},
        ignore_auto_populate:null
      }))
    }

    if (name === "auto_populate_categories") {
      let newValue = value;
      if (newValue.includes("all")) {
        newValue = ["all"]; 
      } else {
        newValue = newValue.filter(val => val !== "all");
      }
      value = newValue
    }
        setbodyData((prev)=>({
          ...prev,
          [name]:type === "checkbox" ? checked : value
          
      }))

}

    // const handleSave = () => {
          
    //       if (!isValid()) return;
    //       setLoading(true);
    //       let data = bodyData
    //       delete data.enabled
    //       delete data.event_or_place_list
    //       if(!bodyData.auto_populate){
    //         delete data.auto_populate_categories
    //         if (selectALlRef?.current)
    //           data.event_or_place_list_ids = true
    //           else if(Object.keys(idsRef?.current).length>0)
    //         data.event_or_place_list_ids = Object.keys(idsRef?.current) || []
    //       }
    //       else delete bodyData?.event_or_place_list_ids

    //            if(id){
    //                   dispatch(AddUpdateAndDeleteCuratedList({...data,id},true, setLoading,"patch"))
    //                 }
    //                 else{
    //                   dispatch(AddUpdateAndDeleteCuratedList(data,false, setLoading,"post")).then((data) => {
    //                       console.log("Response Data:", data);
    //                       params.set("id",data.id)
    //                       history.replace({ search: params.toString() });
    //                     console.log(bodyData);
    //                     }) 
    //                 }
    //   }


      const handleSave = async () => {
        if (!isValid()) return;  
        setLoading(true);
        try {
          const payload = {
            ...bodyData,
          };

          delete payload.enabled;
          delete payload.event_or_place_list;
      
          if (!payload.auto_populate) {
            delete payload?.auto_populate_categories
            if (selectALlRef?.current) {
              payload.event_or_place_list_ids = true;
            } else if (Object.keys(idsRef?.current || {}).length > 0) {
              payload.event_or_place_list_ids = Object.keys(idsRef?.current);
            }
          }
          if (id) {
            await dispatch(AddUpdateAndDeleteCuratedList(
              { ...payload, id },
              true,
              setLoading,
              "patch"
            )).then((response)=>{
              setInitialState({
                name: response.name,
                description: response.description,
                auto_populate: response.auto_populate,
                // personalize: response.personalize,
                event_or_place_list: response?.event_or_place_list || [],
                enabled: response.settings?.enabled || false,
                auto_populate_categories:response?.auto_populate_categories?.length>0 ?response.auto_populate_categories:[],
              });
            })
          } else {
            const response = await dispatch(AddUpdateAndDeleteCuratedList(
              payload,
              false,
              setLoading,
              "post"
            )).then((response)=>{
              setInitialState({
                name: response.name,
                description: response.description,
                auto_populate: response.auto_populate,
                // personalize: response.personalize,
                event_or_place_list: response?.event_or_place_list || [],
                enabled: response.settings?.enabled || false,
                auto_populate_categories:response?.auto_populate_categories?.length>0 ?response.auto_populate_categories:[],
              });
            })
            params.set("id", response.id);
            history.replace({ search: params.toString() });
          }
        } catch (error) {
          console.error("Error saving data:", error);
        }
      };

          useEffect(() => {
      
              if (triggerSaveRef)
                triggerSaveRef.current = () => handleSave();
             }, [handleSave]);


             const submitAutoPopulateNowHandler = (e)=>{
              e?.stopPropagation()

             const flag =  detectChange(data,bodyData)
              if (flag) {
                dispatch(
                  setGlobalMessage({
                    mess: "Please save the information first",
                    type: "warning",
                  })
                );
                return
              }

                  setauto_populate_loading(true)
                  dispatch(SubmitAutoPopulateNow({
                    id:id
                  },
                setauto_populate_loading
                )).then((d)=>{
                  const { event_or_place_list = [] }  = d
                  const updatedData = {event_or_place_list,auto_populate:true}
                  setInitialState((p)=>({...bodyData,...updatedData}))
                  setComponentData((p)=>({
                    ...p,"bodyData":{...p.bodyData,...updatedData},
                    ignor_auto_populate:true
                  }))
                        dispatch(changeLoadingStatePlaceAndEvent(true, "Curated_List_Options"));
                          dispatch(
                            fetchAllPlaceAndEventData(
                              0,
                              changeLoadingStatePlaceAndEvent,
                              false,
                               null,
                              null,
                              {
                                filterkey: filterRoute,
                                entityType: "Curated_List_Options",
                                params:{
                                  group_id:d.id,
                                  ...(d.id?{order_by:"selected"}:{})
                                }
                              }
                            )
                          );
                })
             }


  return (
    <Stack spacing={3} maxWidth={"50%"}>
      {/* <LoadingOverlay isLoading={loading} message="Saving data..." /> */}
      <Box>
        <Typography variant="h6" color="textPrimary">
          Information
        </Typography>
        <Divider sx={{ my: 1 }} />
      </Box>

      <Stack spacing={1}>
        <Typography variant="body2" color="textPrimary">
          Name
        </Typography>
        <TextField
          fullWidth
          name="name"
          variant="outlined"
          size="small"
          value={bodyData.name}
          onChange={handleChange}
          error={formError["name"]}
          helperText={formError["name"]}
          disabled={loading}
        />
        {/* <Typography variant="caption" color="textSecondary">
          Maximum of 240 characters
        </Typography> */}
      </Stack>

      <Stack spacing={1}>
        <Typography variant="body2" color="textPrimary">
          Description
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          multiline
          rows={4}
          placeholder="Write a description"
          name="description"
          value={bodyData.description}
          onChange={handleChange}
          error={formError["description"]}
          helperText={formError["description"]}
          disabled={loading}
        />
        {/* <Typography variant="caption" color="textSecondary">
          Maximum of 240 characters
        </Typography> */}
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Checkbox
          name="auto_populate"
          checked={bodyData.auto_populate}
          onChange={handleChange}
          error={formError["auto_populate"]}
          helperText={formError["auto_populate"]}
          disabled={loading}
          //  defaultChecked={bodyData.auto_populate}
        />
        <Typography variant="body2" color="textPrimary">
          Auto populate
        </Typography>
      </Stack>

    {
      bodyData?.auto_populate &&   <Stack spacing={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          // gap: "16px",
          // width:"100%"
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Typography variant="body2" color="textPrimary">
            Auto Populate Categories
          </Typography>
          <TextField
            name="auto_populate_categories"
            value={bodyData.auto_populate_categories || []}
            onChange={handleChange}
            size="small"
            fullWidth
            select
            SelectProps={{ multiple: true,
                renderValue: (selected) =>
                  formData?.auto_populate_categories
                    ?.filter((item) => selected.includes(item.value))
                    .map((item) => item.label)
                    .join(", "),
              }}
            disabled={loading}
            error={formError["auto_populate_categories"]}
            helperText={formError["auto_populate_categories"]}
            sx={{
              padding:"0px"
            }}
          >
            <MenuItem value="" disabled>
              Select Auto Populate Categorie
            </MenuItem>
            {formData?.auto_populate_categories &&
              formData?.auto_populate_categories.length > 0 &&
              formData?.auto_populate_categories.map((i, idx) => (
                <MenuItem key={i.value} value={i.value} >
                <Checkbox sx={{
                  // padding:0.5
                }} checked={(bodyData.auto_populate_categories || []).includes(i.value)}
                  disabled={i.value != "all" && bodyData.auto_populate_categories?.includes("all")}
                />
                   {i.label}
                </MenuItem>
              ))}
          </TextField>
        </Box>
{
  id &&       <CommonButton
  title={"Populate Now"}
  sx={{ color: "#000000", marginBottom: 0, alignSelf:"end" }}
  onClickHandler={submitAutoPopulateNowHandler}
  loading={auto_populate_loading}
  isReq={true}
/>
}
      </Box>
    </Stack>
    }

      {/* <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox
                     name="personalize"
                     checked={bodyData.personalize}
                     onChange={handleChange}
                     error={formError["personalize"]}
                     helperText={formError["personalize"]}
                     disabled={loading}
                    //  defaultChecked={bodyData.personalize}
              />
              <Typography variant="body2" color="textPrimary">
                Personalize
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox
                     name="enabled"
                     checked={bodyData.enabled || false}
                     onChange={handleChange}
                     error={formError["enabled"]}
                     helperText={formError["enabled"]}
                     disabled={loading}
                    //  defaultChecked={bodyData.enabled}
              />
              <Typography variant="body2" color="textPrimary">
                Enable
              </Typography>
            </Stack> */}
    </Stack>
  );
}

export default CuratedListForm



