import React, { useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { Box, Tabs, Tab, styled } from '@mui/material';
import '../styles/EditorStyles.css'

// Styled components
const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#ffffff',
  paddingLeft: '24px',
  paddingTop: '16px',
}));

const StyledTab = styled(Tab)(({ theme, selected }) => ({
  textTransform: 'none',
  color: selected ? '#007bff' : '#6c757d',
  fontWeight: selected ? 'bold' : 'normal',
}));

const EditorBox = styled(Box)(({ theme }) => ({
  padding: '24px',
  backgroundColor: '#ffffff',
  flexGrow: 1,
  overflowY: 'auto',
}));

const HtmlBox = styled(Box)(({ theme }) => ({
  padding: '16px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  backgroundColor: '#f9f9f9',
  height: '100%',
  overflowY: 'auto',
}));

const EditorComponent = ({ content, setContent, tabIndex, handleTabChange, placeholder }) => {
  const editor = useRef(null);

  // JoditEditor configuration
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: content ? '' : placeholder || 'Start typing...',
      toolbarSticky: true,
      toolbarStickyOffset: -25,
    }),
    [placeholder]
  );

  return (
    <>
      {/* Tabs Area */}
      <StyledTabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="editor tabs"
        TabIndicatorProps={{ style: { backgroundColor: '#007bff' } }}
      >
        <StyledTab label="Edit" selected={tabIndex === 0} />
        <StyledTab label="View" selected={tabIndex === 1} />
      </StyledTabs>

      {/* EditorComponent and HTML View */}
      <EditorBox>
        {tabIndex === 0 && (
          <JoditEditor
            ref={editor}
            value={content} // Set the value to the current content
            config={config}
            tabIndex={1}
            onBlur={(newContent) => setContent(newContent)} // Update content onBlur
            // onChange={(newContent) => setContent(newContent)} // Use onChange for real-time updates
          />
        )}

        {tabIndex === 1 && (
          <HtmlBox dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </EditorBox>
    </>
  );
};

export default EditorComponent;
