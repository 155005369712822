import * as actionTypes from '../actions/actionTypes';
import { url } from '../components/Constants/Constants';
import axios from 'axios';
import { useCallback } from 'react';
import { setGlobalMessage } from './dashboardActions';


export const extractErrorMessage = (response, defaultMessage = "Something went wrong") => {
    if (response && response.data && typeof response.data === 'object') {
        const errorData = response.data;

        const extractMessages = (obj, parentKey = "") => {
            if (Array.isArray(obj)) {
                return obj
                    .map(item => (typeof item === "object" ? extractMessages(item, parentKey) : item))
                    .filter(Boolean)
                    .join(", ");
            } else if (typeof obj === "object") {
                return Object.entries(obj)
                    .map(([key, messages]) => extractMessages(messages, parentKey || key))
                    .join("; ");
            }
            return `${obj}`;
        };

        const errorMessage = extractMessages(errorData);
        if (errorMessage) {
            return errorMessage;
        }
    }
    return defaultMessage;
};





export const setCreateSurvey = (arr) => {
    return{
        type: actionTypes.Set_create_survey,
        payload: arr
    }
}

export const getSurvey = (reqHeader,id) => {
    return async dispatch => {
        try{
            axios.get(`${url}/survey/${id}/`,{
                "headers": reqHeader
            })
            .then(resp => {
                console.log("survey response",resp.data)
                if(resp.data){
                    dispatch({
                        type: actionTypes.Set_create_survey,
                        payload : resp.data
                    })
                }else{
                    dispatch({
                        type: actionTypes.Set_create_survey,
                        payload : {}
                    })
                }
            })
            .catch(({ response = {} }) => {
                console.log("get survey err response",response)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
                // callback(false)
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${id}/`,e)
        }
    }
}

export const createNewSurvey = (reqHeader,details,callback) => {
    return async dispatch => {
        try{
            axios.post(`${url}/survey/`,{
                ...details,
                "sections": []
            },{
                "headers": reqHeader
            })
            .then(resp => {
                // console.log("survey response",resp.data)
                if(resp.data){
                    dispatch({
                        type: actionTypes.Set_create_survey,
                        payload : resp.data
                    })
                    callback(true,resp.data)
                }else{
                    dispatch({
                        type: actionTypes.Set_create_survey,
                        payload : {}
                    })
                }
            })
            .catch(({ response = {} }) => {
                console.log("get survey err response",response)
                callback(false, response)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
            })
        }catch(e){
            console.log(`error in api call to path = /survey/`,e)
            callback(false, e)
        }
    }
}

export const editSurvey = (reqHeader,surveyId,data,callback) => {
    return async dispatch => {
        try{
            axios.patch(`${url}/survey/${surveyId}/`,data,{
                "headers": reqHeader      
            })
            .then(resp => {
                callback(true)
            })
            .catch(({ response = {} }) => {
                console.log("edit survey err response",response)
                callback(false)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${surveyId}/`,e)
            callback(false)
        }
    }
}

export const deleteSurvey = (reqHeader,id,callback) => {
    return async dispatch => {
        try{
            axios.delete(`${url}/survey/${id}`,{
                "headers": reqHeader      
            })
            .then(resp => {
                callback(true)
            })
            .catch(({ response = {} }) => {
                console.log("delete survey err response",response)
                callback(false)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${id}`,e)
            callback(false)
        }
    }
}


export const createNewSection = (reqHeader,id,name,questions,callback) => {
    return async dispatch => {
        try{
            axios.post(`${url}/survey/${id}/sections/`,{
                "questions": questions,
                "name": name
            },
            {
                "headers": reqHeader,
            })
            .then(resp => {
                if(resp.data){
                    if(callback)
                    callback(true, resp.data)
                }
            })
            .catch(({ response = {} }) => {
                console.log("create section err response",response)
                if(callback)
                callback(false, response)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
                // callback(false)
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${id}/sections/`,e)
        }
    }
}


export const editSectionName = (reqHeader,surveyId,sectionId,name,callback) => {
    return async dispatch => {
        try{
            axios.patch(`${url}/survey/${surveyId}/sections/${sectionId}/`,{"name":name},{
                "headers": reqHeader      
            })
            .then(resp => {
                callback(true,resp.data)
            })
            .catch((resp={}) => {
                console.log("edit section err response",resp)
                callback(false)
               const errorMessage = extractErrorMessage(resp); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${surveyId}/sections/${sectionId}/`,e)
            callback(false)
        }
    }
}

export const deleteSection = (reqHeader,surveyId,sectionId,callback) => {
    return async dispatch => {
        try{
            axios.delete(`${url}/survey/${surveyId}/sections/${sectionId}/`,{
                "headers": reqHeader      
            })
            .then(resp => {
                callback(true)
            })
            .catch(({ response = {} }) => {
                console.log("delete section err response",response)
                callback(false)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${surveyId}/sections/${sectionId}/`,e)
            callback(false)
        }
    }
}



export const createNewQuestion = (reqHeader,surveyId,sectionId,question,callback) => {
    console.log(sectionId);
    return async dispatch => {
        try{
            axios.post(`${url}/survey/${surveyId}/sections/${sectionId}/questions/`,{
                ...question
            },
            {
                "headers": reqHeader,
            })
            .then(resp => {
                
                if(resp.data){
                    console.log("create question response",resp.data)
                    callback(true, resp.data)
                }
            })
            .catch(({ response = {} }) => {
                console.log("create question err response",response)
                callback(false, null)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
                // callback(false)
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${surveyId}/sections/${sectionId}/questions/`,e)
        }
    }
}

export const updateQuestion = (reqHeader,surveyId,sectionId,questionId,question,callback) => {
    return async dispatch => {
        try{
            axios.patch(`${url}/survey/${surveyId}/sections/${sectionId}/questions/${questionId}/`,{
                ...question
            },
            {
                "headers": reqHeader,
            })
            .then(resp => {
                
                if(resp.data){
                    // console.log("create question response",resp.data)
                    callback(true, resp.data)
                }
            })
            .catch(({ response = {} }) => {
                console.log("create question err response",response)
                callback(false, null)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
                // callback(false)
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${surveyId}/sections/${sectionId}/questions/`,e)
        }
    }
}


export const deleteQuestion = (reqHeader,surveyId,sectionId,id,callback) => {
    return async dispatch => {
        try{
            axios.delete(`${url}/survey/${surveyId}/sections/${sectionId}/questions/${id}/`,{
                "headers": reqHeader      
            })
            .then(resp => {
                console.log("resp",resp);
                
                callback(true)
            })
            .catch(({ response = {} }) => {
                console.log("question survey err response",response)
                callback(true)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${surveyId}/sections/${sectionId}/questions/${id}/`,e)
            callback(false)
        }
    }
}

export const getQuestion = (reqHeader,surveyId,sectionId,questionId,callback) => {
    return async dispatch => {
        try{
            axios.get(`${url}/survey/${surveyId}/sections/${sectionId}/questions/${questionId}/`,{
                "headers": reqHeader
            })
            .then(resp => {
                // console.log("Get question response",resp.data)
                if(resp.data){
                   callback(true,resp.data)
                }
            })
            .catch(({ response = {} }) => {
                console.log("get question err response",response);
                callback(false)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
                // callback(false)
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${surveyId}/sections/${sectionId}/questions/${questionId}/`,e)
        }
    }
}


export const createNewDependentQuestion = (reqHeader,surveyId,sectionId,questionId,optionId,question,callback) => {
    return async dispatch => {
        try{
            axios.post(`${url}/survey/${surveyId}/sections/${sectionId}/questions/${questionId}/conditional-questions/`,{
                "option": optionId,
                "question": question
            },
            {
                "headers": reqHeader,
            })
            .then(resp => {
                
                if(resp.data){
                    console.log("create conditional question response",resp.data)
                    callback(true)
                }
            })
            .catch(({ response = {} }) => {
                console.log("create conditional question err response",response)
                callback(false)
               const errorMessage = extractErrorMessage(response); 
                 dispatch(setGlobalMessage({
        mess: errorMessage,
        type: 'error'
    }));
                // callback(false)
            })
        }catch(e){
            console.log(`error in api call to path = /survey/${surveyId}/sections/${sectionId}/questions/${questionId}/conditional-questions/`,e)
        }
    }
}

// Save survey response
export const saveSectionResponses = (reqHeader, survey_token, data, callback,camp_code=null) => {
    return async dispatch => {
        try{
            console.log("called from saaveSection");
            
            axios.post(`${url}/survey/response/${survey_token}/sections/${camp_code?"?camp_code="+camp_code:""}`,
            {...data},
            {
                "headers": reqHeader      
            })
            .then(resp => {
                callback(true,resp.data)
                console.log("called from saaveSection thaen");
            })
            .catch(({ response = {} }) => {
                console.log("saveSectionResponses err response",response)
                callback(false)
               const errorMessage = extractErrorMessage(response); 
               console.log("saveSectionResponses err response",errorMessage)
                 dispatch(setGlobalMessage({
                mess: errorMessage,
                type: 'error'
                }));
            })
        }catch(e){
            console.log(`error in api call to path = /survey/response/${survey_token}/sections/`,e)
            callback(false)
        }
    }
}