import { setGlobalMessage } from "../../../../actions/dashboardActions";
import { fetchUserDetail, fetchUserDetailSuccess } from "../../../../actions/loginActions";
import { updateNewUserNotificationFromAPI } from "../../../../apps/Chat/actions/chatActions";
import { check_auth_error } from "../../../../components/Utility/Utility";
import axiosClient from "../../../../httpClient";
import * as actionTypes from "./actionTypes";

export function setSelectedSideNav(navObj) {
  return { type: actionTypes.SET_SELCTED_SIDENAV, payload: navObj };
}

export function fetchSideNavLinks() {
  return function (dispatch, getState) {
    const {
      filter: { selectedHotel },
    } = getState();

    if(Object.keys(selectedHotel).length==0)
      return

    dispatch({ type: actionTypes.FETCH_SIDENAV_REQUEST });
    axiosClient
      .get(`/services/side-nav-menue-screens/?hotel=${selectedHotel.hotel_code}`)
      .then((resp) => {
      let chatNav = resp.data.features.find(e=>e.name==="Chats")
      if(chatNav)
      dispatch(updateNewUserNotificationFromAPI(chatNav.notif_count || 0))
        dispatch({
          type: actionTypes.FETCH_SIDENAV_SUCCESS,
          payload: resp.data.features,
        });
      })
      .catch(({ response = {} }) => {
        check_auth_error(response);
        console.log("sidenav err response", response);
        dispatch({
          type: actionTypes.FETCH_SIDENAV_SUCCESS,
          payload:[],
        });
      });
  };
}

// fetch active staff members
export const fetchActiveStaffMembers = (page_id) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      auth:{token}
    } = getState();
    if(!token)
      return

    dispatch({ type: actionTypes.FETCH_ACTIVE_STAFF_REQUEST });
    axiosClient
      .post(`/services/page-visit/?hotel=${selectedHotel.hotel_code}`, {
        id: page_id,
      })
      .then((resp) => {
        const data = { id: page_id, data: resp.data };
        dispatch({
          type: actionTypes.FETCH_ACTIVE_STAFF_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        //check_auth_error(error);
        console.log("sidenav err response", error);
      });
  };
};

export const updateActiveStaffList = (staff, add_or_remove, page_id) => {
  return {
    type: actionTypes.UPDATE_ACTIVE_STAFF,
    payload: { staff, add: add_or_remove, id: page_id },
  };
};

export const appendNewNotification = (data) => {
  return {
    type: actionTypes.APPEND_NEW_NOTIFICATION,
    payload: data,
  };
};

export const updateNotificationData = (data) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_DATA,
    payload: data,
  };
};

export const ChangePassword = (body, closeModel, setLoading,setBody,initialState,passwordResetRequired=false) => {
  return (dispatch, getState) => {
    axiosClient
      .post(
        `/accounts/password/change/`,
        body
      )
      .then((res) => {
        if(passwordResetRequired)
          dispatch(fetchUserDetail({
            loginFlow:false
          }))
        closeModel();
        dispatch(setGlobalMessage({
          mess: "Password successfully changed",
          type: 'success'
        }))
        setBody(initialState)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error.response);
        //check_auth_error(error);
        const errorMsg = error.message;
        if(error?.response?.data && Object.keys(error?.response?.data).length>0){
          Object.values(error?.response?.data).forEach((e)=>{
            if(e[0])
            dispatch(setGlobalMessage({
              mess: e[0],
              type: 'error'
            }))
          })
        }else
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        setLoading(false)
      });

  };
};

export const fetchNotification = () => {
  return (dispatch, getState) => {
    const {
      auth: { token }
    } = getState(); 
    if(!token)
      return
    axiosClient
      .get(`/messages/notifications/`)
      .then((res) => {
        // console.log(res)
       dispatch( {
        type: actionTypes.SET_NOTIFICATION,
        payload:res.data,
      })
      })
      .catch((error) => {
        //check_auth_error(error);
        if(error?.response?.data && Object.keys(error?.response?.data).length>0){
          Object.values(error?.response?.data).forEach((e)=>{
            if(e[0])
            dispatch(setGlobalMessage({
              mess: e[0],
              type: 'error'
            }))
          })
        }else
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};


export const fetchNotificationStat = () => {
  return (dispatch, getState) => {
    const {
      auth: { token }
    } = getState(); 
    if(!token)
      return
    axiosClient
      .get(`/messages/notification-stats/`)
      .then((res) => {
        // console.log(res)
       dispatch( {
        type: actionTypes.SET_NOTIFICATION_UNREAD_COUNT,
        payload:res.data,
      })
      })
      .catch((error) => {
        //check_auth_error(error);
        if(error?.response?.data && Object.keys(error?.response?.data).length>0){
          dispatch(setGlobalMessage({
            mess: error?.response?.data?.detail,
            type: 'error'
          }))
        }else
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};

export const updateNotification = (id) => {
  return (dispatch, getState) => {
    const {
      auth: { token },
      nav:{ notificationData,}
    } = getState(); 
    if(!token)
      return
    axiosClient
      .patch(`/messages/notifications/${id}/`,{read:true})
      .then((res) => {
      //   let data  = {
      //     ...notificationCountData,
      //     unread_count:notificationCountData.unread_count - 1
      //   }
      //  dispatch( {
      //   type: actionTypes.SET_NOTIFICATION_UNREAD_COUNT,
      //   payload:data,
      // })
      let data = []
      if(notificationData.results && notificationData.results.length>0)
        {
            data = notificationData.results.map((e)=>{
              if(e.id === res.data?.id)
                return res.data
              else 
              return e
            })
            dispatch(updateNotificationData(data))
        }

      })
      .catch((error) => {
        //check_auth_error(error);
        if(error?.response?.data && Object.keys(error?.response?.data).length>0){
          Object.values(error?.response?.data).forEach((e)=>{
            if(e[0])
            dispatch(setGlobalMessage({
              mess: e[0],
              type: 'error'
            }))
          })
        }else
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};


export const updateUserData = (body={},setLoading,closeModel) => {
  return (dispatch, getState) => { 
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .patch(`/accounts/user/?hotel=${selectedHotel.hotel_code}`,body)
      .then((res) => {
        dispatch(fetchUserDetailSuccess(res.data))
if(closeModel)closeModel()
  dispatch(setGlobalMessage({
    mess: "Your preferences successfully saved",
    type: 'success'
  }))
      })
      .catch((error) => {
        //check_auth_error(error);
        dispatch(setGlobalMessage({
          mess: "Something went wrong",
          type: 'error'
        }))
      })
      .finally(()=>{if(setLoading)setLoading(false)})

  };
};
