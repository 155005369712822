import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import HeaderBox from './components/HeaderBox'; // Assuming you already have this
import TemplateDropdown from './components/TemplateDropdown'; // Assuming you already have this
import EditorComponent from './components/EditorComponent';

const Editor = () => {
  const [content, setContent] = useState(''); // State to store editor content
  const [tabIndex, setTabIndex] = useState(0); // State for active tab

  // Handle template selection
  const handleTemplateSelect = (templateUrl) => {
    fetchHtmlFile(templateUrl); // Fetch and set the template content
  };

  // Function to fetch HTML file and set it in the editor
  const fetchHtmlFile = async (URL) => {
    try {
      const response = await fetch(URL); // Fetch the HTML file from public directory
      if (!response.ok) {
        throw new Error(`Could not load ${URL}`);
      }
      const fileContent = await response.text();
      setContent(fileContent); // Set the editor content
    } catch (error) {
      console.error('Error fetching HTML file:', error);
      setContent('<p>Error loading file. Please check the file name.</p>');
    }
  };

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue); // Switch between "Editor" and "View"
  };

  // Handle file upload
  const handleUploadHtml = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (file && file.type === 'text/html') {
      const reader = new FileReader();
      reader.onload = () => {
        setContent(reader.result); // Set the file content in the editor
      };
      reader.onerror = () => {
        console.error('Error reading the file.');
        setContent('<p>Error loading file. Please try again.</p>');
      };
      reader.readAsText(file); // Read the file as text
    } else {
      alert('Please upload a valid HTML file.');
    }
  };

  // Handle file download
  const handleDownloadHtml = () => {
    const blob = new Blob([content], { type: 'text/html' }); // Create a Blob with the content
    const link = document.createElement('a'); // Create an anchor tag
    link.href = URL.createObjectURL(blob); // Create a URL for the Blob
    link.download = 'editor_content.html'; // Set the default file name
    link.click(); // Simulate a click to trigger the download
  };

  // Effect to log content changes for debugging
  useEffect(() => {
    // console.log('Content updated:', content);
    console.log('Content updated:');
  }, [content]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#f9f9f9',
      }}
    >
      {/* Header Area */}
      <HeaderBox
        handleUploadHtml={handleUploadHtml}
        handleDownloadHtml={handleDownloadHtml}
        showUploadButton={true}
      >
        <Typography variant="h4">HTML Editor</Typography>
        <Typography variant="body2">Edit your HTML files</Typography>
      </HeaderBox>

      {/* Template Dropdown */}
      <TemplateDropdown onTemplateSelect={handleTemplateSelect} />

      {/* Reusable Editor */}
      <EditorComponent
        content={content}
        setContent={setContent}
        tabIndex={tabIndex}
        handleTabChange={handleTabChange}
        placeholder="Start typing or load a template..."
      />
    </Box>
  );
};

export default Editor;
