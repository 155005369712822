import React, { useEffect, useState } from "react";
import { Snackbar, Alert, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setGlobalMessage } from "../../../actions/dashboardActions";
import { EditGuestReqSuccess } from "../../../apps/Chat/actions/chatActions";
import SystemAlert from "../PopUp/SystemAlert";

const position_for_sys_alert = { vertical: "bottom", horizontal: "left" }

const CustomAlert = ({variant, type, message,position={ vertical: "top", horizontal: "center"},style={},autoclose=type!=="system_notification" }) => {
  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
const dispatch = useDispatch()


useEffect(() => {
  
  setMounted(true);
  return () => setMounted(false);
}, []);
  useEffect(() => {
    if (
      message &&
      message.length > 0 &&
      message != "--" &&
      message != "No chat messages found"
    )
      setOpen(true);
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    if(variant !== "human-intereaction")
    dispatch(setGlobalMessage({
      mess:"",
      type:"",
      data:{}
    }))
    else
   dispatch(EditGuestReqSuccess(""))
  };

  if (!mounted) return null;  

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoclose?5000:null}
      anchorOrigin={ type === "system_notification"?position_for_sys_alert : position}
      onClose={handleClose}
    >
      <Box>
 {
      type === "system_notification" ? <SystemAlert onClose={handleClose} message={message}/> :
  message ?     <Alert onClose={handleClose} severity={type}  variant="filled" sx={style}>
  {message}
</Alert> : null
 }
         </Box>

    </Snackbar>
  );
};

export default CustomAlert;
