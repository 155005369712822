import * as actionTypes from "./actions/settingActionTypes";

const initialState = {
  sources: {
    data: [],
    selectedSource: {},
    loading: false,
    connecting: "",
    error: "",
    redirection_uri: "",
    properties: {},
    staff_list: { count: 0, page: 0, data: [], loading: false },
    successMessage: "",
  },
  CampaignData: {
    data: [],
    loading: false,
  },
  TransactionnData: {
    emailData: {
      data: [],
      count: 0,
      // page:0,
      next: null,
      previous: null,
      loading: false,
    },
    smsData: {
      data: [],
      count: 0,
      // page:0,
      next: null,
      previous: null,
      loading: false,
    },
  },
  UrlData: {
    data: [],
    count: 0,
    // page:0,
    next: null,
    previous: null,
    loading: false,
  },
  PdfData: {
    data: [],
    count: 0,
    // page:0,
    next: null,
    previous: null,
    loading: false,
  },
  PlaceAndEvent: {
    PLACE: {
      data: [],
      count: 0,
      next: null,
      previous: null,
      loading: false,
      page: 0,
    },
    EVENT: {
      data: [],
      count: 0,
      next: null,
      previous: null,
      loading: false,
      page: 0,
    },
    EVENT_VENUE: {
      data: [],
      count: 0,
      next: null,
      previous: null,
      loading: false,
      page: 0,
    },
    Curated_List: {
      data: [],
      count: 0,
      next: null,
      previous: null,
      loading: false,
      page: 0,
    },

    Curated_List_Options: {
      data: [],
      count: 0,
      next: null,
      previous: null,
      loading: false,
      page: 0,
    },
  },
  MessageTemplate: {
    data: [],
    count: 0,
    next: null,
    previous: null,
    loading: false,
    page: 0,
  },
  // added the email template table reducer
  EmailTemplate: {
    data: [],
    count: 0,
    next: null,
    previous: null,
    loading: false,
    page: 0,
  },
  Text: {
    data: [],
    count: 0,
    next: null,
    previous: null,
    loading: false,
    page: 0,
  },

  CustomFilter: {
    data: [],
    count: 0,
    next: null,
    previous: null,
    loading: false,
    page: 0,
    error: "",
  },
  Department: {
    data: [],
    count: 0,
    next: null,
    previous: null,
    loading: false,
    page: 0,
    error: "",
  },
  GuestReqType: {
    data: [],
    count: 0,
    next: null,
    previous: null,
    loading: false,
    page: 0,
    error: "",
  },
};

const filterSources = (sources, newSource, add_if_not_present = true) => {
  var _old_source_indx = sources.findIndex((_s) => _s.id == newSource.id);

  if (_old_source_indx == -1)
    return add_if_not_present ? [...sources, newSource] : [...sources];

  var _filtered_sources = sources.filter((_s) => _s.id != newSource.id);
  _filtered_sources.splice(_old_source_indx, 0, newSource);

  return _filtered_sources;
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SOURCES_REQUEST:
      return {
        ...state,
        sources: { ...state.sources, loading: true },
      };

    case actionTypes.FETCH_SOURCES_ERROR:
      return {
        ...state,
        sources: {
          ...state.sources,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.FETCH_SOURCES_SUCCESS:
      return {
        ...state,
        sources: {
          ...state.sources,
          loading: false,
          error: "",
          data: action.payload,
        },
      };

    case actionTypes.SET_SELECTED_SOURCE:
      return {
        ...state,
        sources: {
          ...state.sources,
          selectedSource: action.payload,
        },
      };

    case actionTypes.UPDATE_SOURCE:
      const filteredSources = filterSources(state.sources.data, action.payload);
      return {
        ...state,
        sources: {
          ...state.sources,
          data: filteredSources,
        },
      };

    case actionTypes.CONNECTING_SOURCE_REQUEST:
      return {
        ...state,
        sources: {
          ...state.sources,
          connecting: action.payload,
        },
      };

    case actionTypes.CONNECTING_SOURCE_SUCCESS:
      return {
        ...state,
        sources: {
          ...state.sources,
          connecting: "",
          error: "",
          properties: action.payload,
        },
      };

    case actionTypes.CONNECTING_SOURCE_ERROR:
      return {
        ...state,
        sources: {
          ...state.sources,
          connecting: "",
          error: action.payload,
          selectedSource: {},
          redirection_uri: "",
        },
      };
    case actionTypes.SET_CONNECTING_TO_INITIAL:
      return {
        ...state,
        sources: {
          ...state.sources,
          connecting: "",
        },
      };

    case actionTypes.SET_PAGE_OR_LOC_SUCCESS:
      return {
        ...state,
        sources: {
          ...state.sources,
          selectedSource: {},
          properties: {},
          error: "",
        },
      };

    case actionTypes.SET_PAGE_OR_LOC_ERROR:
      return {
        ...state,
        sources: {
          ...state.sources,
          selectedSource: {},
          properties: {},
          error: action.payload,
        },
      };

    case actionTypes.FETCH_REDIRECTION_URI_SUCCESS:
      return {
        ...state,
        sources: {
          ...state.sources,
          error: "",
          redirection_uri: action.payload,
        },
      };

    case actionTypes.FETCH_STAFF_LIST_SUCCES: {
      return {
        ...state,
        sources: {
          ...state.sources,
          staff_list: {
            count: action.payload.data.length,
            page: action.payload.page,
            data: action.payload.data,
            loading: false,
          },
        },
      };
    }

    case actionTypes.SET_STAFF_LOADING_FALSE: {
      return {
        ...state,
        sources: {
          ...state.sources,
          staff_list: {
            ...state.sources.staff_list,
            loading: false,
          },
        },
      };
    }

    case actionTypes.FETCH_REDIRECTION_URI_ERROR:
      return {
        ...state,
        sources: {
          ...state.sources,
          connecting: "",
          error: action.payload,
          selectedSource: {},
        },
      };

    case actionTypes.Add_NEW_STAFF_Req_Success:
      return {
        ...state,
        sources: {
          ...state.sources,
          successMessage: action.payload,
        },
      };
    case actionTypes.Set_Campaign_List:
      return {
        ...state,
        CampaignData: {
          ...state.CampaignData,
          data: action.payload,
          loading: false,
        },
      };
    case actionTypes.FETCH_EMAIL_TRANSACTION_LIST_SUCCES:
      return {
        ...state,
        TransactionnData: {
          ...state.TransactionnData,
          emailData: {
            // ...state.TransactionnData.emailData,
            data: action.payload.results,
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
            loading: false,
          },
        },
      };
    case actionTypes.SET_EMAIL_LOADING_FALSE:
      return {
        ...state,
        TransactionnData: {
          ...state.TransactionnData,
          emailData: {
            ...state.TransactionnData.emailData,
            loading: false,
          },
        },
      };
    case actionTypes.FETCH_SMS_TRANSACTION_LIST_SUCCES:
      return {
        ...state,
        TransactionnData: {
          ...state.TransactionnData,
          smsData: {
            data: action.payload.results,
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
            loading: false,
          },
        },
      };

    case actionTypes.SET_SMS_LOADING_FALSE:
      return {
        ...state,
        TransactionnData: {
          ...state.TransactionnData,
          smsData: {
            ...state.TransactionnData.smsData,
            loading: false,
          },
        },
      };

    case actionTypes.FETCH_Scrap_Url_LIST_SUCCES:
      return {
        ...state,
        UrlData: {
          data: action?.payload || [],
          count: action?.count || action.payload.length,
          next: action?.next || null,
          previous: action?.previous || null,
          loading: false,
        },
      };

    case actionTypes.FETCH_MESSAGE_TEMPLETE_LIST_SUCCES:
      return {
        ...state,
        MessageTemplate: {
          data: action?.payload || [],
          count: action.payload.length,
          next: action?.next || null,
          previous: action?.previous || null,
          loading: false,
        },
      };


      // adding fetch email template list success 
      case actionTypes.FETCH_EMAIL_TEMPLATE_LIST_SUCCESS:
        return {
          ...state,
          EmailTemplate: {
            data: action?.payload || [],
            count: action.payload.length,
            next: action?.next || null,
            previous: action?.previous || null,
            loading: false,
          },
        };


    case actionTypes.FETCH_TEXT_DATA_SUCCESS:
      return {
        ...state,
        Text: {
          data: action?.payload || [],
          count: action.payload.length,
          next: action?.next || null,
          previous: action?.previous || null,
          loading: false,
        },
      };


      

    case actionTypes.FETCH_CUSTOM_FILTER_DATA_SUCCESS:
      return {
        ...state,
        CustomFilter: {
          data: action?.payload || [],
          count: action.payload.length,
          next: action?.next || null,
          previous: action?.previous || null,
          loading: false,
          error: "",
        },
      };
    case actionTypes.FETCH_CUSTOM_FILTER_DATA_FAILURE:
      return {
        ...state,
        CustomFilter: {
          data: [],
          count: 0,
          next: null,
          previous: null,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.FETCH_DEPARATMENT_DATA_SUCCESS:
      return {
        ...state,
        Department: {
          data: action?.payload || [],
          count: action.payload.length,
          next: action?.next || null,
          previous: action?.previous || null,
          loading: false,
          error: "",
        },
      };
    case actionTypes.FETCH_DEPARATMENT_DATA_FAILURE:
      return {
        ...state,
        Department: {
          data: [],
          count: 0,
          next: null,
          previous: null,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.FETCH_GUESTREQTYPE_DATA_SUCCESS:
      return {
        ...state,
        GuestReqType: {
          data: action?.payload || [],
          count: action.payload.length,
          next: action?.next || null,
          previous: action?.previous || null,
          loading: false,
          error: "",
        },
      };
    case actionTypes.FETCH_GUESTREQTYPE_DATA_FAILURE:
      return {
        ...state,
        GuestReqType: {
          data: [],
          count: 0,
          next: null,
          previous: null,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.FETCH_PDF_LIST_SUCCES:
      return {
        ...state,
        PdfData: {
          data: action?.payload || [],
          count: action?.count || action.payload.length,
          next: action?.next || null,
          previous: action?.previous || null,
          loading: false,
        },
      };
    case actionTypes.FETCH_REQ_FOR_SETTINGS_TALBLES: {
      switch (action.payload) {
        case "Campaign":
          return {
            ...state,
            CampaignData: {
              ...state.CampaignData,
              loading: true,
            },
          };
        case "Email":
          return {
            ...state,
            TransactionnData: {
              ...state.TransactionnData,
              emailData: {
                ...state.TransactionnData.emailData,
                loading: true,
              },
            },
          };
        case "Sms":
          return {
            ...state,
            TransactionnData: {
              ...state.TransactionnData,
              smsData: {
                ...state.TransactionnData.smsData,
                loading: true,
              },
            },
          };
        case "Url":
          return {
            ...state,
            UrlData: {
              ...state.UrlData,
              loading: true,
            },
          };
        case "Pdf":
          return {
            ...state,
            PdfData: {
              ...state.PdfData,
              loading: true,
            },
          };
        case "Staff":
          return {
            ...state,
            sources: {
              ...state.sources,
              staff_list: {
                ...state.sources.staff_list,
                loading: true,
              },
            },
          };
        case "MessageTemplate":
          return {
            ...state,
            MessageTemplate: {
              ...state.MessageTemplate,
              loading: true,
            },
          };

        // added the email template table reducer
        case "EmailTemplate":
          return {
            ...state,
            EmailTemplate: {
              ...state.EmailTemplate,
              loading: true,
            },
          };


        case "Text":
          return {
            ...state,
            Text: {
              ...state.Text,
              loading: true,
            },
          };

        case "CustomFilter":
          return {
            ...state,
            CustomFilter: {
              ...state.CustomFilter,
              loading: true,
            },
          };
        case "Department":
          return {
            ...state,
            Department: {
              ...state.Department,
              loading: true,
            },
          };
        case "GuestReqType":
          return {
            ...state,
            GuestReqType: {
              ...state.GuestReqType,
              loading: true,
            },
          };
      }
    }
    case actionTypes.SET_PLACE_EVENT_LOADING:
      return {
        ...state,
        PlaceAndEvent: {
          ...state.PlaceAndEvent,
          [action.key]: {
            ...state.PlaceAndEvent[action.key],
            loading: action.payload,
          },
        },
      };
    case actionTypes.SET_PLACE_EVENT_DATA:
      return {
        ...state,
        PlaceAndEvent: {
          ...state.PlaceAndEvent,
          data: action.payload.results,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          page: action.payload.page,
        },
      };

    case actionTypes.SET_TRIP_PLANNER_DATA:
      return {
        ...state,
        PlaceAndEvent: {
          ...state.PlaceAndEvent,
          ...action.payload,
        },
      };

    case actionTypes.SET_TO_INITIAL:
      return initialState;

    default:
      return state;
  }
};

export default SettingsReducer;
