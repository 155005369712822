import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import BasePage from "../../../commons/components/BasePage/BasePage";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { changeLoadingStatePlaceAndEvent } from "../../../commons/components/Settings/actions/settingsActions";
import { fetchFilters } from "../../../commons/components/Filter/actions/actions";
import { AddUpdateAndDeleteCuratedList, fetchCuratedList } from "../action/TripPlannerAction";
import { formatDate } from "../../../commons/utility";
import { colorWithType } from "../../../components/Utility/Constants";
import CommonSingleRow from "../../../commons/components/Settings/components/CommonSingleRow";
import CommonTableUI from "../../../commons/components/Settings/components/CommonTableUI";
import DialogBox from "../../../commons/components/PopUp/DialogBox";

const filterRoute = `/trip-planner`;

const columns = [
    { name: "Created",orderBy:"created_at"},
    { name: "Name",orderBy:"name"},
    { name: "Amount of listings",orderBy:"amount_of_listing"},
    { name: "Status",orderBy:"status"},
    {name:"Action"}
  ];

const CuratedList = () => {
  const [initialRenderFlag, setinitialRenderFlag] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);
  const APITrigger = useRef(null);
  const operationData = useRef({})
  const TableContainerRef = useRef(null);
  const ComponentDataRef = useRef({});
  const selectedFilters = useSelector(
    (state) => state.filter.selectedFilters[`${filterRoute}`] || []
  );

  const location = useLocation();

  
  const navObj = useSelector((state) => state.nav.selectedNav || {});
  const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );
  const DataSelector = (state) =>
    state.settings?.PlaceAndEvent?.Curated_List?.data || [];
  const Count = useSelector(
    (state) => state.settings?.PlaceAndEvent?.Curated_List?.count || 0
  );
//   const page = useSelector(
//     (state) => state.settings?.PlaceAndEvent?.Curated_List?.page || 0
//   );
const minRow = 10;
const page = Math.ceil(Count / minRow);
  const LoadingSelector = (state) =>
    state.settings?.PlaceAndEvent?.Curated_List?.loading;
  const ErrorSelector = (state) =>
    state.settings?.PlaceAndEvent?.Curated_List?.error;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(selectedHotel).length > 0) {
      dispatch(
        fetchFilters(
          `/data-aggregator/places-events/dashboard-filters/?hotel=${selectedHotel.hotel_code}`,
          "trip_planner",
          {
            isHotelChange: initialRenderFlag,
            key: `${filterRoute}`,
          }
        )
      );
      if (!initialRenderFlag) setinitialRenderFlag(true);
    }
  }, [selectedHotel]);

  useEffect(() => {
    if (APITrigger.current) clearTimeout(APITrigger.current);
    if (Object.keys(selectedHotel).length > 0 && selectedFilters.length > 0) {
      APITrigger.current = setTimeout(() => {
        dispatch(changeLoadingStatePlaceAndEvent(true, "Curated_List"));
        dispatch(
          fetchCuratedList(0, changeLoadingStatePlaceAndEvent, false, null, {
            filterkey: `${filterRoute}`,
            entityType: "Curated_List",
          })
        );
      }, 0);
    }
  }, [selectedFilters]);

  const fetchData = useCallback((newPage) => {
    dispatch(changeLoadingStatePlaceAndEvent(true, "Curated_List"));
    dispatch(
      fetchCuratedList(newPage, changeLoadingStatePlaceAndEvent, false, null, {
        filterkey: `${filterRoute}`,
        entityType: "Curated_List",
      })
    );
  }, [dispatch]);


  
  const closeConfirmModal = () => {
    setConfirmModal(null);
  };

  // const confirmChange = () => {
  //   dispatch(updateGuestRequest(confirmModal.id, confirmModal.body));
  //   closeConfirmModal();
  // };

  const handleEdit = useCallback((data) => {
    history.push(`/trip-planner/curated-list/manage?id=${data.id}`);
  }, [history]);

  const hadleDelete = useCallback(() => {
   const data = operationData.current
    dispatch(AddUpdateAndDeleteCuratedList({id:data.id},false,false,'delete')).then((d)=>{
      operationData.current={}    
    })
    closeConfirmModal();
  }, [dispatch,operationData]);
  const menuItems = [
    { label: "Edit", onClick: handleEdit },
    { label: "Delete", onClick: (data)=>{
      operationData.current = data
      setConfirmModal(true)
    } },
  ];

  const RowComponent = ({row={},skipRowIndices=[]}) => {
    let payload = {
      created_at:row.created_at?formatDate(row.created_at):"",
      name:row.name,
      amount_of_listings:   row?.event_or_place_list?.length || "" ,
      status: row.status ? <Box sx={{
        display:'inline-block',
        borderRadius:"16px",
        background:colorWithType[row.status]?.bg || colorWithType["new"]?.bg,
        color:colorWithType[row.status]?.tx || colorWithType["new"]?.tx,
        padding:"4px 8px"
       }}>
    <Typography
    sx={{
      fontSize:"12px",
      lineHeight:"18px",
      letterSpacing:"0.4px"
    }}
    >
      {row.status}
    </Typography>
       </Box>:"",
  }

  const css = { whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize'}
  return(
    <>
<CommonSingleRow
  key={`${row?.id}`}
  payload={ payload}
  showMenu={true}
  menuItems={menuItems}
  id={row.id}
  fullData = {row}
  commonCss={css}
  skipRowIndices={skipRowIndices}
/>
      </>
    );
  };



  return (
    <BasePage title={navObj.name} showBox={true} show_side_filter={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          height: "100%",
          width: "100%",
          padding: "8px 0px 8px 0px",
          flexGrow: 1,
          marginBottom:"80px"
        }}
      >
        <Box
          gap={1}
          display={"flex"}
          flexDirection={"column"}
          position={"relative"}
        >
          <Typography
            variant="h3"
            component={"div"}
            sx={{
              fontWeight: "fontWeightBold",
              color: "#232531",
              fontSize: 32,
              letterSpacing: "-0.80px",
              lineHeight: "32px",
            }}
          >
            Curated lists
          </Typography>
          <Typography variant="body2" color={"textSecondary"}>
            Create curated lists
          </Typography>

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              // translate:"0% -50%"
            }}
            display="flex"
            gap={1}
            justifyContent="flex-end"
            marginTop={2}
          >
            <Button
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/trip-planner/curated-list/manage`);
              }}
              variant="text"
              color="primary"
              sx={{
                height: "40px",
                padding: "0px 16px",
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: "medium",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.44px",
                backgroundColor: "#007bff",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}
            >
              Add a list
            </Button>
          </Box>
        </Box>
        <Divider
          sx={{
            padding: "8px 0px",
          }}
        />
           <CommonTableUI
                      columns={columns}
                      fetchData={fetchData}
                      dataSelector={DataSelector}
                      loadingSelector={LoadingSelector}
                      errorSelector={ErrorSelector}
                      isEditPermission={true}
                      RowComponent = {RowComponent}
                      minRow={minRow}
                      count={Count}
                      page={page}
                      initialFetch={false}
                      title={"Curated List"}
                      heading={false}
                      maxHeight={"1096px"}
                      columnCtrl={true}
                      refreshButton={true}
                      ordering={false}
                      TableContainerRef={TableContainerRef}
                      ComponentDataRef={ComponentDataRef}
                      serverSidePagination={true}
                      />
      </Box>
      <DialogBox open={confirmModal} setOpen={closeConfirmModal} handleAgree={hadleDelete} agreeButtonText={"Yes"} content={"Are you sure you want to delete? This process cannot be undone"} topHeading={"Confirm"} closeText={"No"}/>

    </BasePage>
  );
};

export default CuratedList;
