import { setCommonTableLoading, setGlobalMessage } from "../../../actions/dashboardActions";
import { addFilterKey, ensureHttps } from "../../../commons/utility";
import { check_auth_error, convert_dates_to_query_params } from "../../../components/Utility/Utility";
import axiosClient from "../../../httpClient";
import * as chatActiontypes from "./chatActionTypes";

export const fetchUsersRequest = () => {
  return {
    type: chatActiontypes.FETCH_USERS_REQUEST,
  };
};

export const setFetchGuest = (val) => {
  // console.log(val,"dddddddddddddddddddddddd");
  
  return {
    type: chatActiontypes.FETCH_GUEST_FLAG,
    payload:val
  };
};

export const fetchUsersSuccess = (users,opr = false) => {
if(opr)
  return {
    type: chatActiontypes.FETCH_USERS_SUCCESS_APPEND,
    payload:  {users}
  };
  else
  return {
    type: chatActiontypes.FETCH_USERS_SUCCESS,
    payload: {users}
  };
};

export const fetchUsersfailure = (error) => {
  return {
    type: chatActiontypes.FETCH_USERS_FAILURE,
    payload: error,
  };
};

export const fetchBroadCastFailure = (error) => {
  return {
    type: chatActiontypes.FETCH_BROADCAST_FAILURE,
    payload: error,
  };
};

export const setSelectedUser = (user) => {
  return {
    type: chatActiontypes.SET_SELECTED_CHAT_USER,
    payload: user,
  };
};

export const unsetSelectedUser = (user) => {
  return {
    type: chatActiontypes.UNSET_SELECTED_CHAT_USER,
    payload: user,
  };
};

export const setSelectedBroadCast = (data) => {
  return {
    type: chatActiontypes.SET_SELECTED_CHAT_BROADCAST,
    payload: data,
  };
};

export const unSelectedBroadCast = (data) => {
  return {
    type: chatActiontypes.UNSET_SELECTED_CHAT_BROADCAST,
    payload: data,
  };
};

export const updateChatUser = (user) => {
  return {
    type: chatActiontypes.UPDATE_CHAT_USER,
    payload: user,
  };
};

export const updateChatUserProp = (user_id, add_props = {}, del_props = {}) => {
  return {
    type: chatActiontypes.UPDATE_CHAT_USER_PROP,
    payload: { user_id: user_id, add: add_props, delete: del_props },
  };
};

export const updateOrAddChatUserAndReshuffle = (update_type, user) => {
  return {
    type: chatActiontypes.UPDATE_OR_ADD_CHAT_USER_AND_RESHUFFLE,
    payload: { type: update_type, user: user },
  };
};

export const fetchUserMessageSuccess = (payload,type="guest") => {
  //payload = {messages: msgs, AppendToMsgs: true|false}
if(type=="guest"){
  return {
    type: chatActiontypes.FETCH_USER_MESSAGES_SUCCESS,
    payload: payload,
  };
}
else if(type="broadcast"){
  return {
    type: chatActiontypes.FETCH_BROADCAST_MESSAGES_SUCCESS,
    payload: payload,
  };
}
};

export const prependToMessages = (payload,chatType="guest") => {
  //payload = {user_id: user_id, messages: msgs}
  return {
    type: chatActiontypes.PREPEND_USER_MESSAGES,
    payload: payload,
    chatType
  };
};

export const updateSuggestedReply = (payload) => {
  return {
    type: chatActiontypes.UPDATE_SUGGESTED_REPLY,
    payload: payload,
  };
};

// export const addToSelectedUserMessages = (msg) => {
//   return {
//     type: chatActiontypes.ADD_TO_USER_MESSAGES,
//     payload: msg,
//   };
// };

export const addToUserMessages = (payload) => {
  //payload = {user_id: <id of user>, message: msg}
  return {
    type: chatActiontypes.ADD_TO_USER_MESSAGES,
    payload: payload,
  };
};

export const addToGroupMessages = (payload) => {
  return {
    type: chatActiontypes.ADD_TO_GROUP_MESSAGES,
    payload: payload,
  };
};

export const updateUserMessage = (msg_obj, add = false,chatType="guest") => {
if(chatType == "broadcast"){
  return {
    type: chatActiontypes.UPDATE_GROUP_MESSAGE,
    payload: { message: msg_obj, add },
  };
}
else{
  return {
    type: chatActiontypes.UPDATE_USER_MESSAGE,
    payload: { message: msg_obj, add },
  };
}
};

// export const addUnsentMessage = (messageId, message, user_id) => {
//   return {
//     type: chatActiontypes.ADD_UNSENT_MESSAGE,
//     payload: { unsentMessageId: messageId, updatedMessage: message, user_id },
//   };
// };

export const updateNewUserNotification = () => {
  return {
    type: chatActiontypes.UPDATE_NEW_USER_NOTIFICATION,
  };
};

export const updateNewUserNotificationFromAPI = (count) => {
  return {
    type: chatActiontypes.SET_NEW_NOTI_USER_FROM_API,
    payload:count
  };
};

export const fetchChannelsSuccess = (channels) => {
  return {
    type: chatActiontypes.FETCH_CHANNELS_SUCCESS,
    payload: channels,
  };
};

export const fetchGroupChannelSuccess = (channels) => {
  return {
    type: chatActiontypes.FETCH_GROUP_CHANNELS_SUCCESS,
    payload: channels,
  };
};

export const setSelectedChannel = (channel,key="selectedUser_channels") => {
  return {
    type: chatActiontypes.SET_SELECTED_CHANNEL,
    payload: channel,
    key
  };
};

export const EditGuestReqSuccess = (mess) => {
  return {
    type: chatActiontypes.EDIT_GUEST_REQ_SUC,
    payload: mess,
  };
};

export const saveChatResolutionData = (data) => {
  return {
    type: chatActiontypes.SAVE_CHAT_RESOLUTION,
    payload: data,
  };
};
export const saveGuestEngagementData = (data) => {
  return {
    type: chatActiontypes.SAVE_GUEST_ENAGGEMENT,
    payload: data,
  };
};

export const saveIntentData = (data) => {
  return {
    type: chatActiontypes.SAVE_INTENTS,
    payload: data,
  };
};

export const saveAnalyticsData = (data,type) => {
  return {
    type: chatActiontypes.SAVE_CHAT_ANALYTICS_DATA,
    payload: {data,type},
  };
};

export const selectedBroadCastList = (data) => {
  return {
    type: chatActiontypes.SET_BROADCAST_LIST,
    payload: data,
  };
};

export const fetchUsers = () => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      auth: { token }
    } = getState();
    if(!token)
      return
    dispatch(fetchUsersRequest());
    axiosClient(`/innspire-ai/users/?hotel=${selectedHotel.hotel_code}`)
      .then((response) => {
        const users = response.data;
        dispatch(fetchUsersSuccess(users));
        // dispatch(setSelectedUser(users[0]));
      })
      .catch((error) => {
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(fetchUsersfailure(errorMsg));
      });
  };
};

export const fetchGuests = (page_size = null,query=null,filterObj = {},next=null,isState=false,setLoading,setExpand=null,setLength,length,parameters={}) => {
  return (dispatch, getState) => {
    let {
      filter: { selectedHotel,selectedFilters },
      auth: { token }
    } = getState();
    selectedFilters = selectedFilters[parameters.filterkey] || []
    if(!token)
      return
    let str = ""
    for (let [key, value] of Object.entries(filterObj)) {
      console.log(key, value);
      str += `&${key}=${value}`
  }
    dispatch(fetchUsersRequest());
    let url = `/innspire-ai/v2/guests/?hotel=${selectedHotel.hotel_code}${page_size?`&page_size=${page_size}`:""}${query?`&q=${query}`:""}${str}`
    if(next){
      url = ensureHttps(next.toString())
    }
    if(selectedFilters.length>0)
      url = addFilterKey(selectedFilters,url,"&")

    
    axiosClient.get(url)
      .then((response) => {
        const users = response.data;
        

        var _ha = [],
        _on = [],
        _hi = [];

      for (var _u of users.results) {
        if (_u.requires_assistance) {
          _ha.push(_u);
        } else if (!_u.chat_closed) _on.push(_u);
        else _hi.push(_u);
      }
      if(query && setExpand && users?.results.length>0){
       setExpand((p)=>{
        let g = p[2]
        return [_ha.length>0,_on.length>0,g,_hi.length>0]
       })
      }

      let data = {
        ...users,
        _ha,_hi,_on

      }


        dispatch(fetchUsersSuccess(data,next?true:false));
        // dispatch(setSelectedUser(users[0]));
        if(isState)
          setLoading(false)
        if(setLength && length)
          setLength(length)
      })
      .catch((error) => {
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(fetchUsersfailure(errorMsg));
        if(isState)
          setLoading(false)
      });
  };
};

export const fetchBroadCast = () => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      auth: { token }
    } = getState();
    if(!token)
      return
    dispatch(fetchUsersRequest());
    axiosClient(`/messages/broadcasts/?hotel=${selectedHotel.hotel_code}`)
      .then((response) => {
        // const users = response.data;
        // dispatch(fetchUsersSuccess(users));
        // // dispatch(setSelectedUser(users[0]));
        // console.log(response)
        dispatch(selectedBroadCastList(response.data))

      })
      .catch((error) => {
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(fetchBroadCastFailure(errorMsg));
      });
  };
};

export const AddUpdateBroadCast = (body,isEdit=false, closeModel, setLoading) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    const url = `/messages/broadcasts/${isEdit ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;
    const method = isEdit ? 'patch' : 'post';

    axiosClient[method](url, body)
      .then((res) => {

        closeModel();
        dispatch(setGlobalMessage({
          mess: "Details are successfully saved..",
          type: 'success'
        }))
        if(setLoading)
        setLoading(false)
        dispatch(fetchBroadCast())
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
        if(setLoading)
          setLoading(false)
        closeModel();
      });

  };
};

export const deleteBroadCast = (body) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    const url = `/messages/broadcasts/${`${body.id}/`}?hotel=${selectedHotel.hotel_code}`;
    const method = "delete"

    axiosClient[method](url, body)
      .then((res) => {
        dispatch(setGlobalMessage({
          mess: "Group is successfully deleted..",
          type: 'success'
        }))
        dispatch(fetchBroadCast())
        dispatch(unSelectedBroadCast({}))
      })
      .catch((error) => {
        console.log(error);
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess: error.message,
          type: 'error'
        }))
      });

  };
};

export const unsetUserMessages = () => {
  return {
    type: chatActiontypes.UNSET_USER_MESSAGES,
  };
};

export const unsetGroupMessages = () => {
  return {
    type: chatActiontypes.UNSET_Group_MESSAGES,
  };
};

export const update_Message = (message) => {
  return {
    type: chatActiontypes.UPDATE_MESSAGE,
    payload: message,
  };
};

export const update_campaign_name_filter = (data) => {
  return {
    type: chatActiontypes.UPADATE_CAMPAIGN_NAME_FILTER,
    payload: data,
  };
};

export const fetchUserMessages = (userId, offset = 0, limit = 10) => {
  console.log(`fetchingMessages ${userId}`);
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    dispatch({ type: chatActiontypes.FETCH_USER_MESSAGES_REQUEST });
    let _url = `/innspire-ai/users/${userId}/messages/?limit=${limit}&hotel=${selectedHotel.hotel_code}`;
    if (offset) _url += `&offset=${offset}`;

    axiosClient
      .get(_url)
      .then((response) => {
        // if (response.data.results && response.data.results.length < limit)
        //   dispatch({
        //     type: chatActiontypes.FETCH_USER_MESSAGES_FAILURE,
        //     payload: response.data.results.length > 0 ? "--" : "No chat messages found",
        //   });
        var payload = response.data?.results || [];
        payload = payload.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        if (offset && offset > 0) dispatch(prependToMessages(payload));
        else dispatch(fetchUserMessageSuccess(payload));
      })
      .catch((error) => { 
        
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch({
          type: chatActiontypes.FETCH_USER_MESSAGES_FAILURE,
          payload: errorMsg,
        });
      });
  };
};

export const sendStaffMessage = (user, msg,setImageSumbitLoader) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    let url = `/innspire-ai/users/${user.user_id}/messages/?hotel=${selectedHotel.hotel_code}`;
    if (msg.id) url += `&feId=${msg.id}`;
    axiosClient.post(url, msg).then((res)=>{
      setImageSumbitLoader(false)
    }).catch((error) => {
      if(Array.isArray(error?.response?.data)){
        dispatch(setGlobalMessage({
          mess:error.response.data[0] || "Something went wrong",
          type:'error'
         }))
      }
      let err = !navigator.onLine
        ? "Network error"
        : error.detail ||
          error.message ||
          "Something went wrong, Please try again!";
      const unsent_msg = {
        ...msg,
        error: err,
        guest: user,
        speaker: "STAFF USER",
        created_at: new Date(),
        id: msg.id ? msg.id : `${Date.now()}`,
      };
      dispatch(updateUserMessage(unsent_msg, true));
      setImageSumbitLoader(false)
      // dispatch({type: chatActiontypes.FETCH_USER_MESSAGES_FAILURE, payload: errorMsg})
    });
  };
};

export const fetchMessagesOnReconnect = (userId, created_at) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .post(
        `/innspire-ai/users/${userId}/push/messages/${created_at}/staff/?hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        console.log("fetched message on socket reconnect");
      })
      .catch((error) => {
        console.log("error on fetching messages on reconnect", error);
      });
  };
};

export const setLoadingOnClick = (value) => {
  return {
    type: chatActiontypes.SET_LOADING_ONCLICK,
    payload: value,
  };
};

export const setBotEnabled = (userId, botState) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    dispatch(setLoadingOnClick(true));
    axiosClient
      .patch(
        `/innspire-ai/users/?user_id=${userId}&hotel=${selectedHotel.hotel_code}`,
        {
          chat_with_bot_enabled: botState,
        }
      )
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(setLoadingOnClick(false));
        // dispatch({type: chatActiontypes.FETCH_USER_MESSAGES_FAILURE, payload: errorMsg})
      });
    // .then((response) => {
    //   dispatch(setLoadingOnClick(false));
    // })
  };
};

export const unsetRequiresAssistance = (userId) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    dispatch(setLoadingOnClick(true));
    axiosClient
      .patch(
        `/innspire-ai/users/?user_id=${userId}&hotel=${selectedHotel.hotel_code}`,
        {
          requires_assistance: false,
        }
      )
      .catch((error) => {
        dispatch(setLoadingOnClick(false));
        const errorMsg = error.message;
      });
    // .then((response) => {
    //   const user = response.data;
    //   dispatch(setLoadingOnClick(false));
    //   // dispatch(updateOrAddChatUserAndReshuffle("assistance_required", user));
    //   // dispatch(updateNewUserNotification());
    // })
  };
};

export const closeChat = (userId) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    dispatch(setLoadingOnClick(true));
    axiosClient
      .patch(
        `/innspire-ai/users/?user_id=${userId}&hotel=${selectedHotel.hotel_code}`,
        {
          chat_closed: true,
          requires_assistance: false,
        }
      )
      .then((response) => {
        const user = response.data;
        dispatch(updateOrAddChatUserAndReshuffle("chat_closed", user));
        dispatch(unsetSelectedUser(user));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(setLoadingOnClick(false));
      });
  };
};

export const readMessageStatus = (user_id, msg_id) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .patch(
        `/innspire-ai/users/${user_id}/messages/${msg_id}/?hotel=${selectedHotel.hotel_code}`,
        {
          read: true,
        }
      )
      .then((response) => {
        const message = response.data;
      })
      .catch((error) => {
        console.log("error in read status change", error);
      });
  };
};

// fetch messaging channels for a specific user
export const fetchChannels = (userId) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .get(
        `/innspire-ai/users/${userId}/channels/?hotel=${selectedHotel.hotel_code}`
      )
      .then((response) => {
        dispatch(fetchChannelsSuccess(response.data));
      })
      .catch((error) => {
        const errorMsg = error.message;
      });
  };
};

// delete a message
export const deleteMessage = (userId, messageId) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .delete(
        `/innspire-ai/users/${userId}/messages/${messageId}/?hotel=${selectedHotel.hotel_code}`
      )
      .then((response) => {
        console.log("deleted");
      })
      .catch((error) => {
        console.log("error in deleting message", error);
      });
  };
};

export const fetchGroupMessages = (groupId, offset = 0, limit = 10) => {
  console.log(`fetchingMessages ${groupId}`);
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    dispatch({ type: chatActiontypes.FETCH_USER_MESSAGES_REQUEST });
    let _url = `/innspire-ai/broadcasts/guests/chat/messages/?broadcast_id=${groupId}&limit=${limit}&hotel=${selectedHotel.hotel_code}`;
    if (offset) _url += `&offset=${offset}`;

    axiosClient
      .get(_url)
      .then((response) => {
        // if (response.data.results && response.data.results.length < limit)
        //   dispatch({
        //     type: chatActiontypes.FETCH_USER_MESSAGES_FAILURE,
        //     payload: response.data.results.length > 0 ? "--" : "No chat messages found",
        //   });
        var payload = response.data?.results || [];
        payload = payload.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        if (offset && offset > 0) dispatch(prependToMessages(payload,"broadcast"));
        else dispatch(fetchUserMessageSuccess(payload,"broadcast"));
      })
      .catch((error) => {
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch({
          type: chatActiontypes.FETCH_USER_MESSAGES_FAILURE,
          payload: errorMsg,
        });
      });
  };
};

export const sendGroupMessage = (group, msg,setImageSumbitLoader) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      auth:{user}
    } = getState();

    let url = `/innspire-ai/broadcasts/guests/chat/messages/?broadcast_id=${group.id}&hotel=${selectedHotel.hotel_code}`;
    if (msg.id) url += `&feId=${msg.id}`;
    axiosClient.post(url, msg).then((res)=>{
      setImageSumbitLoader(false)
    }).catch((error) => {
      if(error?.response?.data && Array.isArray(error?.response?.data)){
        dispatch(setGlobalMessage({
          mess:error.response.data[0] || "Something went wrong",
          type:'error'
         }))
      }
      let err = !navigator.onLine
        ? "Network error"
        : error.detail ||
          error.message ||
          "Something went wrong, Please try again!";
      const unsent_msg = {
        ...msg,
        error: err,
        staff_user: user,
        speaker: "STAFF USER",
        created_at: new Date(),
        id: msg.id ? msg.id : `${Date.now()}`,
      };
      dispatch(updateUserMessage(unsent_msg, true,"broadcast"));
      if(setImageSumbitLoader)
        setImageSumbitLoader(false)
      // dispatch({type: chatActiontypes.FETCH_USER_MESSAGES_FAILURE, payload: errorMsg})
    });
  };
};

export const fetchGroupChannels = (userId) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .get(
        `/innspire-ai/broadcasts/guests/channels/?broadcast_id=${userId}/channels/&hotel=${selectedHotel.hotel_code}`
      )
      .then((response) => {
        dispatch(fetchGroupChannelSuccess(response.data));
      })
      .catch((error) => {
        const errorMsg = error.message;
      });
  };
};


export const editGuestUserDetails = (body,closeModel,userId,setLoading) => {
  return (dispatch, getState) => {
    body.checkin_date === "" && delete body.checkin_date
    body.checkout_date === "" && delete body.checkout_date
    // console.log(body);
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .patch(
        `/innspire-ai/users/?user_id=${userId}&hotel=${selectedHotel.hotel_code}`,
        body
      )
      .then((res) => {
      //  dispatch( fetchUsers())
        closeModel();
       dispatch(setGlobalMessage({
        mess:"Details are successfully saved..",
        type:'success'
       }))
       setLoading(false)
      })
      .catch((error) => {
        //check_auth_error(error);
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess:errorMsg,
          type:'error'
         }))
         setLoading(false)
      });
  };
};

export const fetchChatResolution = () => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel,selectedCurrDate },
      auth: { token }
    } = getState(); 
    if(!token)
      return
    const date_params = `date=${convert_dates_to_query_params(
      selectedCurrDate.option,
      selectedCurrDate.startDate,
      selectedCurrDate.endDate,
    )}`;
    axiosClient
      .get(
        `/chat-analytics/chat-overall-stats/?hotel=${selectedHotel.hotel_code}&${date_params}`
      )
      .then((response) => {
        dispatch(saveChatResolutionData(response?.data[0]));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess:"Something went wrong for - Analytics of InnSpire.One system.",
          type:'error'
         }))
      });
  };
};

export const fetchGuestEngagement = (setLoading) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel,selectedCurrDate },
      auth: { token }
    } = getState(); 
    if(!token)
      return
    const date_params = `date=${convert_dates_to_query_params(
      selectedCurrDate.option,
      selectedCurrDate.startDate,
      selectedCurrDate.endDate,
    )}`;
    axiosClient
      .get(
        `/chat-analytics/guest-engagement-timeline/?hotel=${selectedHotel.hotel_code}&${date_params}`
      )
      .then((response) => {
        dispatch(saveGuestEngagementData(response.data));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess:"Something went wrong",
          type:'error'
         }))
      })
      .finally(()=>{
        if(setLoading)
        setLoading(false)
      });
  };
};

export const fetchIntens = (page,commonTableLoading=false) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel,selectedCurrDate },
      auth: { token }
    } = getState(); 
    if(!token)
      return
    const date_params = `date=${convert_dates_to_query_params(
      selectedCurrDate.option,
      selectedCurrDate.startDate,
      selectedCurrDate.endDate,
    )}`;
    axiosClient
      .get(
        `/chat-analytics/intents-detected/?hotel=${selectedHotel.hotel_code}&${date_params}`
      )
      .then((response) => {
        dispatch(saveIntentData(response.data));
        if(commonTableLoading)
          commonTableLoading(false)
        // dispatch(commonTableLoading(false))
      })
      .catch((error) => {
        if(commonTableLoading)
          commonTableLoading(false)
        // dispatch(commonTableLoading(false))
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess:"Something went wrong",
          type:'error'
         }))
      });
  };
};

export const fectchChatAnalyticsData = (page=null,setLoading=false,type,isSetLoadingIsState=false,filter={}) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel,selectedCurrDate },
      auth: { token }
    } = getState(); 
    if(!token)
      return

    const date_params = `date=${convert_dates_to_query_params(
      selectedCurrDate.option,
      selectedCurrDate.startDate,
      selectedCurrDate.endDate,
    )}`;
    let  url =  `/chat-analytics/${type}/?hotel=${selectedHotel.hotel_code}&${date_params}`
    for(let k in filter){
      url+=`${url.includes("?")?"&":"?"}${k}=${filter[k]}`
    }
    axiosClient
      .get(
        url
      )
      .then((response) => {
        dispatch(saveAnalyticsData(response.data,type));
        if(setLoading){
          if(isSetLoadingIsState)
          setLoading(false)
          else
          dispatch(setLoading(false))
        }
     
      })
      .catch((error) => {
        if(setLoading){
          if(isSetLoadingIsState)
          setLoading(false)
          else
          dispatch(setLoading(false))
        }
        const errorMsg = error.message;
        dispatch(setGlobalMessage({
          mess:"Something went wrong",
          type:'error'
         }))
      });
  };
};


export const fetcWhatsapphMessageTemplates = (user_id="") => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      auth: { token }
    } = getState(); 
    if(!token)
      return
    axiosClient
      .get(
        `/innspire-ai/chat/whatsapp/templates/?hotel=${selectedHotel.hotel_code}&user_id=${user_id}`
      )
      .then((response) => {
        console.log(response);
        
       if(typeof response.data === "object" && response.data){
        dispatch({
          type: chatActiontypes.FETCH_WHATSAPP_TEMPLATES,
          payload:response.data
        });
       }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(
          dispatch(setGlobalMessage({
            mess:"Something went wrong for fetching whatspaap channel messages",
            type:'error'
           })
      ))
      });
  };
};
