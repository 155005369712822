import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Stack,
  Typography,
  Box,
  Snackbar,
} from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';

// const slideIn = keyframes`
//   from { transform: translateY(-100%); opacity: 0; }
//   to { transform: translateY(0); opacity: 1; }
// `;

const progress = keyframes`
  from { width: 100%; }
  to { width: 0%; }
`;

// const pulseAnimation = keyframes`
//   0% { transform: scale(1); opacity: 1; }
//   50% { transform: scale(1.5); opacity: 0.5; }
//   100% { transform: scale(2); opacity: 0; }
// `;

const spinAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

// const ReconnectionDot = styled('div')(({ theme }) => ({
//   width: 8,
//   height: 8,
//   backgroundColor: theme.palette.primary.main,
//   borderRadius: '50%',
// }));

// const PulseCircle = styled('div')(({ theme }) => ({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   width: 8,
//   height: 8,
//   borderRadius: '50%',
//   border: `2px solid ${theme.palette.primary.main}`,
//   transform: 'translate(-50%, -50%)',
//   animation: `${pulseAnimation} 1.5s infinite`,
// }));

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  minWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    minWidth: '600px',
  },
  padding: theme.spacing(2),
  '& .MuiAlert-message': {
    width: '100%',
    padding: 0,
  },
  '& .MuiAlert-icon': {
    color: '#fff',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '2px',
    backgroundColor: theme.palette.primary.main,
    animation: `${progress} 5s linear forwards`,
    boxShadow: '0 0 8px rgba(0, 255, 136, 0.4)',
    // key: resetkey,
  },
}));

// const ReconnectionAnimation = styled(Box)(({ theme}) => ({
//   position: 'relative',
//   width: 40,
//   height: 40,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   marginRight: theme.spacing(2),
// }));

const StyledRefreshIcon = styled(RefreshIcon)(({ isreconnecting }) => ({
  animation: isreconnecting ? `${spinAnimation} 1s linear infinite` : 'none',
}));

const CustomAlert = ({
  retryCountRef,
  MAX_RETRIES = 10,
  RETRYINTERVAL = 5,
  onClose,
  message,
  retryConnection,
  retryFunction
}) => {
  const [countdown, setCountdown] = useState(RETRYINTERVAL);

  useEffect(() => {
    let timer = null;
    timer = countdown > 0 && setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
    
    if (countdown === 0 && retryCountRef?.current <= MAX_RETRIES) {
      retryFunction();
    }
    
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown]);

  useEffect(() => {
    if (retryConnection === false && retryCountRef?.current <= MAX_RETRIES) {
      setCountdown(RETRYINTERVAL);
    }
  }, [retryConnection]);

  if (!message) return null;

  return (
    <StyledAlert
      icon={<InfoIcon />}
      severity="info"
    >
      <Stack spacing={1} width="100%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 500,  fontSize: {xs:"12px",md:"1rem"} }}>
              {message} {countdown > 0 && `Retrying in ${countdown} seconds`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* {retryConnection && (
              // <ReconnectionAnimation>
              //   <ReconnectionDot />
              //   <PulseCircle sx={{ animationDelay: '0s' }} />
              //   <PulseCircle sx={{ animationDelay: '0.5s' }} />
              //   <PulseCircle sx={{ animationDelay: '1s' }} />
              // </ReconnectionAnimation>
              <CircularProgress size={20} thickness={5} sx={{ color: 'primary.main' }} />
            )} */}
            <Button
              variant="contained"
              onClick={(e)=>{
                e.stopPropagation()
                retryCountRef.current = MAX_RETRIES + 1
                setCountdown(0)
                retryFunction()
              }}
              startIcon={<StyledRefreshIcon isreconnecting={retryConnection ? 1 : 0} />}
              size="small"
              sx={{
                bgcolor: 'white',
                color: 'black',
                '&:hover': {
                  bgcolor: 'grey.200',
                },
                textTransform: 'none',
                minWidth: '120px',
           fontSize: {xs:"12px",md:"1rem"}
              }}
            >
             {retryConnection ? 'Reconnecting' : 'Retry'}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </StyledAlert>
  );
};

const SocketConnectionAlert = ({
  message = "Please check your internet connection.",
  open,
  setOpen,
  retryConnection,
  retryFunction,
  retryCountRef,
  MAX_RETRIES,
  RETRYINTERVAL
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={null}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Box>
        <CustomAlert
          retryFunction={retryFunction}
          onClose={handleClose}
          message={message}
          retryConnection={retryConnection}
          retryCountRef={retryCountRef}
          MAX_RETRIES={MAX_RETRIES}
          RETRYINTERVAL={RETRYINTERVAL}
        />
      </Box>
    </Snackbar>
  );
};

export default SocketConnectionAlert;