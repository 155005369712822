import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, Select, FormControl, InputLabel, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Styled components based on your style pattern
const DropdownBox = styled(Box)(({ theme }) => ({
  padding: '1px 24px',  // Reduced top and bottom padding to 8px, left and right remain 24px
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  width: '100%',
}));


const DropdownTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 200,
  fontSize: '14px',
  color: '#1a1a1a',
  marginBottom: '8px',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
}));

const TemplateDropdown = ({ onTemplateSelect }) => {
  const templateData = useSelector((state) => state?.settings?.EmailTemplate?.data || []);
  
  const [selectedTemplate, setSelectedTemplate] = React.useState('');

  const handleTemplateChange = (event) => {
    const selectedTemplateValue = event.target.value;
    setSelectedTemplate(selectedTemplateValue);
    
    const selectedTemplateObj = templateData.find(template => template.template === selectedTemplateValue);
    console.log(selectedTemplateObj);

    
    if (selectedTemplateObj) {
      onTemplateSelect(selectedTemplateObj.template);
    }
  };

  return (
    <DropdownBox>
      <DropdownTitle>Select Template</DropdownTitle>
      <StyledFormControl>
        <InputLabel id="template-dropdown-label">Template</InputLabel>
        <Select
          labelId="template-dropdown-label"
          value={selectedTemplate}
          onChange={handleTemplateChange}
          label="Template"
        >
          {templateData.map((templatei, index) => {
            if (templatei.template) {
              return (
                <MenuItem key={index} value={templatei.template}>
                  {templatei.name}

                </MenuItem>
              );
            }
            return null;
          })}
        </Select>
      </StyledFormControl>
    </DropdownBox>
  );
};

export default TemplateDropdown;
