import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  changeLoadingStatePlaceAndEvent,
  delete_Place_Events,
  fetchEventVenueData,
} from "../../../commons/components/Settings/actions/settingsActions";
import { fetchFilters } from "../../../commons/components/Filter/actions/actions";
import CommonSingleRow from "../../../commons/components/Settings/components/CommonSingleRow";
import BasePage from "../../../commons/components/BasePage/BasePage";
import { Box, Button, Checkbox, Divider, Typography } from "@mui/material";
import CommonTableUI from "../../../commons/components/Settings/components/CommonTableUI";
import SearchInput from "../commonComp/SearchInput";
import { AddUpdateEventVenue } from "../action/TripPlannerAction";
import DialogBox from "../../../commons/components/PopUp/DialogBox";
// import LoadingOverlay from "../../../commons/components/Loader/LoadingOverlay";

const filterRoute = `/trip-planner`;

const Monitors = () => {
  const [initialRenderFlag, setinitialRenderFlag] = useState(false);
  const [ids, setIds] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [operationData, setOperationData] = useState({});
  const [loading, setLoading] = useState(false);
  const APITrigger = useRef(null);
  const TableContainerRef = useRef(null);
  const ComponentDataRef = useRef({});
  const selectedFilters = useSelector(
    (state) => state.filter.selectedFilters[`${filterRoute}`] || []
  );
  const navObj = useSelector((state) => state.nav.selectedNav || {});
  const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );
  const DataSelector = (state) =>
    state.settings?.PlaceAndEvent?.EVENT_VENUE?.data || [];
  const data = useSelector(DataSelector);
  const Count = useSelector(
    (state) => state.settings?.PlaceAndEvent?.EVENT_VENUE?.count || 0
  );
  const minRow = 20;
  const page = Math.ceil(Count / minRow);
  const LoadingSelector = (state) =>
    state.settings?.PlaceAndEvent?.EVENT_VENUE?.loading;
  const ErrorSelector = (state) =>
    state.settings?.PlaceAndEvent?.EVENT_VENUE?.error;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return params.get("q") || "";
      }, [location.search]);

  const columns = 
  // useMemo(
  //   () =>
     [
      {
        name: "Select",
        orderBy: "selected"
        //  (
        //   <Checkbox
        //     size={"small"}
        //     name="selectAll"
        //     checked={selectAll}
        //     onChange={(e) => handleSelectAllChange(e.target.checked)}
        //   />
        // )
      },
      { name: "Name", orderBy: "name" },
      { name: "Location", orderBy: "address" },
      { name: "Distance from hotel", orderBy: "distance" },
      { name: "Category", orderBy: "category" },
      { name: "Action" },
    ]
  //   // [selectAll] 
  //   []
  // );


  const dimension = {
  
    "address": { maxWidth: "500px", minWidth: "170px" },
  };

  useEffect(() => {
    if (Object.keys(selectedHotel).length > 0) {
      dispatch(
        fetchFilters(
          `/data-aggregator/places-events/dashboard-filters/?hotel=${selectedHotel.hotel_code}`,
          "trip_planner",
          {
            isHotelChange: initialRenderFlag,
            key: `${filterRoute}`,
          }
        )
      );
      if (!initialRenderFlag) setinitialRenderFlag(true);
    }
  }, [selectedHotel]);

  useEffect(() => {
    if (APITrigger.current) clearTimeout(APITrigger.current);
    if (Object.keys(selectedHotel).length > 0 && selectedFilters.length > 0) {
      APITrigger.current = setTimeout(() => {
        dispatch(changeLoadingStatePlaceAndEvent(true, "EVENT_VENUE"));
        dispatch(
          fetchEventVenueData(
            0,
            changeLoadingStatePlaceAndEvent,
            false,
            query || null,
            null,
            {
              filterkey: `${filterRoute}`,
              entityType: "EVENT_VENUE",
            }
          )
        );
      }, 0);
    }
  }, [selectedFilters]);

  // useEffect(() => {
  //   if (selectAll) handleSelectAllChange(true);
  // }, [data]);

  const handleSave = () => {
    console.log("save is called");
  };

  const fetchData = (newPage, q = null, parameter = {}) => {
    const searchQuery = q === null ? query : q;
    dispatch(changeLoadingStatePlaceAndEvent(true, "EVENT_VENUE"));
    dispatch(
      fetchEventVenueData(
        newPage,
        changeLoadingStatePlaceAndEvent,
        false,
        searchQuery,
        null,
        {
          filterkey: `${filterRoute}`,
          entityType: "EVENT_VENUE",
          ...parameter
        }
      )
    );
  };

  const handleEdit = useCallback((data) => {
    history.push(`/trip-planner/manage?id=${data.id}&entity=Event Venue`);
  }, [history]);

  const handleDelete = useCallback(() => {
    dispatch(
      delete_Place_Events(operationData.id, {
        entityType: operationData.type,
      })
    );
    setDeleteDialog(false)
  }, [dispatch,operationData]);

  // const handleSearch = (q) => {
  //   fetchData(0, q);
  // };

  const handleCheckboxChange = (id, checked) => {
    dispatch(
      AddUpdateEventVenue(
        {
          id,
          monitor_for_events: checked,
        },
        true,
        setLoading,
        {}
      )
    );

    // const newIds = {
    //   ...ids
    // }
    // if(checked)
    //   newIds[id] = checked
    // else
    // delete newIds?.[id]
    // setIds(newIds);
    // const allSelected =
    //   Object.keys(newIds).length  === data.length;
    // setSelectAll(allSelected);
    // selectALlRef.current = allSelected
  };

  // const handleSelectAllChange = (checked) => {
  //   setSelectAll(checked);

  //   const newIds = {};
  //   if (checked) {
  //     data.forEach((item) => {
  //       newIds[item.id] = true;
  //     });
  //   }
  //   setIds(newIds);
  // };

  const menuItems = [
    { label: "Edit", onClick: handleEdit },
    { label: "Delete", onClick: (d)=>{
      setDeleteDialog(true)
      setOperationData(d)
    } },
  ];

  const RowComponent = useCallback(({ row = {},skipRowIndices=[] }) => {
    let payload = {
      checkbox: (
        <Checkbox
          size={"small"}
          name={row.id}
          checked={row.monitor_for_events || false}
          onChange={(e) => {
            e.stopPropagation();
            handleCheckboxChange(row.id, e.target.checked);
          }}
          disabled={loading}
        />
      ),
      name: row.name,
      address: row.address,
      distance: row.distance || "",
      category: row.category?.display_name,
    };

    const css = {
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    };
    return (
      <>
        <CommonSingleRow
          key={`${row?.id}`}
          payload={payload}
          showMenu={true}
          menuItems={menuItems}
          id={row.id}
          fullData={row}
          commonCss={css}
          dimension={dimension}
          skipRowIndices={skipRowIndices}
        />
      </>
    );
  }, [loading]);
  return (
    <BasePage title={navObj.name} showBox={true} show_side_filter={true}>
      {/* <LoadingOverlay isLoading={loading} message="Saving data..." /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          height: "100%",
          width: "100%",
          padding: "8px 0px 8px 0px",
          flexGrow: 1,
          marginBottom: "80px",
        }}
      >
        <Box
          gap={1}
          display={"flex"}
          flexDirection={"column"}
          position={"relative"}
        >
          <Typography
            variant="h3"
            component={"div"}
            sx={{
              fontWeight: "fontWeightBold",
              color: "#232531",
              fontSize: 32,
              letterSpacing: "-0.80px",
              lineHeight: "32px",
            }}
          >
            Monitors
          </Typography>
          <Typography variant="body2" color={"textSecondary"}>
            Create monitors lists
          </Typography>

          {/* <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              // translate:"0% -50%"
            }}
            display="flex"
            gap={1}
            justifyContent="flex-end"
            marginTop={2}
          >
            <Button
              onClick={(e) => {
                e.stopPropagation();
                history.goBack();
              }}
              sx={{
                height: "40px",
                padding: "0px 16px",
                mr: 1,
                backgroundColor: "#EEF0F1",
                color: "#000000",
                fontSize: "14px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#ABB0B8",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleSave();
              }}
              sx={{
                height: "40px",
                padding: "0px 16px",
                backgroundColor: "#0074E8",
                color: "#FFFFFF",
                fontSize: "14px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#005BB5",
                },
              }}
            >
              Save
            </Button>
          </Box> */}
        </Box>
        <Divider
          sx={{
            padding: "8px 0px",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 10,
          }}
        >
          {/* <SearchInput onSearch={handleSearch} additonCssForParent={{justifyContent:"end",alignSelf:"end"}}/> */}

          <CommonTableUI
            columns={columns}
            fetchData={fetchData}
            dataSelector={DataSelector}
            loadingSelector={LoadingSelector}
            errorSelector={ErrorSelector}
            isEditPermission={true}
            RowComponent={RowComponent}
            minRow={minRow}
            count={Count}
            page={page}
            initialFetch={false}
            title={"Monitors"}
            heading={false}
            maxHeight={"1096px"}
            serverSidePagination={true}
            refreshButton={true}
            ordering={true}
            searchBar={true}
            columnCtrl={true}
            TableContainerRef={TableContainerRef}
            ComponentDataRef={ComponentDataRef}
          />
          <DialogBox
        open={deleteDialog}
        setOpen={setDeleteDialog}
        handleAgree={handleDelete}
        agreeButtonText={"Delete"}
        content={"Please confirm before you delete"}
      />
        </Box>
      </Box>
    </BasePage>
  );
};

export default Monitors;
