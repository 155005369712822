import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogBox({
  open,
  setOpen,
  handleAgree,
  agreeButtonText,
  content,
  setContent = null,
  dont_ask_checkbox = false,
  topHeading = "Confirm",
  closeText = "Close",
}) {
  const [checkbox, setCheckbox] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    if (setContent) setContent(null);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{topHeading}</Typography>
          <CloseIcon
            style={{
              fontSize: "15px",
            }}
            sx={{
              position: "absolute",
              top: "3%",
              right: "1%",
              color: "gray",
              cursor: "pointer",
              // fontSize: "0.8rem"
            }}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: "25vw",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: dont_ask_checkbox ? "space-between" : "end",
            alignItems: "center",
            padding: "16px 24px",
          }}
        >
          {dont_ask_checkbox && (
            <FormControlLabel
              control={
                <Checkbox
                  name="remember"
                  color="success"
                  onChange={(e) => {
                    e?.stopPropagation();
                    setCheckbox(e.target.checked);
                  }}
                />
              }
              label="Don't ask again"
              sx={{
                color: "rgb(0 0 0 / 60%)", // Ensure the label text matches content
                marginBottom: "2px",
              }}
            />
          )}
          <Box>
            <Button onClick={handleClose}>{closeText}</Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (dont_ask_checkbox) handleAgree(checkbox);
                else handleAgree();
              }}
              autoFocus
            >
              {agreeButtonText}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
