import React from 'react';
import { Button, styled, CircularProgress, Box } from '@mui/material';
// import SaveIcon from '@mui/icons-material/Save';
// import SendIcon from '@mui/icons-material/Send';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const StyledButton = styled(Button)(({ theme, size, backgroundColor, customColor, loadingPosition,sx={} }) => ({
  borderRadius: '4px',
  textTransform: 'none',
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '8px',
  // minWidth: '100px',
  ...sx,
}));

const LoadingIndicator = styled(Box)(({ loadingPosition }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const DynamicButton = ({
  children,
  variant = 'contained',
  size = 'medium',
  backgroundColor,
  customColor,
  fullWidth = false,
  disabled = false,
  customStyles = {},
  loading = false,
  loadingPosition = 'center',
  loadingIndicator = <CircularProgress color="inherit" size={20} />,
  loadingText = 'Loading...',
  startIcon = null,
  endIcon = null,
  onClick,
  ...props
}) => {
  const showStartIcon =  !loading? startIcon :  loadingPosition !== 'start' && startIcon ;
  const showEndIcon = !loading? endIcon:  loadingPosition !== 'end' && endIcon;
  
  const renderContent = () => {
    if (loading && loadingPosition === 'center') {
      return typeof loadingIndicator === 'string' ? loadingIndicator : loadingText;
    }
    return children;
  };

  return (
    <StyledButton
      variant={variant}
      size={size}
      backgroundColor={backgroundColor}
      fullWidth={fullWidth}
      disabled={disabled || loading}
      sx={customStyles}
      onClick={!disabled && !loading ? onClick : undefined}
      loading={loading}
      startIcon={showStartIcon ? startIcon : null}
      endIcon={showEndIcon ? endIcon : null}
      {...props}
    >
{
      loading && loadingPosition === 'start' && (

          <LoadingIndicator loadingPosition={loadingPosition}>
            {typeof loadingIndicator === 'string' ? (
              loadingIndicator
            ) : (
              <CircularProgress color="inherit" size={20} />
            )}
          </LoadingIndicator>
      )
}
      {renderContent()}

      {
      loading && loadingPosition === 'end' && (

          <LoadingIndicator loadingPosition={loadingPosition}>
            {typeof loadingIndicator === 'string' ? (
              loadingIndicator
            ) : (
              <CircularProgress color="inherit" size={20} />
            )}
          </LoadingIndicator>
      )
}
    </StyledButton>
  );
};

// const LoadingButtonsDemo = () => {
//   const [loading, setLoading] = React.useState(false);

//   const handleClick = () => {
//     setLoading(true);
//     setTimeout(() => setLoading(false), 2000);
//   };

//   return (
//     <Box sx={{ '& > button': { m: 1 } }}>
//       <DynamicButton
//         loading={loading}
//         loadingPosition="start"
//         endIcon={<AddCircleOutlineIcon />}
//         variant="contained"
//         onClick={handleClick}
//         color='success'
//         sx={{
//           // padding:"10px"
//         }}
//       >
//         Save Changes
//       </DynamicButton>

//       <DynamicButton
//         loading={loading}
//         loadingPosition="end"
//         endIcon={<SendIcon />}
//         variant="contained"
//         color="secondary"
//         onClick={handleClick}
//       >
//         Send Message
//       </DynamicButton>

//       <DynamicButton
//         loading={loading}
//         loadingPosition="center"
//         loadingText="Fetching..."
//         variant="outlined"
//         onClick={handleClick}
//       >
//         Fetch Data
//       </DynamicButton>

//       <DynamicButton
//         // disabled
//         loading={loading}
//         variant="outlined"
//         onClick={handleClick}
//         // sx={{
//         //   color: 'error.main',
//         //   borderColor: 'error.main',
//         // }}
//       >
//         Disabled
//       </DynamicButton>
//     </Box>
//   );
// };

export default DynamicButton ;