import React, { useState, useEffect } from 'react';
import { 
  Alert, 
  Button, 
  Stack, 
  LinearProgress,
  Typography,
  Box
} from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalMessage } from '../../../actions/dashboardActions';


const slideIn = keyframes`
  from { transform: translateY(-100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const progress = keyframes`
  from { width: 100%; }
  to { width: 0%; }
`;

const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  minWidth: '100%', // default for xs
  [theme.breakpoints.up('sm')]: {
    minWidth: '600px',
  },
  padding: theme.spacing(2),
  '& .MuiAlert-message': {
    width: '100%',
    padding: 0,
  },
  '& .MuiAlert-icon': {
    color: '#fff',
  },
//   animation: 'fadeIn 0.3s ease-in',
//   '@keyframes fadeIn': {
//     '0%': {
//       opacity: 0,
//       transform: 'translateY(-10px)',
//     },
//     '100%': {
//       opacity: 1,
//       transform: 'translateY(0)',
//     },
//   },

'&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '2px',
    backgroundColor: theme.palette.primary.main,
    animation: `${progress} 10s linear forwards`,
    boxShadow: '0 0 8px rgba(0, 255, 136, 0.4)',
  },
}));

// const ProgressBar = styled(LinearProgress)(({ theme }) => ({
//   width: '100%',
//   height: 4,
//   marginTop: theme.spacing(1),
//   '& .MuiLinearProgress-bar': {
//     backgroundColor: '#fff',
//   },
//   backgroundColor: 'rgba(255, 255, 255, 0.2)',
// }));


const countdownOptions = [10,15,20,25,30]

const SystemAlert = ({ onClose, message }) => {
  const [countdown, setCountdown] = useState(() => 
    countdownOptions[Math.floor(Math.random() * countdownOptions.length)]
  );
  const [data,setData] = useState({});
  const dispatch = useDispatch();
const globalMessage = useSelector(
  (state) =>
    state.common?.globalMessage?.mess ? state.common.globalMessage : ""
);

useEffect(() => {
if(globalMessage?.data)
  setData(globalMessage.data)
}, [globalMessage]);

  useEffect(() => {
    let timer = null
    console.log(data,countdown);
    
    if (data?.auto_reload) {
        timer = countdown > 0 && setInterval(() => {
            setCountdown((prev) => prev - 1);
          }, 1000);
    }
    
    if (countdown === 0) {
      handleReload();
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown,data]);

  const handleReload = () => {
        dispatch(setGlobalMessage({
          mess:"",
          type:"",
          data:{}
        }))
    window.location.reload();
  };
  
  if(!message) return null;

  return (
    <StyledAlert
      icon={<InfoIcon />}
      severity="info"
    >
      <Stack spacing={1} width="100%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: {xs:"12px",md:"1rem"} }}>
             {message} {data?.auto_reload && `in ${countdown} seconds`}    
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={handleReload}
            startIcon={<RefreshIcon />}
            size="small"
            sx={{
              bgcolor: 'white',
              color: 'black',
              '&:hover': {
                bgcolor: 'grey.200',
              },
              textTransform: 'none',
              minWidth: '120px',
            }}
          >
            Reload Now
          </Button>
        </Stack>
        {/* <ProgressBar
          variant="determinate"
          value={(countdown / 10) * 100}
        /> */}
      </Stack>
    </StyledAlert>
  );
};

export default SystemAlert;