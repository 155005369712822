import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TableCell,
    tableCellClasses,
    styled,
    TablePagination,
    TableSortLabel, 
  } from "@mui/material";
  import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { ReplayOutlined } from "@mui/icons-material";
  import CustomModal from "../../Modals/CustomModal";
  import LineLoader from "../../Loader/LineLoader";
import SearchInput from "../../../../apps/TripPlanner/commonComp/SearchInput";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TableColCtrl from "../../TableUtils/TableColCtrl";

  
  const StyledTableCell = styled(TableCell)(({ theme, fontWeight }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#E4E6E7",
      color: "#6D7378",
      padding: "4px 5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "14px",
      fontWeight: fontWeight ? fontWeight : 500,
    },
  }));
  
  const CommonTableUI = ({
    columns = [], // Pass columns dynamically
    fetchData, // Function to fetch data
    dataSelector, // Selector to get data from the store
    loadingSelector, // Selector to get loading state
    errorSelector, // Selector to get error state
    isEditPermission = false,
    handleEdit, // Function to handle edit
    handleDelete, // Function to handle delete
    RowComponent,
    count,
    minRow=10,
    page,
    initialFetch=true,
    title = "table",
    maxHeight,
    minHeight,
    heading,
    maxWidth=null,
    serverSidePagination=false,
    useInfiniteScroll = false,
    ordering=false,
    TableContainerRef=null,
    ComponentDataRef,
    searchBar=false,
    refreshButton=false,
    columnCtrl=false
  }) => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch();
    const data = useSelector(dataSelector) || [];
    const loading = useSelector(loadingSelector);
    const error = useSelector(errorSelector);
    const observerTarget = useRef(null);
  
    const [currPage, setCurrPage] = useState(0);
    const [confirmModal, setConfirmModal] = useState(null);
    const [open, setOpen] = useState(false);
    const [isChecked, setIsChecked] = useState([]);
    const [visibleRows, setVisibleRows] = useState([]);
    const [order, setOrder] = useState({});
    const selectedHotel = useSelector(
        (state) => state.filter.selectedHotel || {}
      );
      const [visibleColumns, setVisibleColumns] = useState({});
      const filteredColumns = useMemo(() => {
        return columnCtrl 
          ? columns.filter(col => visibleColumns[col.name])
          : columns;
      }, [columns, visibleColumns, columnCtrl]);

      const getInvisibleRowIndices = (visibleColumns = {}) => {
        return Object.keys(visibleColumns)
          .map((key, index) => (visibleColumns[key] === false ? index : null))
          .filter(index => index !== null);
      };

      const hiddenIndices = useMemo(() => {
        return getInvisibleRowIndices(visibleColumns) || [];
      }, [columns, visibleColumns]);

      useEffect(() => {
        if(!columnCtrl) return;
        const initialVisibility = {};
        columns.forEach((column) => {
          initialVisibility[column.name] = true;
        });
        setVisibleColumns(initialVisibility);
      }, [columns]);


      useEffect(() => {    
            
        if (!useInfiniteScroll || loading) return;
    
        const observer = new IntersectionObserver(
          (entries) => {
            // console.log(entries[0].isIntersecting,loading,observerTarget);
            
            if (entries[0].isIntersecting && !loading) {
              const nextPage = currPage + 1;
              if (nextPage * minRow < count) {
                if (serverSidePagination) {
                  fetchData(nextPage, null, { orderBy: order });
                }
                setCurrPage(nextPage);
              }
            }
          },
          {
            root: TableContainerRef?.current || null,
            threshold: 0.1,
            rootMargin: '100px',
          }
        );
    
        const currentTarget = observerTarget.current;
        if (currentTarget) {
          observer.observe(currentTarget);
        }

        // console.log("cur",currPage,"page",page,useInfiniteScroll,loading,serverSidePagination,observer,currentTarget);

    
        return () => {
          if (currentTarget) {
            observer.unobserve(currentTarget);
          }
        };
      }, [currPage, count, loading, minRow, serverSidePagination, useInfiniteScroll]);
  
    useEffect(() => {
      if(initialFetch)
        fetchData()
      if(!useInfiniteScroll){
        setVisibleRows(data);
        setCurrPage(0)
      }
    }, [selectedHotel]);
  
    useEffect(() => {
      console.log("cur",currPage,data)
      if (data.length > 0) {
       if(useInfiniteScroll)
        setVisibleRows(data);
        else
       setVisibleRows(data.slice(currPage * minRow, currPage * minRow + minRow));
      } else {
        setVisibleRows([]);
      }
    }, [currPage, data]);
  
    const handleChangePage = (event, newPage) => {
      console.log(newPage, page)
      if (newPage > page) {
       fetchData(newPage,null,{ orderBy: order})
      }
        setCurrPage(newPage);
    };

    useEffect(()=>{
        if(serverSidePagination && page == 0)
          if(currPage != 0)
          setCurrPage(page)
    },[page])
    const closeConfirmModal = () => {
      setConfirmModal(null);
    };
  
    const confirmChange = () => {
      closeConfirmModal();
    };


      const createSortHandler= (e,val)=>{
        let newOrder = order
        if (!newOrder[val.orderBy]) {
          newOrder[val.orderBy] = 'asc';
        } else if (newOrder[val.orderBy] === 'asc') {
          newOrder[val.orderBy] = 'desc';
        } else if (newOrder[val.orderBy] === 'desc') {
          delete newOrder[val.orderBy];
        }
        setOrder((prevOrder) => {
              return newOrder;
        });

        console.log("ComponentDataRef: ",ComponentDataRef);
        if(ComponentDataRef && ComponentDataRef.current){
          ComponentDataRef.current = {
            ...ComponentDataRef.current,
            order: newOrder
        }
      }
      
        
    
        if (TableContainerRef.current) {
          TableContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    
    
        fetchData(0, null,{orderBy:newOrder});
      }

      const handleSearch = useCallback((q) => {
        if(TableContainerRef?.current){
          TableContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
        
                fetchData(0, q,{orderBy:order || null});
              }, [fetchData]);
  
    return (
      <Box sx={{ width: "100%", ...(maxWidth ? { maxWidth: maxWidth } : {}) }}>
        <Box display={"flex"} justifyContent={"space-between"} padding={"8px 12px"}>
            <Typography
            >
              {heading ? title: ""}
            </Typography>
        <Box display={"flex"} gap={"4px"}>
      {
        searchBar &&   <SearchInput onSearch={handleSearch} additonCssForParent={{justifyContent:"end",alignSelf:"end",marginTop:"0px"}}/>
      }
    {
      refreshButton &&     <IconButton
      onClick={() => {
        if(searchBar){
          const params = new URLSearchParams(location.search);
          params.set("q", "");
          history.push({ search: params.toString() });
          handleSearch("")
        }
        setOrder({});
        if(ComponentDataRef && ComponentDataRef.current){
          ComponentDataRef.current.order = {}
        }
        fetchData(0, null, {orderBy:{}})
        if(!serverSidePagination) setCurrPage(0);
        
      }}
    >
      <ReplayOutlined />
    </IconButton>
    }

{
  columnCtrl && <TableColCtrl visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} columns={columns} />
}
        </Box>
        </Box>
        <Paper sx={{ width: "100%", overflow: "auto", borderRadius: "8px" }}>
          <TableContainer
            sx={{
              maxHeight: maxHeight || 250,
              // minHeight:minHeight ||  250,
              flexGrow: 1,
            }}
            //  onScroll={useInfiniteScroll ? handleScroll : undefined}
            {...(TableContainerRef ? { ref: TableContainerRef } : {})}
          >
            <Table stickyHeader aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {filteredColumns.map((column, idx) => (
                    <StyledTableCell key={`${idx}-{${column.name}`}>
                      <Box
                        display="flex"
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={"2px"}
                      >
                        {"action" !== column.name.toLowerCase() &&
                          //   <IconButton
                          //   aria-label="expand row"
                          //   size="small"
                          //   onClick={() => setOpen(open === column.name ? "" : column.name)}
                          // >
                          //   {open === column.name ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          // </IconButton>
                          (ordering && column.orderBy ? (
                            <TableSortLabel
                              active={order[column.orderBy]}
                              direction={
                                order[column.orderBy]
                                  ? order[column.orderBy]
                                  : "asc"
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                createSortHandler(e, column);
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: { sm: "10px", lg: "14px" },
                                  padding: "5px 0px",
                                  paddingLeft: "20px",
                                }}
                              >
                                {column.name}
                              </Typography>
                            </TableSortLabel>
                          ) : (
                            <>
                              <Typography
                                sx={{
                                  fontSize: { sm: "10px", lg: "14px" },
                                  padding: "5px 0px",
                                  paddingLeft: "20px",
                                }}
                              >
                                {column.name}
                              </Typography>
                              {/* <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(open === column.name ? "" : column.name)}
                  >
                    {open === column.name ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton> */}
                            </>
                          ))}
                      </Box>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                  <TableRow>
                  {!useInfiniteScroll && loading ? (
                    <StyledTableCell colSpan={columns.length}>
                      <LineLoader />
                    </StyledTableCell>
                  ) : error && data.length === 0 ? (
                    <StyledTableCell colSpan={columns.length}>
                      <Typography sx={{ color: "#6D7378" }}>
                        {error}
                        <IconButton
                          onClick={() => {
                            fetchData(0);
                            setCurrPage(0);
                          }}
                        >
                          <ReplayOutlined />
                        </IconButton>
                      </Typography>
                    </StyledTableCell>
                  ) : (
                    data.length === 0 ? (
                      <StyledTableCell colSpan={columns.length}>
                        <Typography
                          sx={{ textAlign: "center", color: "#6D7378" }}
                        >
                          No data found!
                        </Typography>
                      </StyledTableCell>
                    ) :
                    (filteredColumns.length === 0 && columnCtrl) && (
                      <StyledTableCell colSpan={1} height={minHeight || 250}>
                      {/* <Typography
                        sx={{ textAlign: "center", color: "#6D7378" }}
                      >
                        No columns selected.
                      </Typography> */}
                    </StyledTableCell>
                    )
                  )}
                </TableRow>
                {data.length > 0 &&  filteredColumns.length > 0 &&
                  visibleRows.map((row, index) => {
                    return (
                      <RowComponent
                        singleRowRef={
                          useInfiniteScroll && index === visibleRows.length - 1
                            ? observerTarget
                            : null
                        }
                        row={row}
                        key={`${row.id}-${index}`}
                        skipRowIndices = {columnCtrl ?hiddenIndices:[]}
                      />
                    );
                  })}

                {useInfiniteScroll && loading && (
                  <TableRow>
                    <StyledTableCell
                      colSpan={columns.length}
                      sx={{ textAlign: "center" }}
                    >
                      <LineLoader />
                    </StyledTableCell>
                  </TableRow>
                )}

                {/* {Array.from({ length: currPage > 0 ? Math.max(0, (currPage + 1) * minRow - count) : 0 }).map((_, idx) => (
                  <TableRow key={idx}>
                    <StyledTableCell colSpan={columns.length} />
                  </TableRow>
                ))} */}
              </TableBody>
            </Table>
          </TableContainer>

          {!useInfiniteScroll && (
            <TablePagination
              component="div"
              count={count}
              page={currPage}
              onPageChange={handleChangePage}
              rowsPerPage={minRow}
              rowsPerPageOptions={[minRow]}
              disabled={loading}
            />
          )}
        </Paper>
        <CustomModal
          show={confirmModal != null}
          handleClose={closeConfirmModal}
          handleClick={confirmChange}
          title={"Please confirm before any change"}
        />
      </Box>
    );
  };
  
  export default CommonTableUI;
  